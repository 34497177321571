import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { Sun, Battery, Zap, Anchor, Cpu, ChevronRight } from 'lucide-react';
import SeoHead from './common/SeoHead';
import SolarPanelPage from './products/SolarPanelPage';
import BatteryPage from './products/BatteryPage';
import InverterPage from './products/InverterPage';
import MountingSystemPage from './products/MountingSystemPage';
import ChargeControllerPage from './products/ChargeControllerPage';

const ProductPage = () => {
  const { isDarkMode } = useTheme();
  const { category } = useParams();
  const [categoryState, setCategoryState] = useState(category);

  const products = {
    all: {
      title: 'Solar Energy Products',
      icon: Sun,
      description: 'Explore our comprehensive range of high-quality solar energy products for your home or business.',
      categories: [
        { 
          id: 'panels', 
          name: 'Solar Panels', 
          icon: Sun, 
          description: 'High-efficiency solar panels for optimal energy generation',
          features: [
            'High conversion efficiency',
            'Durable construction',
            'Weather resistant',
            'Long warranty period'
          ]
        },
        { 
          id: 'batteries', 
          name: 'Batteries', 
          icon: Battery, 
          description: 'Reliable energy storage solutions for continuous power',
          features: [
            'Extended backup time',
            'Deep cycle capability',
            'Smart monitoring',
            'Long lifespan'
          ]
        },
        { 
          id: 'inverters', 
          name: 'Inverters', 
          icon: Zap, 
          description: 'Advanced inverters for efficient power conversion',
          features: [
            'High efficiency rating',
            'Smart grid features',
            'Multiple operating modes',
            'Remote monitoring'
          ]
        },
        { 
          id: 'mounting', 
          name: 'Mounting Systems', 
          icon: Anchor, 
          description: 'Secure mounting solutions for any roof type',
          features: [
            'Strong durability',
            'Easy installation',
            'Weather resistant',
            'Universal compatibility'
          ]
        },
        { 
          id: 'controllers', 
          name: 'Charge Controllers', 
          icon: Cpu, 
          description: 'Smart charging solutions for optimal battery life',
          features: [
            'MPPT technology',
            'Battery protection',
            'System monitoring',
            'Multiple charging modes'
          ]
        },
      ],
    },
  };

  // SEO Configuration
  const seoData = {
    title: 'Solar Power Products | Complete Range of Solar Solutions | Solar Insight',
    description: 'Explore our comprehensive range of high-quality solar power products in South Africa. From premium solar panels and efficient batteries to advanced inverters and professional mounting systems. Get expert guidance on choosing the right solar components for your home or business.',
    keywords: 'solar power products, solar panels South Africa, solar batteries, solar inverters, solar mounting systems, charge controllers, solar equipment, solar power solutions, solar installation components, solar system parts, solar power kits, solar energy products, solar accessories South Africa',
    path: '/product/all',
    image: '/images/solar-products.jpg',
    categories: products.all.categories.map(cat => cat.name),
    features: [
      'Premium Solar Components',
      'Leading Manufacturers',
      'Professional Installation',
      'Expert Support',
      'Warranty Coverage'
    ],
    brands: [
      'Canadian Solar',
      'Fronius',
      'Tesla',
      'SMA',
      'Victron Energy'
    ],
    lastUpdated: new Date().toISOString()
  };

  const renderProductPage = () => {
    switch(categoryState) {
      case 'panels':
        return <SolarPanelPage />;
      case 'batteries':
        return <BatteryPage />;
      case 'inverters':
        return <InverterPage />;
      case 'mounting':
        return <MountingSystemPage />;
      case 'controllers':
        return <ChargeControllerPage />;
      default:
        return renderAllProducts();
    }
  };

  const renderAllProducts = () => (
    <>
      {/* Hero Section */}
      <section className={`text-center py-16 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'} rounded-lg shadow-xl mb-12`}>
        <div className="flex justify-center mb-6">
          <Sun className="w-16 h-16 text-yellow-400" />
        </div>
        <h1 className={`
          text-4xl md:text-6xl font-bold mb-6
          ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}
          drop-shadow-[0_0_10px_rgba(250,204,21,0.7)]
        `}>
          Solar Energy Products
        </h1>
        <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto px-4">
          Discover our comprehensive range of premium solar power solutions for your home or business
        </p>
      </section>

      {/* Products Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
        {products.all.categories.map((product) => (
          <Link
            key={product.id}
            to={`/product/${product.id}`}
            className={`
              p-6 rounded-lg transition-all duration-300
              ${isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-800 hover:bg-amber-700'}
              transform hover:scale-105 shadow-xl
            `}
          >
            <div className="flex items-center mb-4">
              <product.icon className="w-10 h-10 text-yellow-400" />
              <h2 className="text-2xl font-semibold ml-3 text-white">{product.name}</h2>
            </div>
            <p className="text-gray-300 mb-4">{product.description}</p>
            <ul className="space-y-2">
              {product.features.map((feature, idx) => (
                <li key={idx} className="flex items-center text-gray-300">
                  <ChevronRight className="w-4 h-4 text-yellow-400 mr-2" />
                  <span className="text-sm">{feature}</span>
                </li>
              ))}
            </ul>
          </Link>
        ))}
      </div>

      {/* CTA Section */}
      <section className={`text-center p-8 rounded-lg shadow-xl mt-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
        <h2 className="text-2xl font-bold mb-4 text-white">Ready to Get Started?</h2>
        <p className="text-gray-300 mb-6">
          Contact us today to discuss your solar power needs and get expert recommendations
        </p>
        <Link
          to="/contact"
          className={`
            inline-block px-8 py-3 rounded-lg font-semibold text-lg
            bg-yellow-400 text-gray-900 hover:bg-yellow-300
            transition duration-300 transform hover:scale-105
          `}
        >
          Get a Quote
        </Link>
      </section>
    </>
  );

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      {categoryState === 'all' && <SeoHead template="product" data={seoData} />}
      <main className="container mx-auto py-8">
        {renderProductPage()}
      </main>
    </div>
  );
};

export default ProductPage;