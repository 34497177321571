import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sun, Battery, Zap, Calculator, Star, ChevronLeft, ChevronRight, Check, ArrowRight } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import SeoHead from './common/SeoHead';
import { getReviews } from '../services/reviewService';

const Home = () => {
  const { isDarkMode } = useTheme();
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [topProducts, setTopProducts] = useState([]);

  // Sample reviews with South African locations - replace with actual data later
  const sampleReviews = [
    {
      id: 1,
      userName: "David van der Merwe",
      location: "Cape Town, Western Cape",
      rating: 5,
      comment: "Incredible savings on our electricity bill since installing the solar system. Load shedding is no longer a concern for our family.",
      userAvatar: "/avatars/david.jpg",
      createdAt: new Date("2024-01-15")
    },
    {
      id: 2,
      userName: "Sarah Naidoo",
      location: "Durban, KwaZulu-Natal",
      rating: 5,
      comment: "The installation team was professional and efficient. Our home now has reliable power even during the worst load shedding schedules.",
      userAvatar: "/avatars/sarah.jpg",
      createdAt: new Date("2024-01-10")
    },
    {
      id: 3,
      userName: "John Botha",
      location: "Johannesburg, Gauteng",
      rating: 4,
      comment: "Great investment for our business. The solar panels have significantly reduced our operating costs.",
      userAvatar: "/avatars/john.jpg",
      createdAt: new Date("2024-01-05")
    },
    {
      id: 4,
      userName: "Thabo Molefe",
      location: "Pretoria, Gauteng",
      rating: 5,
      comment: "The solar calculator helped us choose the perfect system size. Now we're completely off-grid!",
      userAvatar: "/avatars/thabo.jpg",
      createdAt: new Date("2024-01-01")
    },
    {
      id: 5,
      userName: "Lisa Patel",
      location: "Port Elizabeth, Eastern Cape",
      rating: 4,
      comment: "Excellent customer service and the battery backup system has been a game-changer during load shedding.",
      userAvatar: "/avatars/lisa.jpg",
      createdAt: new Date("2023-12-28")
    },
    {
      id: 6,
      userName: "Michael Pretorius",
      location: "Bloemfontein, Free State",
      rating: 5,
      comment: "The whole process from consultation to installation was smooth. Very happy with the performance of our solar system.",
      userAvatar: "/avatars/michael.jpg",
      createdAt: new Date("2023-12-20")
    }
  ];

  useEffect(() => {
    setReviews(sampleReviews);
    fetchTopProducts();
  }, []);

  const fetchTopProducts = async () => {
    try {
      const allReviews = await getReviews();
      
      // Sample top products (replace with actual data from your reviews)
      const sampleTopProducts = [
        {
          id: 1,
          name: "Canadian Solar HiKu Panels",
          category: "Solar Panels",
          avgRating: 4.8,
          reviewCount: 156,
          description: "High-efficiency panels perfect for residential use",
          price: "R8,500 - R12,000",
          topReview: {
            userName: "James Wilson",
            location: "Cape Town",
            rating: 5,
            comment: "These panels have exceeded our expectations. Power output is consistently high even on cloudy days.",
            verifiedPurchase: true
          }
        },
        {
          id: 2,
          name: "Tesla Powerwall 2",
          category: "Batteries",
          avgRating: 4.9,
          reviewCount: 98,
          description: "Premium home battery backup solution",
          price: "R15,000 - R25,000",
          topReview: {
            userName: "Sarah van Wyk",
            location: "Johannesburg",
            rating: 5,
            comment: "Complete peace of mind during load shedding. The app makes it easy to monitor power usage.",
            verifiedPurchase: true
          }
        },
        {
          id: 3,
          name: "Fronius Symo Inverter",
          category: "Inverters",
          avgRating: 4.7,
          reviewCount: 112,
          description: "Reliable three-phase inverter with smart features",
          price: "R12,000 - R18,000",
          topReview: {
            userName: "Michael Ndlovu",
            location: "Durban",
            rating: 5,
            comment: "Silent operation and excellent performance. The monitoring system is top-notch.",
            verifiedPurchase: true
          }
        }
      ];

      setTopProducts(sampleTopProducts);
    } catch (error) {
      console.error('Error fetching top products:', error);
    }
  };

  const nextReview = () => {
    setCurrentReviewIndex((prevIndex) => 
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevReview = () => {
    setCurrentReviewIndex((prevIndex) => 
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const features = [
    {
      icon: <Sun className="w-12 h-12" />,
      title: "Solar Solutions",
      description: "Find the perfect solar setup for your home",
      link: "/product/panels"
    },
    {
      icon: <Battery className="w-12 h-12" />,
      title: "Power Storage",
      description: "Explore battery storage options",
      link: "/product/batteries"
    },
    {
      icon: <Calculator className="w-12 h-12" />,
      title: "Solar Calculators",
      description: "Calculate your solar needs and savings",
      link: "/calculators/savings"
    },
    {
      icon: <Zap className="w-12 h-12" />,
      title: "Load Shedding",
      description: "Stay updated with load shedding schedules",
      link: "/load-shedding"
    }
  ];

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Solar Insight South Africa",
    "description": "Your trusted source for solar energy solutions in South Africa. Expert guidance on solar panels, batteries, and load shedding solutions.",
    "url": "https://solarinsight.co.za",
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "South Africa"
    },
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://solarinsight.co.za/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  const StarRating = ({ rating }) => (
    <div className="flex items-center">
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          className={`w-5 h-5 ${
            star <= rating
              ? isDarkMode
                ? 'text-yellow-400'
                : 'text-amber-400'
              : 'text-gray-300'
          }`}
          fill={star <= rating ? 'currentColor' : 'none'}
        />
      ))}
    </div>
  );

  // SEO Configuration
  const seoData = {
    title: 'Solar Insight | Solar Power Solutions in South Africa',
    description: 'Leading provider of solar power solutions in South Africa. Expert installation, maintenance, and support for residential and commercial solar systems.',
    keywords: 'solar power, solar energy, solar installation, solar panels, South Africa solar, renewable energy, load shedding solutions',
    path: '/',
    image: '/images/home-banner.jpg',
    features: [
      'Professional Installation',
      'Load Shedding Solutions',
      'Battery Backup Systems',
      'Solar Maintenance',
      'Energy Monitoring'
    ],
    services: [
      'Residential Solar',
      'Commercial Solar',
      'Battery Storage',
      'System Maintenance'
    ],
    coverage: [
      'Cape Town',
      'Johannesburg',
      'Durban',
      'Pretoria'
    ],
    stats: {
      installationsCompleted: 500,
      averageRating: 4.9,
      reviewCount: 250,
      yearsExperience: 10
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="general" data={seoData} />
      
      <main className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <div className={`text-center py-16 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'} rounded-lg shadow-xl mb-12`}>
          <h1 className={`
            text-4xl md:text-6xl font-bold mb-6
            ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}
            drop-shadow-[0_0_10px_rgba(250,204,21,0.7)]
            transition-all duration-300
          `}>
            Power Your Future with Solar
          </h1>
          <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
            Find the perfect solar solution for your home with our comprehensive tools and calculators
          </p>
          <div className="flex justify-center gap-4">
            <Link
              to="/calculators/panel"
              className={`
                px-6 py-3 rounded-lg font-semibold text-white
                ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-600 hover:bg-amber-500'}
                transition duration-200
                transform hover:scale-105
                shadow-lg hover:shadow-xl
                border-2 border-yellow-400
              `}
            >
              Get Started
            </Link>
            <Link
              to="/product/all"
              className={`
                px-6 py-3 rounded-lg font-semibold
                ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-700 hover:bg-amber-600'}
                text-yellow-400
                transition duration-200
                transform hover:scale-105
                shadow-lg hover:shadow-xl
                border-2 border-yellow-400
              `}
            >
              View Products
            </Link>
          </div>
        </div>

        {/* Features Section */}
        <div className="mb-12">
          <h2 className={`text-3xl font-bold mb-8 text-center ${isDarkMode ? 'text-yellow-400' : 'text-amber-800'}`}>
            Solar Solutions
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {features.map((feature, index) => (
              <Link
                key={index}
                to={feature.link}
                className={`
                  p-6 rounded-lg text-center shadow-xl
                  ${isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-800 hover:bg-amber-700'}
                  transition duration-200
                `}
              >
                <div className="flex justify-center mb-4 text-yellow-400">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-300">
                  {feature.description}
                </p>
              </Link>
            ))}
          </div>
        </div>

        {/* Latest Solar News Section */}
        <div className="mb-12">
          <h2 className={`text-3xl font-bold mb-8 text-center ${isDarkMode ? 'text-yellow-400' : 'text-amber-800'}`}>
            Latest Solar News
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Link
              to="/news/stage-6-loadshedding-south-africa"
              className={`rounded-lg overflow-hidden shadow-xl transition-all duration-300 ${
                isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-100 hover:bg-amber-200'
              }`}
            >
              <div className="p-6">
                <h3 className={`text-xl font-bold mb-3 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Stage 6 Loadshedding Crisis in South Africa
                </h3>
                <p className={`text-sm mb-4 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                  Analysis of Stage 6 loadshedding's impact, with 6,000MW cuts causing R500M daily losses. Explore solutions and recovery plans.
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-sm opacity-75">February 15, 2025</span>
                  <ArrowRight className="h-5 w-5" />
                </div>
              </div>
            </Link>

            <Link
              to="/news/solar-registration-laws-2025"
              className={`rounded-lg overflow-hidden shadow-xl transition-all duration-300 ${
                isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-100 hover:bg-amber-200'
              }`}
            >
              <div className="p-6">
                <h3 className={`text-xl font-bold mb-3 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  South Africa's New Solar Registration Laws
                </h3>
                <p className={`text-sm mb-4 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                  Essential guide to 2025 solar registration requirements, deadlines, and compliance for homeowners.
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-sm opacity-75">February 10, 2025</span>
                  <ArrowRight className="h-5 w-5" />
                </div>
              </div>
            </Link>

            <Link
              to="/news/load-shedding-south-africa-2025"
              className={`rounded-lg overflow-hidden shadow-xl transition-all duration-300 ${
                isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-100 hover:bg-amber-200'
              }`}
            >
              <div className="p-6">
                <h3 className={`text-xl font-bold mb-3 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Load Shedding in South Africa 2025
                </h3>
                <p className={`text-sm mb-4 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                  Comprehensive analysis of the ongoing load shedding crisis, examining causes and solutions.
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-sm opacity-75">January 31, 2025</span>
                  <ArrowRight className="h-5 w-5" />
                </div>
              </div>
            </Link>
          </div>

          <div className="text-center mt-8">
            <Link
              to="/news"
              className={`inline-flex items-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 ${
                isDarkMode 
                  ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
                  : 'bg-amber-500 text-white hover:bg-amber-600'
              }`}
            >
              <span className="font-semibold">View All News</span>
              <ArrowRight className="h-5 w-5" />
            </Link>
          </div>
        </div>

        {/* Reviews Stats Section */}
        <div className="mb-12">
          <h2 className={`text-3xl font-bold mb-8 text-center ${isDarkMode ? 'text-yellow-400' : 'text-amber-800'}`}>
            Trusted Solar Product Reviews
          </h2>
          <div className={`p-8 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
              <div className="flex flex-col items-center">
                <div className="text-4xl font-bold text-white mb-2">500+</div>
                <p className="text-gray-300">Verified Reviews</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-4xl font-bold text-white mb-2">4.8</div>
                <div className="mb-2">
                  <StarRating rating={4.8} />
                </div>
                <p className="text-gray-300">Average Rating</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-4xl font-bold text-white mb-2">98%</div>
                <p className="text-gray-300">Customer Satisfaction</p>
              </div>
            </div>
            <div className="max-w-2xl mx-auto mb-8">
              <p className="text-gray-200 text-lg mb-4">
                Make informed decisions with authentic reviews from South African solar users. Our community shares real experiences with solar panels, inverters, and batteries.
              </p>
              <div className="flex flex-wrap justify-center gap-4 text-gray-300 text-sm mb-6">
                <span className="flex items-center">
                  <Check className="w-4 h-4 mr-1 text-green-400" />
                  Verified Purchases
                </span>
                <span className="flex items-center">
                  <Check className="w-4 h-4 mr-1 text-green-400" />
                  Local Experiences
                </span>
                <span className="flex items-center">
                  <Check className="w-4 h-4 mr-1 text-green-400" />
                  Load Shedding Solutions
                </span>
              </div>
            </div>
            <div className="text-center">
              <Link
                to="/reviews"
                className={`
                  inline-block px-8 py-4 rounded-lg font-semibold text-lg shadow-lg
                  ${isDarkMode
                    ? 'bg-yellow-400 text-gray-900 hover:bg-yellow-300'
                    : 'bg-amber-500 hover:bg-amber-600 text-white'
                  }
                  transition duration-200 transform hover:scale-105
                `}
              >
                Explore All Solar Reviews
              </Link>
            </div>
          </div>
        </div>

        {/* Customer Reviews Slider */}
        <div className="mb-12">
          <h2 className={`text-3xl font-bold mb-8 text-center ${isDarkMode ? 'text-yellow-400' : 'text-amber-800'}`}>
            What Our Customers Say Across South Africa
          </h2>
          <div className="relative">
            <div className={`p-8 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'} transition-all duration-300 ease-in-out`}>
              {reviews.length > 0 && (
                <div className="flex flex-col items-center">
                  {reviews[currentReviewIndex].userAvatar && (
                    <img
                      src={reviews[currentReviewIndex].userAvatar}
                      alt={reviews[currentReviewIndex].userName}
                      className="w-20 h-20 rounded-full mb-4 border-4 border-yellow-400"
                    />
                  )}
                  <div className="mb-4">
                    <StarRating rating={reviews[currentReviewIndex].rating} />
                  </div>
                  <p className="text-gray-200 text-lg text-center mb-6 italic">
                    "{reviews[currentReviewIndex].comment}"
                  </p>
                  <div className="text-center">
                    <p className="text-white font-semibold text-lg">
                      {reviews[currentReviewIndex].userName}
                    </p>
                    <p className="text-yellow-400 text-sm mb-2">
                      {reviews[currentReviewIndex].location}
                    </p>
                    <p className="text-gray-400 text-sm">
                      {new Date(reviews[currentReviewIndex].createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Navigation Buttons */}
            <button
              onClick={prevReview}
              className={`
                absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4
                p-2 rounded-full
                ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-700 hover:bg-amber-600'}
                text-white
                transition-all duration-200
                shadow-lg
              `}
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              onClick={nextReview}
              className={`
                absolute right-0 top-1/2 -translate-y-1/2 translate-x-4
                p-2 rounded-full
                ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-700 hover:bg-amber-600'}
                text-white
                transition-all duration-200
                shadow-lg
              `}
            >
              <ChevronRight className="w-6 h-6" />
            </button>

            {/* Review Indicators */}
            <div className="flex justify-center mt-6 space-x-2">
              {reviews.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentReviewIndex(index)}
                  className={`
                    w-2 h-2 rounded-full transition-all duration-200
                    ${currentReviewIndex === index
                      ? isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'
                      : isDarkMode ? 'bg-blue-600' : 'bg-amber-300'
                    }
                  `}
                />
              ))}
            </div>
          </div>

          <div className="text-center mt-8">
            <Link
              to="/reviews"
              className={`
                inline-block px-6 py-3 rounded-lg font-semibold
                ${isDarkMode
                  ? 'bg-yellow-400 text-gray-900 hover:bg-yellow-300'
                  : 'bg-amber-500 hover:bg-amber-600 text-white'
                }
                transition duration-200
              `}
            >
              View All Reviews
            </Link>
          </div>
        </div>

        {/* Call to Action */}
        <div className="mb-12">
          <div className={`text-center py-12 px-4 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
            <h2 className="text-3xl font-bold text-white mb-4">
              Ready to Start Your Solar Journey?
            </h2>
            <p className="text-gray-200 mb-8 max-w-2xl mx-auto">
              Let us help you find the perfect solar solution for your needs
            </p>
            <Link
              to="/contact"
              className={`
                inline-block px-8 py-3 rounded-lg font-semibold text-white shadow-lg
                ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-600 hover:bg-amber-700'}
                transition duration-200
              `}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;