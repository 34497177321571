import React, { useState, useEffect } from 'react';
import { Battery, Calculator, Info, HelpCircle, ArrowRight, Sun, Moon, Zap, DollarSign, TrendingUp, Leaf } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import SeoHead from '../common/SeoHead';

const BatteryCapacityCalculator = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    dailyEnergyUsage: 20,
    peakPower: 5,
    backupHours: 12,
    usableCapacity: 80,
  });

  const [results, setResults] = useState({
    recommendedCapacity: 0,
    recommendedPower: 0,
    estimatedCost: 0,
    dailySavings: 0,
    carbonReduction: 0,
  });

  const [activeQuestion, setActiveQuestion] = useState(null);

  useEffect(() => {
    calculateResults();
  }, [inputs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({ ...prev, [name]: parseFloat(value) }));
  };

  const calculateResults = () => {
    const { dailyEnergyUsage, peakPower, backupHours, usableCapacity } = inputs;
    
    const capacityWattHours = (dailyEnergyUsage * 1000 * (backupHours / 24)) / (usableCapacity / 100);
    const recommendedPower = Math.ceil(peakPower * 1.2);
    const estimatedCost = capacityWattHours * 8 + recommendedPower * 3000; // Rough estimate in Rand
    const dailySavings = dailyEnergyUsage * 2.5; // Assuming 2.5 Rand per kWh savings
    const carbonReduction = dailyEnergyUsage * 0.5 * 365;
    
    setResults({
      recommendedCapacity: capacityWattHours.toFixed(2),
      recommendedPower: recommendedPower.toFixed(1),
      estimatedCost: estimatedCost.toFixed(2),
      dailySavings: dailySavings.toFixed(2),
      carbonReduction: carbonReduction.toFixed(2),
    });
  };

  const bgColor = isDarkMode ? 'bg-blue-900' : 'bg-amber-50';
  const textColor = isDarkMode ? 'text-amber-100' : 'text-amber-900';
  const inputBgColor = isDarkMode ? 'bg-blue-800' : 'bg-white';
  const inputBorderColor = isDarkMode ? 'border-blue-700' : 'border-amber-300';
  const iconHoverColor = isDarkMode ? 'hover:text-amber-300' : 'hover:text-amber-600';
  const resultsBgColor = isDarkMode ? 'bg-blue-800 shadow-inner shadow-amber-400/20' : 'bg-amber-100';
  const headerColor = isDarkMode ? 'text-yellow-400' : 'text-amber-800'; // Brown color for light mode
  const headerHoverEffect = isDarkMode 
    ? 'hover:text-yellow-300 hover:glow-yellow-300 transition-all duration-300'
    : 'hover:text-amber-700 hover:glow-amber-700 transition-all duration-300';

  const commonQuestions = [
    {
      q: "How do I choose the right battery size?",
      a: "Choose a battery size that covers your daily energy usage and provides enough backup for your needs. Consider factors like peak power demand, desired backup time, and budget."
    },
    {
      q: "What's the difference between battery capacity and power rating?",
      a: "Battery capacity (measured in kWh) determines how long the battery can power your home, while power rating (measured in kW) determines how many appliances it can run simultaneously."
    },
    {
      q: "How long do solar batteries typically last?",
      a: "Most modern lithium-ion solar batteries last 10-15 years. Factors affecting lifespan include usage patterns, depth of discharge, and maintenance."
    }
  ];

  // SEO Configuration
  const seoData = {
    title: 'Battery Capacity Calculator',
    purpose: 'optimal battery capacity for your solar system based on energy usage, peak demand, and backup requirements',
    description: 'Find the perfect battery size for your solar system. Get instant calculations for capacity, costs, and savings based on your energy usage and backup power needs.',
    keywords: 'battery sizing, energy storage calculator, solar battery requirements, backup power sizing, load shedding battery calculator',
    path: '/calculators/battery',
    image: '/images/battery-calculator.jpg'
  };

  return (
    <div className={`p-4 sm:p-6 rounded-lg shadow-lg ${bgColor} ${textColor}`}>
      <SeoHead template="calculator" data={seoData} />

      <h1 className={`text-3xl font-bold mb-4 flex items-center ${headerColor} ${headerHoverEffect}`}>
        <Battery className={`mr-2 ${iconHoverColor} transition-colors duration-300`} /> Solar Battery Sizing Calculator
      </h1>
      
      <p className="mb-6">Find the perfect solar battery solution for your home. Enter your details below to get personalized recommendations and insights.</p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <InputField
          label="Daily Energy Usage (kWh)"
          name="dailyEnergyUsage"
          value={inputs.dailyEnergyUsage}
          onChange={handleInputChange}
          tooltip="Your average daily electricity consumption. Check your utility bill or energy meter for this information."
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
        />
        <InputField
          label="Peak Power Demand (kW)"
          name="peakPower"
          value={inputs.peakPower}
          onChange={handleInputChange}
          tooltip="The maximum power your home uses at any given time. This helps size the inverter."
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
        />
        <InputField
          label="Desired Backup Time (hours)"
          name="backupHours"
          value={inputs.backupHours}
          onChange={handleInputChange}
          tooltip="How many hours you want your battery to power your home during an outage or at night."
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
        />
        <InputField
          label="Usable Battery Capacity (%)"
          name="usableCapacity"
          value={inputs.usableCapacity}
          onChange={handleInputChange}
          tooltip="The percentage of the battery's capacity that can be safely used. Typically 80-90% for lithium-ion batteries."
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
        />
      </div>

      <div className={`p-4 rounded-lg ${resultsBgColor} mb-6`}>
        <h2 className={`text-2xl font-bold mb-2 flex items-center ${headerColor} ${headerHoverEffect}`}>
          <Calculator className={`mr-2 ${iconHoverColor} transition-colors duration-300`} /> Your Personalized Results
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <ResultItem
            icon={<Battery className={iconHoverColor} />}
            label="Recommended Battery Capacity"
            value={`${results.recommendedCapacity} Wh`}
            description="This is the suggested size of your battery storage system."
          />
          <ResultItem
            icon={<Zap className={iconHoverColor} />}
            label="Recommended Inverter Size"
            value={`${results.recommendedPower} kW`}
            description="This is the suggested power rating for your inverter."
          />
          <ResultItem
            icon={<DollarSign className={iconHoverColor} />}
            label="Estimated System Cost"
            value={`R${results.estimatedCost}`}
            description="Rough estimate of battery system cost. Actual prices may vary."
          />
          <ResultItem
            icon={<TrendingUp className={iconHoverColor} />}
            label="Potential Daily Savings"
            value={`R${results.dailySavings}`}
            description="Estimated daily savings on your electricity bill."
          />
          <ResultItem
            icon={<Leaf className={iconHoverColor} />}
            label="Annual Carbon Reduction"
            value={`${results.carbonReduction} kg CO2`}
            description="Estimated annual reduction in carbon emissions."
          />
        </div>
      </div>

      <div className="mb-8">
        <h3 className={`text-xl font-bold mb-4 ${headerColor} ${headerHoverEffect}`}>Frequently Asked Questions</h3>
        {commonQuestions.map((q, index) => (
          <div key={index} className="mb-4">
            <button
              className={`flex justify-between items-center w-full text-left font-semibold p-2 rounded ${isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-100 hover:bg-amber-200'} transition-colors duration-300`}
              onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}
            >
              {q.q}
              <ArrowRight className={`transform transition-transform duration-300 ${activeQuestion === index ? 'rotate-90' : ''} ${iconHoverColor}`} />
            </button>
            {activeQuestion === index && <p className={`mt-2 p-2 rounded ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>{q.a}</p>}
          </div>
        ))}
      </div>

      <div className={`p-4 rounded-lg ${resultsBgColor}`}>
        <h3 className={`text-xl font-bold mb-2 flex items-center ${headerColor} ${headerHoverEffect}`}>
          <Info className={`mr-2 ${iconHoverColor} transition-colors duration-300`} /> Next Steps
        </h3>
        <p>Based on your results, you now have a good starting point for your solar battery system. Remember, these are estimates and your actual needs may vary. For a precise quote and professional assessment:</p>
        <ul className="list-disc list-inside mt-2">
          <li>Consult with a certified solar installer</li>
          <li>Consider your future energy needs</li>
          <li>Explore available incentives and rebates in your area</li>
        </ul>
        <button 
          onClick={() => navigate('/contact')}
          className="mt-4 px-6 py-2 bg-green-500 hover:bg-green-600 text-white font-bold rounded transition-colors duration-300">
          Get a Professional Assessment
        </button>
      </div>
    </div>
  );
};

const InputField = ({ label, name, value, onChange, tooltip, bgColor, borderColor, textColor, iconHoverColor }) => (
  <div className="relative group">
    <label className="block mb-2 font-semibold flex items-center">
      {label}
      <HelpCircle size={16} className={`ml-2 cursor-help ${iconHoverColor} transition-colors duration-300`} />
    </label>
    <input
      type="number"
      name={name}
      value={value}
      onChange={onChange}
      className={`w-full p-2 rounded ${bgColor} ${borderColor} border ${textColor}`}
    />
    <div className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
      {tooltip}
    </div>
  </div>
);

const ResultItem = ({ icon, label, value, description }) => (
  <div className="flex items-start">
    <div className="mr-3 mt-1">{icon}</div>
    <div>
      <p className="font-semibold">{label}</p>
      <p className="text-lg font-bold">{value}</p>
      <p className="text-sm">{description}</p>
    </div>
  </div>
);

export default BatteryCapacityCalculator;