import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../auth/AuthContext';
import { Edit2, Trash2, AlertCircle, Eye, Search, Filter, PlusCircle, Share2 } from 'lucide-react';
import SeoHead from '../common/SeoHead';

function ManageTips() {
  const { isDarkMode } = useTheme();
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [tips, setTips] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { value: 'all', label: 'All Categories' },
    { value: 'maintenance', label: 'Maintenance' },
    { value: 'efficiency', label: 'Efficiency' },
    { value: 'savings', label: 'Cost Savings' },
    { value: 'installation', label: 'Installation' },
    { value: 'loadshedding', label: 'Load Shedding' },
  ];

  // Sample tips data - replace with Firebase fetch
  const sampleTips = [
    {
      id: 1,
      title: 'Maximize Solar Panel Efficiency',
      category: 'efficiency',
      content: 'Regular cleaning and maintenance can increase your solar panel efficiency by up to 15%.',
      createdAt: new Date('2024-01-15'),
      views: 1250,
      shares: 45,
    },
    {
      id: 2,
      title: 'Load Shedding Survival Guide',
      category: 'loadshedding',
      content: 'Essential tips for managing your solar system during load shedding periods.',
      createdAt: new Date('2024-01-10'),
      views: 2100,
      shares: 89,
    },
    {
      id: 3,
      title: 'Solar Panel Cleaning Guide',
      category: 'maintenance',
      content: 'Step-by-step guide to properly clean your solar panels for optimal performance.',
      createdAt: new Date('2024-01-05'),
      views: 980,
      shares: 32,
    },
  ];

  useEffect(() => {
    // Replace with Firebase fetch
    setTips(sampleTips);
  }, []);

  const handleDelete = async (tipId) => {
    if (window.confirm('Are you sure you want to delete this tip?')) {
      // Add Firebase delete logic here
      console.log('Deleting tip:', tipId);
    }
  };

  const filteredTips = tips.filter(tip => {
    const matchesSearch = tip.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         tip.content.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || tip.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  if (!isAdmin) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900' : 'bg-amber-50'} flex items-center justify-center`}>
        <div className="text-center">
          <AlertCircle className={`w-16 h-16 mx-auto mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`} />
          <h2 className={`text-2xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-amber-900'}`}>
            Admin Access Required
          </h2>
          <p className={`mb-4 ${isDarkMode ? 'text-blue-100' : 'text-amber-700'}`}>
            You need admin privileges to access this page.
          </p>
        </div>
      </div>
    );
  }

  // SEO Configuration
  const seoData = {
    title: 'Manage Solar Tips | Admin Dashboard',
    description: 'Administrative dashboard for managing solar power tips and community content. Review, edit, and moderate user contributions.',
    keywords: 'solar tips management, admin dashboard, content moderation, community management, solar power tips',
    path: '/social-tips/manage',
    image: '/images/admin-dashboard.jpg',
    contentType: 'admin',
    features: [
      'Content Moderation',
      'User Management',
      'Analytics Dashboard',
      'Category Management'
    ],
    access: 'admin',
    noIndex: true
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900' : 'bg-amber-50'}`}>
      <SeoHead template="admin" data={seoData} />

      <div className="container mx-auto px-4 py-8">
        {/* Header Section */}
        <div className={`mb-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <div className="flex justify-between items-center">
            <div>
              <h1 className={`text-3xl font-bold mb-2 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}`}>
                Manage Solar Tips
              </h1>
              <p className="text-gray-200">
                Create, edit, and manage your solar power tips
              </p>
            </div>
            <button
              onClick={() => navigate('/social-tips/add')}
              className={`
                flex items-center px-4 py-2 rounded-lg font-semibold
                ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-700 hover:bg-amber-600'}
                text-white transition duration-200
              `}
            >
              <PlusCircle className="w-5 h-5 mr-2" />
              Add New Tip
            </button>
          </div>
        </div>

        {/* Filters Section */}
        <div className={`mb-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search tips..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                />
              </div>
            </div>
            <div className="md:w-64">
              <div className="relative">
                <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                >
                  {categories.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Tips List */}
        <div className={`rounded-lg overflow-hidden ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className={`${isDarkMode ? 'bg-blue-700' : 'bg-amber-700'}`}>
                  <th className="px-6 py-3 text-left text-yellow-400 font-semibold">Title</th>
                  <th className="px-6 py-3 text-left text-yellow-400 font-semibold">Category</th>
                  <th className="px-6 py-3 text-left text-yellow-400 font-semibold">Created</th>
                  <th className="px-6 py-3 text-center text-yellow-400 font-semibold">Views</th>
                  <th className="px-6 py-3 text-center text-yellow-400 font-semibold">Shares</th>
                  <th className="px-6 py-3 text-center text-yellow-400 font-semibold">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {filteredTips.map((tip) => (
                  <tr key={tip.id} className="hover:bg-black/10">
                    <td className="px-6 py-4 text-white">{tip.title}</td>
                    <td className="px-6 py-4">
                      <span className={`
                        px-2 py-1 rounded-full text-sm
                        ${tip.category === 'efficiency' ? 'bg-green-500' :
                          tip.category === 'maintenance' ? 'bg-blue-500' :
                          tip.category === 'loadshedding' ? 'bg-red-500' :
                          'bg-yellow-500'} text-white
                      `}>
                        {tip.category}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-gray-300">
                      {tip.createdAt.toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 text-center text-gray-300">
                      <div className="flex items-center justify-center">
                        <Eye className="w-4 h-4 mr-1" />
                        {tip.views}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center text-gray-300">
                      <div className="flex items-center justify-center">
                        <Share2 className="w-4 h-4 mr-1" />
                        {tip.shares}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => navigate(`/social-tips/edit/${tip.id}`)}
                          className="p-1 text-blue-400 hover:text-blue-300"
                          title="Edit"
                        >
                          <Edit2 className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDelete(tip.id)}
                          className="p-1 text-red-400 hover:text-red-300"
                          title="Delete"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageTips; 