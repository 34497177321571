import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import SeoHead from '../common/SeoHead';
import {
  Share2,
  Bookmark,
  BookmarkPlus,
  ChevronUp,
  Calendar,
  Clock,
  Tag,
  AlertTriangle,
  Battery,
  Sun,
  Zap,
  MessageCircle,
  ThumbsUp,
  ThumbsDown,
  List,
  ChevronRight,
  ArrowRight,
  Plus,
  Minus,
  HelpCircle,
  TrendingDown,
  Factory,
  Hospital,
  GraduationCap,
  DollarSign,
  Timer,
  Briefcase,
  Building2,
  Hammer,
  Truck,
  Leaf
} from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Cell } from 'recharts';

const LoadSheddingSouthAfrica2025 = () => {
  const { isDarkMode } = useTheme();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const [activeSection, setActiveSection] = useState('');
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [feedback, setFeedback] = useState({ helpful: 0, notHelpful: 0 });
  const [expandedFaqs, setExpandedFaqs] = useState(new Set());
  const [selectedCategory, setSelectedCategory] = useState('all');
  
  const articleRef = useRef(null);
  const sectionsRef = useRef({});

  const publishDate = "2025-01-31T09:00:00Z";
  const siteUrl = 'https://solarinsight.co.za';
  const articlePath = '/news/load-shedding-south-africa-2025';
  const fullUrl = `${siteUrl}${articlePath}`;

  // Reading progress calculation
  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setReadingProgress(progress);
      setShowScrollTop(window.scrollY > 300);

      // Update active section
      Object.entries(sectionsRef.current).forEach(([id, ref]) => {
        if (ref && ref.getBoundingClientRect().top < window.innerHeight / 3) {
          setActiveSection(id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (sectionId) => {
    sectionsRef.current[sectionId]?.scrollIntoView({ behavior: 'smooth' });
  };

  const loadSheddingData = [
    { stage: 'Stage 1', hours: 2.5, mw: 1000 },
    { stage: 'Stage 2', hours: 5, mw: 2000 },
    { stage: 'Stage 3', hours: 7.5, mw: 3000 },
    { stage: 'Stage 4', hours: 10, mw: 4000 },
    { stage: 'Stage 5', hours: 12, mw: 5000 },
    { stage: 'Stage 6', hours: 14, mw: 6000 },
    { stage: 'Stage 7', hours: 16, mw: 7000 },
    { stage: 'Stage 8', hours: 18, mw: 8000 },
  ];

  const powerGenerationData = [
    { category: 'Aging Plants (>30 years)', percentage: 60 },
    { category: 'Coal Dependency', percentage: 65 },
    { category: 'Operational Capacity', percentage: 75 },
  ];

  const urbanRuralComparisonData = [
    {
      area: 'Urban Areas',
      loadSheddingHours: 4,
      reliability: 85,
      solarAdoption: 15,
    },
    {
      area: 'Rural Areas',
      loadSheddingHours: 8,
      reliability: 60,
      solarAdoption: -35,
    },
  ];

  const outagesTrendData = [
    { month: 'Jan', unplannedOutages: 12000 },
    { month: 'Feb', unplannedOutages: 12500 },
    { month: 'Mar', unplannedOutages: 13000 },
    { month: 'Apr', unplannedOutages: 13313 },
  ];

  const relatedArticles = [
    {
      title: 'New Perovskite Solar Cells Break Efficiency Record',
      url: '/news/perovskite-solar-breakthrough-2025',
      date: '2025-01-29',
    },
    {
      title: 'Solar Power Solutions for Load Shedding',
      url: '/insights/solar-solutions-load-shedding',
      date: '2025-01-25',
    },
    {
      title: 'Government Announces New Energy Policy',
      url: '/news/energy-policy-update-2025',
      date: '2025-01-20',
    },
  ];

  const tableOfContents = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'current-state', title: 'Current State in 2025' },
    { id: 'root-causes', title: 'Root Causes' },
    { id: 'socio-economic-impact', title: 'Socio-Economic Impact' },
    { id: 'responses', title: 'Government and Private Sector Responses' },
    { id: 'path-forward', title: 'Path Forward' },
    { id: 'conclusion', title: 'Conclusion' },
  ];

  const faqs = [
    {
      id: 1,
      question: "What is load shedding?",
      answer: "Load shedding is a controlled process that involves:\n• Scheduled power outages to balance electricity supply and demand\n• Planned interruptions to prevent total grid collapse\n• Rotating outages across different areas\n• Systematic approach to manage power shortages\n• Protection of essential infrastructure",
      category: "Basics"
    },
    {
      id: 2,
      question: "What is load shedding in South Africa 2025?",
      answer: "Load shedding in South Africa 2025 refers to the scheduled power outages implemented by Eskom to manage the imbalance between electricity supply and demand. These controlled blackouts are a response to:\n• Aging infrastructure\n• Heavy coal dependency\n• Delays in renewable energy integration\n• Grid stability management",
      category: "Basics"
    },
    {
      id: 3,
      question: "Why is Eskom in crisis?",
      answer: "Eskom's crisis stems from multiple challenges:\n• Aging infrastructure requiring constant maintenance\n• Heavy dependency on coal-fired power stations\n• Significant delays in renewable energy projects\n• Financial constraints and mounting debt\n• Operational inefficiencies and management issues",
      category: "Eskom"
    },
    {
      id: 4,
      question: "Why is South Africa experiencing severe load shedding in 2025?",
      answer: "The crisis is driven by multiple factors including:\n• Over 60% of Eskom's generation fleet being over 30 years old\n• Reliance on aging coal-fired power plants\n• Significant delays in renewable energy projects\n• Unplanned outages causing fluctuating load shedding stages (Stage 3 to 5)",
      category: "Causes"
    },
    {
      id: 5,
      question: "What are the load shedding stages?",
      answer: "Load shedding stages in 2025 indicate severity levels:\n• Stage 1: 2.5 hours of outages per day\n• Stage 2: 5 hours of outages per day\n• Stage 3: 7.5 hours of outages (moderate)\n• Stage 4: 10 hours of outages per day\n• Stage 5: 12 hours of outages (severe)",
      category: "Basics"
    },
    {
      id: 6,
      question: "How does Eskom contribute to the load shedding crisis?",
      answer: "Eskom's contribution to the crisis includes:\n• Aging infrastructure limiting power generation\n• Debt exceeding R400 billion constraining modernization\n• Frequent unplanned maintenance needs\n• Limited capacity to meet peak demand\n• Delayed implementation of expansion projects",
      category: "Eskom"
    },
    {
      id: 7,
      question: "How does load shedding impact the economy?",
      answer: "The economic impact is severe:\n• Daily losses of R500 million\n• Disruption to industrial production\n• Reduced business productivity\n• Job losses across sectors\n• Decreased investor confidence",
      category: "Impact"
    },
    {
      id: 8,
      question: "What are the economic and social impacts of load shedding?",
      answer: "The impacts are widespread:\n• Daily economic losses of R500 million\n• Disruptions to industries and businesses\n• Healthcare service interruptions\n• Educational institution challenges\n• Increased social inequality between urban and rural areas",
      category: "Impact"
    },
    {
      id: 9,
      question: "What role does renewable energy play in addressing the crisis?",
      answer: "Renewable energy is crucial for:\n• Achieving 30% renewable energy mix by 2030\n• Reducing coal dependency\n• Ensuring sustainable power supply\n• Improving grid stability\n• Meeting international climate commitments",
      category: "Solutions"
    },
    {
      id: 10,
      question: "What is the urban vs. rural load shedding difference?",
      answer: "The disparity is significant:\n• Urban areas: Average 4 hours daily outages\n• Rural areas: Up to 8 hours daily outages\n• Urban areas have better backup systems\n• Rural areas face longer recovery times\n• Greater impact on rural essential services",
      category: "Impact"
    },
    {
      id: 11,
      question: "Can renewable energy reduce load shedding?",
      answer: "Yes, renewable energy can help by:\n• Diversifying power sources\n• Reducing reliance on aging coal plants\n• Providing sustainable energy solutions\n• Enabling local power generation\n• Supporting grid stability",
      category: "Solutions"
    },
    {
      id: 12,
      question: "How does Eskom's aging fleet affect power supply?",
      answer: "The aging fleet impacts supply through:\n• Over 60% of plants exceeding 30-year lifespan\n• Frequent breakdowns and maintenance needs\n• Reduced generation capacity\n• Higher maintenance costs\n• Decreased reliability and efficiency",
      category: "Eskom"
    },
    {
      id: 13,
      question: "What policy reforms are being implemented?",
      answer: "Key reforms include:\n• Electricity Regulation Amendment Bill\n• Decentralized power generation policies\n• Renewable energy incentives\n• Private sector participation framework\n• Grid modernization initiatives",
      category: "Government"
    },
    {
      id: 14,
      question: "Are international partnerships helping solve the crisis?",
      answer: "Yes, through:\n• Just Energy Transition Partnership (JETP)\n• Billions in renewable energy investments\n• Technical expertise sharing\n• Infrastructure modernization support\n• Green technology transfer",
      category: "Solutions"
    },
    {
      id: 15,
      question: "What is the future plan for energy stability?",
      answer: "The strategy includes:\n• Modernizing grid infrastructure\n• Achieving 30% renewable energy by 2030\n• Restructuring Eskom\n• Implementing smart grid technology\n• Developing energy storage solutions",
      category: "Future"
    }
  ];

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Load Shedding in South Africa 2025',
          text: 'An in-depth analysis of the ongoing energy crisis and solutions.',
          url: window.location.href
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    } else {
      navigator.clipboard.writeText(window.location.href);
      alert('Link copied to clipboard!');
    }
  };

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "Load Shedding in South Africa 2025: Crisis Analysis and Solutions Guide",
    "datePublished": publishDate,
    "dateModified": publishDate,
    "author": {
      "@type": "Organization",
      "name": "Solar Insight",
      "url": siteUrl
    },
    "publisher": {
      "@type": "Organization",
      "name": "Solar Insight",
      "logo": {
        "@type": "ImageObject",
        "url": `${siteUrl}/logo.png`
      }
    },
    "description": "In-depth analysis of South Africa's load shedding crisis in 2025. Examine causes, economic impact, government responses, and sustainable solutions including renewable energy transition.",
    "keywords": "load shedding South Africa 2025, Eskom crisis, power outages, energy solutions",
    "articleSection": "Energy News",
    "image": {
      "@type": "ImageObject",
      "url": `${siteUrl}/images/load-shedding-2025.jpg`,
      "width": "1200",
      "height": "630"
    },
    "url": fullUrl,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": fullUrl
    },
    "wordCount": "3500",
    "timeRequired": "PT8M",
    "articleBody": "Comprehensive analysis of South Africa's load shedding crisis in 2025, examining infrastructure challenges, economic impacts, and sustainable solutions...",
    "about": {
      "@type": "Thing",
      "name": "Load Shedding",
      "description": "Planned power outages to manage electricity supply and demand in South Africa"
    }
  };

  const keyImpacts = [
    {
      icon: <AlertTriangle className="h-6 w-6" />,
      title: "Economic Impact",
      description: "Daily losses of R500 million affecting GDP growth"
    },
    {
      icon: <Battery className="h-6 w-6" />,
      title: "Power Outages",
      description: "Up to 8 hours of daily blackouts in affected areas"
    },
    {
      icon: <Sun className="h-6 w-6" />,
      title: "Renewable Shift",
      description: "30% renewable energy target by 2030"
    },
    {
      icon: <Zap className="h-6 w-6" />,
      title: "Grid Stability",
      description: "Aging infrastructure requiring urgent upgrades"
    }
  ];

  // SEO Configuration
  const seoData = {
    title: "Load Shedding South Africa 2025: Crisis Analysis, Stages & Solutions Guide",
    description: "Comprehensive analysis of South Africa's 2025 load shedding crisis. Learn about Eskom's challenges, load shedding stages, economic impact (R500M daily losses), and solutions. Includes urban vs rural comparison and latest statistics.",
    keywords: "load shedding South Africa 2025, Eskom crisis, load shedding stages, power outages South Africa, electricity crisis SA, Eskom power cuts, load shedding schedule, renewable energy solutions, solar power South Africa, energy crisis solutions",
    publishDate,
    modifiedDate: publishDate,
    path: articlePath,
    image: '/images/load-shedding-2025.jpg',
    category: 'Energy Crisis',
    author: 'Solar Insight Team',
    readTime: '8 min read',
    section: 'Energy News',
    tags: [
      'load shedding',
      'Eskom',
      'South Africa energy crisis',
      'power outages',
      'renewable energy',
      'solar power',
      'electricity supply',
      'grid stability',
      'energy infrastructure',
      'power generation'
    ],
    noIndex: false,
    ogType: 'article',
    twitterCard: 'summary_large_image',
    canonical: `${siteUrl}${articlePath}`
  };

  const toggleFaq = (id) => {
    setExpandedFaqs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const faqCategories = ['all', ...new Set(faqs.map(faq => faq.category))];

  const filteredFaqs = selectedCategory === 'all' 
    ? faqs 
    : faqs.filter(faq => faq.category === selectedCategory);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-gray-100' : 'bg-amber-50 text-amber-900'}`}>
      <SeoHead template="article" data={seoData} />

      {/* Reading Progress Bar */}
      <div 
        className="fixed top-0 left-0 w-full h-1 bg-gray-200 z-50"
        style={{ 
          background: isDarkMode ? 'rgba(30, 58, 138, 0.3)' : 'rgba(251, 191, 36, 0.3)'
        }}
      >
        <div
          className={`h-full transition-all duration-300 ${
            isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'
          }`}
          style={{ width: `${readingProgress}%` }}
        />
      </div>

      <main className="container mx-auto px-4 py-8">
        <article className="max-w-4xl mx-auto" ref={articleRef}>
          {/* Hero Section */}
          <section className={`rounded-xl shadow-2xl mb-12 p-8 ${
            isDarkMode 
              ? 'bg-gradient-to-br from-blue-800 to-blue-900' 
              : 'bg-gradient-to-br from-amber-100 to-amber-200'
          }`}>
            <h1 className={`text-4xl md:text-6xl font-bold mb-6 text-center ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
              Load Shedding in South Africa 2025
            </h1>
            <p className={`text-xl mb-8 text-center ${
              isDarkMode ? 'text-yellow-100' : 'text-amber-800'
            }`}>
              A comprehensive analysis of the ongoing energy crisis and pathways to a sustainable future
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button 
                onClick={handleShare}
                className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${
                  isDarkMode
                    ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                    : 'bg-amber-500 text-white hover:bg-amber-600'
                }`}
              >
                <Share2 className="h-5 w-5" />
                <span>Share Article</span>
              </button>
              <button 
                onClick={handleBookmark}
                className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${
                  isBookmarked
                    ? isDarkMode
                      ? 'bg-yellow-400 text-blue-900'
                      : 'bg-amber-500 text-white'
                    : isDarkMode
                      ? 'border-2 border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-blue-900'
                      : 'border-2 border-amber-500 text-amber-500 hover:bg-amber-500 hover:text-white'
                }`}
              >
                {isBookmarked ? <Bookmark className="h-5 w-5" /> : <BookmarkPlus className="h-5 w-5" />}
                <span>{isBookmarked ? 'Saved' : 'Save for Later'}</span>
              </button>
            </div>
          </section>

          {/* Table of Contents Toggle */}
          <button
            onClick={() => setShowTableOfContents(!showTableOfContents)}
            className={`fixed left-4 top-1/2 transform -translate-y-1/2 p-3 rounded-full shadow-lg ${
              isDarkMode ? 'bg-blue-800 text-yellow-400' : 'bg-amber-100 text-amber-700'
            }`}
          >
            <List className="h-6 w-6" />
          </button>

          {/* Floating Table of Contents */}
          {showTableOfContents && (
            <div className={`fixed left-16 top-1/2 transform -translate-y-1/2 p-4 rounded-lg shadow-xl ${
              isDarkMode ? 'bg-blue-800' : 'bg-amber-100'
            }`}>
              <h3 className={`font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Table of Contents
              </h3>
              <ul className="space-y-2">
                {tableOfContents.map(({ id, title }) => (
                  <li key={id}>
                    <button
                      onClick={() => scrollToSection(id)}
                      className={`flex items-center text-left ${
                        activeSection === id
                          ? (isDarkMode ? 'text-yellow-400' : 'text-amber-700')
                          : 'text-gray-400'
                      } hover:text-yellow-400 transition-colors`}
                    >
                      <ChevronRight className={`h-4 w-4 mr-2 ${
                        activeSection === id ? 'opacity-100' : 'opacity-0'
                      }`} />
                      {title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Key Impacts Grid */}
          <section className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12`}>
            {keyImpacts.map((impact, index) => (
              <div 
                key={index}
                className={`p-6 rounded-lg transition-all duration-300 ${
                  isDarkMode 
                    ? 'bg-blue-800 hover:bg-blue-700' 
                    : 'bg-amber-100 hover:bg-amber-200'
                }`}
              >
                <div className={`flex items-center gap-4 mb-3`}>
                  <div className={`p-3 rounded-full ${
                    isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'
                  }`}>
                    {impact.icon}
                  </div>
                  <h3 className={`font-semibold ${
                    isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                  }`}>
                    {impact.title}
                  </h3>
                </div>
                <p className={isDarkMode ? 'text-gray-300' : 'text-amber-900'}>
                  {impact.description}
                </p>
              </div>
            ))}
          </section>

          {/* Article Meta */}
          <section className={`flex items-center justify-between mb-8 p-4 rounded-lg ${
            isDarkMode ? 'bg-blue-800' : 'bg-amber-100'
          }`}>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Calendar className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <time dateTime={publishDate} className="text-sm">January 31, 2025</time>
              </div>
              <div className="flex items-center gap-2">
                <Clock className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">8 min read</span>
              </div>
              <div className="flex items-center gap-2">
                <Tag className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">Energy Crisis</span>
              </div>
            </div>
          </section>

          {/* Load Shedding Stages Graph */}
          <section className={`p-6 rounded-lg shadow-xl mb-8 ${isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'}`}>
            <h3 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
              Eskom Load Shedding Stages
            </h3>
            
            <div className={`mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
              <p className="mb-4">Load shedding will be used under emergency conditions for limited periods.</p>
              <p className="mb-4">Eight schedules have been developed based on the level of risk and to ensure that load shedding is applied in a fair and equitable manner:</p>

              {/* Implementation Details */}
              <div className={`p-4 rounded-lg mb-6 ${isDarkMode ? 'bg-blue-700/30' : 'bg-amber-100/50'}`}>
                <h4 className={`font-semibold mb-3 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                  Implementation Details:
                </h4>
                <ul className="space-y-2 mb-4">
                  <li className="flex items-start gap-2">
                    <Clock className={`h-4 w-4 mt-1 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                    <span>Load shedding in stages 1 to 4 will be implemented in most parts of the country in 2 hour blocks.</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <AlertTriangle className={`h-4 w-4 mt-1 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                    <span>In Eskom-supplied Johannesburg areas, blocks are 4 hours long to coincide with City Power's schedule.</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <Timer className={`h-4 w-4 mt-1 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                    <span>Each period has an additional 30 minutes for network switching (2.5 hours for 2-hour blocks, 4.5 hours for 4-hour blocks).</span>
                  </li>
                </ul>
              </div>

              {/* Stages Impact Grid */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                {loadSheddingData.map((stage, index) => (
                  <div
                    key={index}
                    className={`border rounded-lg transition-all duration-300 ${
                      isDarkMode
                        ? 'border-blue-700 hover:border-yellow-400'
                        : 'border-amber-200 hover:border-amber-500'
                    }`}
                  >
                    <button
                      onClick={() => toggleFaq(`stage-${index}`)}
                      className={`w-full px-4 py-3 flex items-center justify-between ${
                        expandedFaqs.has(`stage-${index}`)
                          ? isDarkMode
                            ? 'bg-blue-700'
                            : 'bg-amber-200'
                          : ''
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        <AlertTriangle className={`h-4 w-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                        <span className={`font-semibold text-left ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
                          {stage.stage}
                        </span>
                      </div>
                      <div className="flex items-center gap-4">
                        <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-amber-600'}`}>
                          {stage.mw.toLocaleString()} MW
                        </span>
                        {expandedFaqs.has(`stage-${index}`) 
                          ? <Minus className="h-5 w-5 flex-shrink-0" />
                          : <Plus className="h-5 w-5 flex-shrink-0" />
                        }
                      </div>
                    </button>
                    
                    {/* Stage Details */}
                    {expandedFaqs.has(`stage-${index}`) && (
                      <div className={`px-4 py-3 border-t ${
                        isDarkMode ? 'border-blue-700 bg-blue-700/50' : 'border-amber-200 bg-amber-200/50'
                      }`}>
                        <div className={`space-y-3 ${
                          isDarkMode ? 'text-gray-300' : 'text-amber-900'
                        }`}>
                          <div className="flex items-start gap-2">
                            <Zap className={`h-4 w-4 mt-1 flex-shrink-0 ${
                              isDarkMode ? 'text-yellow-400' : 'text-amber-500'
                            }`} />
                            <p className="text-sm">Allows for up to {stage.mw.toLocaleString()} MW of the national load to be shed.</p>
                          </div>
                          <div className="flex items-start gap-2">
                            <Clock className={`h-4 w-4 mt-1 flex-shrink-0 ${
                              isDarkMode ? 'text-yellow-400' : 'text-amber-500'
                            }`} />
                            <p className="text-sm whitespace-pre-line">{index < 4 ? 
                              `Consumers can expect to be shed up to ${(index + 1) * 3} times over a four-day period for two hours at a time, or ${(index + 1) * 3} times over an eight-day period for four hours at a time.` :
                              index === 4 ?
                              "Consumers can expect to be shed up to 12 times over a four-day period:\n• 9 times for 2 hours\n• 3 times for 4 hours" :
                              index === 5 ?
                              "Consumers can expect to be shed up to 12 times over a four-day period:\n• 6 times for 2 hours\n• 6 times for 4 hours" :
                              index === 6 ?
                              "Consumers can expect to be shed up to 12 times over a four-day period:\n• 3 times for 2 hours\n• 9 times for 4 hours" :
                              "Consumers can expect to be shed up to 12 times over a four-day period for four hours at a time."
                            }</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Important Notes */}
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-700/30' : 'bg-amber-100/50'}`}>
                <h4 className={`font-semibold mb-3 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                  Important Notes:
                </h4>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <AlertTriangle className={`h-4 w-4 mt-1 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                    <span>The frequency of load shedding increases with higher stages.</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <AlertTriangle className={`h-4 w-4 mt-1 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                    <span>2-hour blocks in Stages 1-4 are progressively doubled to 4 hours in Stages 5-8.</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <AlertTriangle className={`h-4 w-4 mt-1 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                    <span>If more load needs to be shed than scheduled in Stages 1-8, additional unscheduled load shedding may occur.</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart 
                  data={loadSheddingData} 
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis 
                    label={{ 
                      value: 'Megawatts (MW)', 
                      angle: -90, 
                      position: 'insideLeft',
                      offset: 10
                    }}
                    tick={{ fontSize: 12 }}
                  />
                  <Tooltip 
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        return (
                          <div className={`p-3 rounded-lg ${isDarkMode ? 'bg-blue-800 text-gray-100' : 'bg-amber-50 text-amber-900'}`}>
                            <p className="font-semibold">{payload[0].payload.stage}</p>
                            <p>Capacity: {payload[0].value.toLocaleString()} MW</p>
                            <p>Duration: {payload[0].payload.hours} hours</p>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Bar 
                    dataKey="mw" 
                    fill={isDarkMode ? '#fbbf24' : '#f59e0b'}
                    radius={[4, 4, 0, 0]}
                  >
                    {loadSheddingData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={isDarkMode ? '#fbbf24' : '#f59e0b'} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <p className={`mt-4 text-sm ${isDarkMode ? 'text-gray-300' : 'text-amber-800'}`}>
              National load shed capacity for each stage of load shedding in South Africa
            </p>
          </section>

          {/* Main Content with refs for scrolling */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <div className="prose prose-lg max-w-none">
              <div ref={(el) => sectionsRef.current['introduction'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Understanding South Africa's 2025 Load Shedding Crisis
                </h2>
                <p className={`text-xl leading-relaxed mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  As we navigate through 2025, <span className="font-bold">South Africa's load shedding crisis</span> has evolved into a critical national challenge, deeply impacting millions of lives and the country's economic stability. This persistent power crisis stems from decades of <span className="font-bold">systemic energy challenges</span>, including infrastructure neglect, heavy coal dependency, and complex governance issues.
                </p>
                <p className={`text-xl leading-relaxed mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Despite notable progress in <span className="font-bold">renewable energy adoption</span> and recent policy reforms, the frequency and duration of power cuts continue to highlight the urgent need for comprehensive, sustainable solutions to South Africa's energy crisis.
                </p>
              </div>

              <div ref={(el) => sectionsRef.current['current-state'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Current State: Eskom's Role in South Africa Load Shedding 2025
                </h2>

                {/* Power Generation State Section */}
                <div className="mb-12">
                  <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    The State of Power Generation
                  </h3>
                  <p className={`mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                    <span className="font-bold">Eskom</span>, South Africa's state-owned utility, continues to be the focal point of the nation's power crisis. The combination of <span className="font-bold">aging coal plants</span> and significant delays in implementing renewable energy projects has left the national grid in a precarious state, with load shedding fluctuating between Stages 3 and 5.
                  </p>
                  
                  {/* Power Generation Stats Grid */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                    {/* Generation Fleet Status */}
                    <div className="h-96">
                      <h4 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                        Current Power Generation Infrastructure
                      </h4>
              <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={powerGenerationData} margin={{ top: 20, right: 30, left: 20, bottom: 70 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                          <XAxis 
                            dataKey="category" 
                            angle={-45} 
                            textAnchor="end" 
                            height={100}
                            interval={0}
                            tick={{ fontSize: 12 }}
                          />
                          <YAxis 
                            label={{ 
                              value: 'Percentage (%)', 
                              angle: -90, 
                              position: 'insideLeft',
                              offset: 10
                            }} 
                          />
                  <Tooltip />
                  <Bar
                            dataKey="percentage"
                    fill={isDarkMode ? '#fbbf24' : '#f59e0b'}
                    radius={[4, 4, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>

                    {/* Unplanned Outages Trend */}
                    <div className="h-96">
                      <h4 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                        2025 Unplanned Outages Trend
                      </h4>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={outagesTrendData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis 
                            dataKey="month" 
                            tick={{ fontSize: 12 }}
                            padding={{ left: 20, right: 20 }}
                          />
                          <YAxis 
                            label={{ 
                              value: 'Megawatts (MW)', 
                              angle: -90, 
                              position: 'insideLeft',
                              offset: 10
                            }}
                            tick={{ fontSize: 12 }}
                          />
                          <Tooltip />
                          <Line
                            type="monotone"
                            dataKey="unplannedOutages"
                            stroke={isDarkMode ? '#fbbf24' : '#f59e0b'}
                            strokeWidth={2}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>

                {/* Urban vs Rural Impact Section */}
                <div className="mb-12">
                  <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    Urban vs. Rural Impact
                  </h3>
                  <p className={`mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                    A stark divide exists between urban and rural areas. While metropolitan regions increasingly benefit from private <span className="font-bold">solar power investments</span>, rural communities bear the brunt of extended power outages, highlighting the growing energy inequality across South Africa.
                  </p>

                  {/* Urban-Rural Comparison Grid */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Load Shedding Hours */}
                    <div className="h-96">
                      <h4 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                        Daily Load Shedding Duration by Region
                      </h4>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={urbanRuralComparisonData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis 
                            dataKey="area" 
                            tick={{ fontSize: 12 }}
                            padding={{ left: 20, right: 20 }}
                          />
                          <YAxis 
                            label={{ 
                              value: 'Hours per Day', 
                              angle: -90, 
                              position: 'insideLeft',
                              offset: 10
                            }}
                            tick={{ fontSize: 12 }}
                          />
                          <Tooltip />
                          <Bar
                            dataKey="loadSheddingHours"
                            fill={isDarkMode ? '#fbbf24' : '#f59e0b'}
                            radius={[4, 4, 0, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
              </div>

                    {/* Power Supply Reliability */}
                    <div className="h-96">
                      <h4 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                        Power Supply Reliability by Region
                      </h4>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={urbanRuralComparisonData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis 
                            dataKey="area" 
                            tick={{ fontSize: 12 }}
                            padding={{ left: 20, right: 20 }}
                          />
                          <YAxis 
                            label={{ 
                              value: 'Reliability (%)', 
                              angle: -90, 
                              position: 'insideLeft',
                              offset: 10
                            }}
                            tick={{ fontSize: 12 }}
                          />
                          <Tooltip />
                          <Bar
                            dataKey="reliability"
                            fill={isDarkMode ? '#fbbf24' : '#f59e0b'}
                            radius={[4, 4, 0, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>

              <div ref={(el) => sectionsRef.current['root-causes'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Root Causes of Load Shedding in South Africa: Infrastructure, Coal & Governance
                </h2>
                <div className="space-y-6">
                  <div>
                    <h3 className={`text-2xl font-semibold mb-3 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Aging Infrastructure and Power Reliability
                    </h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                      A critical <span className="font-bold">60% of Eskom's generation fleet exceeds 30 years in age</span>, resulting in frequent breakdowns and an overwhelming maintenance backlog that severely impacts power reliability.
                    </p>
                  </div>
                  <div>
                    <h3 className={`text-2xl font-semibold mb-3 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Coal Dependency and Energy Source Limitations
                    </h3>
                    <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                      South Africa's heavy reliance on coal, accounting for <span className="font-bold">65% of energy production</span>, creates significant vulnerabilities when coal plants underperform or face supply chain disruptions.
                    </p>
                  </div>
                  <div>
                    <h3 className={`text-2xl font-semibold mb-3 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Financial and Governance Challenges
                    </h3>
                    <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                      With Eskom's debt surpassing <span className="font-bold">R400 billion</span>, modernization efforts are severely constrained. Corruption and bureaucratic inefficiencies have further complicated the implementation of crucial reforms.
                    </p>
                  </div>
                </div>
              </div>

              <div ref={(el) => sectionsRef.current['socio-economic-impact'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Socio-Economic Impact: Daily Challenges and Economic Losses
                </h2>
                <div className="space-y-8">
                  {/* Economic Implications Section */}
                  <div>
                    <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Economic Implications
                    </h3>
                    
                    {/* Daily Losses Stats Card */}
                    <div className={`p-6 rounded-lg mb-8 ${isDarkMode ? 'bg-blue-700/50' : 'bg-amber-200/50'}`}>
                      <div className="flex items-center gap-4 mb-4">
                        <div className={`p-3 rounded-full ${isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'}`}>
                          <TrendingDown className="h-6 w-6" />
                        </div>
                        <h4 className={`text-xl font-semibold ${isDarkMode ? 'text-yellow-200' : 'text-amber-500'}`}>
                          Daily Economic Impact
                        </h4>
                      </div>
                      
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                          <div className="flex items-center gap-2 mb-2">
                            <DollarSign className="h-5 w-5" />
                            <span className="font-bold">Daily Losses</span>
                          </div>
                          <p className="text-2xl font-bold">R500M</p>
                          <p className="text-sm opacity-80">Per Day Impact</p>
                        </div>
                        
                        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                          <div className="flex items-center gap-2 mb-2">
                            <Timer className="h-5 w-5" />
                            <span className="font-bold">Downtime</span>
                          </div>
                          <p className="text-2xl font-bold">4-8h</p>
                          <p className="text-sm opacity-80">Average Daily</p>
                        </div>
                        
                        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                          <div className="flex items-center gap-2 mb-2">
                            <Briefcase className="h-5 w-5" />
                            <span className="font-bold">Jobs</span>
                          </div>
                          <p className="text-2xl font-bold">-2.1%</p>
                          <p className="text-sm opacity-80">Employment Impact</p>
                        </div>
                      </div>
                    </div>

                    {/* Operational Strain Section */}
                    <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-700/50' : 'bg-amber-200/50'}`}>
                      <div className="flex items-center gap-4 mb-6">
                        <div className={`p-3 rounded-full ${isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'}`}>
                          <Factory className="h-6 w-6" />
                        </div>
                        <h4 className={`text-xl font-semibold ${isDarkMode ? 'text-yellow-200' : 'text-amber-500'}`}>
                          Industry Impact Analysis
                        </h4>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                          <div className="flex items-center gap-2 mb-2">
                            <Building2 className="h-5 w-5" />
                            <span className="font-bold">Manufacturing</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                              <div className={`h-2.5 rounded-full ${isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'}`} style={{ width: '65%' }}></div>
                            </div>
                            <span className="text-sm">65%</span>
                          </div>
                          <p className="text-sm mt-2">Production Capacity</p>
                        </div>

                        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                          <div className="flex items-center gap-2 mb-2">
                            <Hammer className="h-5 w-5" />
                            <span className="font-bold">Mining</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                              <div className={`h-2.5 rounded-full ${isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'}`} style={{ width: '55%' }}></div>
                            </div>
                            <span className="text-sm">55%</span>
                          </div>
                          <p className="text-sm mt-2">Operational Efficiency</p>
                        </div>

                        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                          <div className="flex items-center gap-2 mb-2">
                            <Leaf className="h-5 w-5" />
                            <span className="font-bold">Agriculture</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                              <div className={`h-2.5 rounded-full ${isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'}`} style={{ width: '70%' }}></div>
                            </div>
                            <span className="text-sm">70%</span>
                          </div>
                          <p className="text-sm mt-2">Irrigation Coverage</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Essential Services Impact Section */}
                  <div>
                    <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Impact on Essential Services
                    </h3>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {/* Healthcare Impact Card */}
                      <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-700/50' : 'bg-amber-200/50'}`}>
                        <div className="flex items-center gap-4 mb-4">
                          <div className={`p-3 rounded-full ${isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'}`}>
                            <Hospital className="h-6 w-6" />
                          </div>
                          <h4 className={`text-xl font-semibold ${isDarkMode ? 'text-yellow-200' : 'text-amber-500'}`}>
                            Healthcare Facilities
                          </h4>
                        </div>
                        
                        <div className="space-y-4">
                          <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                            <p className="font-bold mb-2">Urban Hospitals</p>
                            <div className="flex items-center gap-2">
                              <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div className={`h-2.5 rounded-full ${isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'}`} style={{ width: '85%' }}></div>
                              </div>
                              <span className="text-sm">85%</span>
                            </div>
                            <p className="text-sm mt-2">Backup Power Coverage</p>
                          </div>

                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <AlertTriangle className="h-4 w-4" />
                              <span>Critical equipment vulnerability</span>
                            </li>
                            <li className="flex items-center gap-2">
                              <Timer className="h-4 w-4" />
                              <span>4-6 hour backup capacity</span>
                            </li>
                </ul>
                        </div>
              </div>

                      {/* Education Impact Card */}
                      <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-700/50' : 'bg-amber-200/50'}`}>
                        <div className="flex items-center gap-4 mb-4">
                          <div className={`p-3 rounded-full ${isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'}`}>
                            <GraduationCap className="h-6 w-6" />
                          </div>
                          <h4 className={`text-xl font-semibold ${isDarkMode ? 'text-yellow-200' : 'text-amber-500'}`}>
                            Education Impact
                          </h4>
                        </div>
                        
                        <div className="space-y-4">
                          <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                            <p className="font-bold mb-2">Rural Schools</p>
                            <div className="flex items-center gap-2">
                              <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div className={`h-2.5 rounded-full ${isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'}`} style={{ width: '35%' }}></div>
                              </div>
                              <span className="text-sm">35%</span>
                            </div>
                            <p className="text-sm mt-2">Power Reliability</p>
                          </div>

                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <Clock className="h-4 w-4" />
                              <span>3.5 hours daily learning disruption</span>
                            </li>
                            <li className="flex items-center gap-2">
                              <MessageCircle className="h-4 w-4" />
                              <span>Digital learning limitations</span>
                            </li>
                </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div ref={(el) => sectionsRef.current['responses'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Responses from Government and the Private Sector
                </h2>
                <div className="space-y-8">
                  <div>
                    <h3 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Policy and Legislative Initiatives
                    </h3>
                    <div className="space-y-6">
                      <div>
                        <h4 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-yellow-200' : 'text-amber-500'}`}>
                          Electricity Regulation Amendment Bill
                        </h4>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                          This critical reform aims to decentralize energy generation and accelerate the adoption of renewable energy solutions. It is a pivotal step toward reducing the burden on Eskom's aging infrastructure.
                        </p>
                      </div>
                      <div>
                        <h4 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-yellow-200' : 'text-amber-500'}`}>
                          Renewable Energy Targets
                        </h4>
                        <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                          Government plans are in place to expand solar and wind capacity, setting a target of a 30% renewable mix by 2030.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Private Sector Innovation
                    </h3>
                    <div className="space-y-6">
                      <div>
                        <h4 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-yellow-200' : 'text-amber-500'}`}>
                          Corporate Investments
                        </h4>
                        <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                          Major companies are investing in solar farms and wind projects to ease the national grid's load.
                        </p>
                      </div>
                      <div>
                        <h4 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-yellow-200' : 'text-amber-500'}`}>
                          Collaborative Partnerships
                        </h4>
                        <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                          Joint ventures between the public and private sectors are helping diversify South Africa's energy sources and stimulate technological innovation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div ref={(el) => sectionsRef.current['path-forward'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Path Forward: Building a Resilient Energy Future
                </h2>
                <div className="space-y-8">
                  <div>
                    <h3 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Renewable Energy Transition
                    </h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                      Accelerating the deployment of wind and solar projects is crucial to reduce coal dependency and stabilize the grid.
                    </p>
                  </div>
                  <div>
                    <h3 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                      Community Energy Solutions
                    </h3>
                    <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                      Developing local microgrids and solar cooperatives can empower underserved regions, ensuring more reliable access to power and fostering energy independence.
                    </p>
                  </div>
                </div>
              </div>

              <div ref={(el) => sectionsRef.current['conclusion'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Conclusion
                </h2>
                <p className={`mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  South Africa's load shedding crisis in 2025 poses significant challenges—but also opportunities for transformation. With coordinated efforts from government, the private sector, and local communities, coupled with strategic investments in renewable energy and modern infrastructure, the nation can build a more resilient and sustainable energy future.
                </p>
              </div>
            </div>
          </section>

          {/* FAQs Section */}
          <div ref={(el) => sectionsRef.current['faqs'] = el}>
            <section className={`p-8 rounded-lg shadow-xl mb-12 ${
              isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
            }`}>
              <div className="flex items-center justify-between mb-8">
                <h2 className={`text-3xl font-bold ${
                  isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                }`}>
                  Frequently Asked Questions
                </h2>
                <HelpCircle className={`h-8 w-8 ${
                  isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                }`} />
              </div>

              {/* Category Filter */}
              <div className="flex flex-wrap gap-2 mb-6">
                {faqCategories.map(category => (
                  <button
                    key={category}
                    onClick={() => setSelectedCategory(category)}
                    className={`px-4 py-2 rounded-full transition-all duration-200 ${
                      selectedCategory === category
                        ? isDarkMode
                          ? 'bg-yellow-400 text-blue-900'
                          : 'bg-amber-500 text-white'
                        : isDarkMode
                          ? 'bg-blue-700 text-gray-300 hover:bg-blue-600'
                          : 'bg-amber-200 text-amber-900 hover:bg-amber-300'
                    }`}
                  >
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </button>
                ))}
              </div>

              {/* FAQ Items */}
              <div className="space-y-4">
                {filteredFaqs.map((faq) => (
                  <div
                    key={faq.id}
                    className={`border rounded-lg transition-all duration-300 ${
                      isDarkMode
                        ? 'border-blue-700 hover:border-yellow-400'
                        : 'border-amber-200 hover:border-amber-500'
                    }`}
                  >
                    <button
                      onClick={() => toggleFaq(faq.id)}
                      className={`w-full px-6 py-4 flex items-center justify-between ${
                        expandedFaqs.has(faq.id)
                          ? isDarkMode
                            ? 'bg-blue-700'
                            : 'bg-amber-200'
                          : ''
                      }`}
                    >
                      <span className={`font-semibold text-left ${
                        isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                      }`}>
                        {faq.question}
                      </span>
                      {expandedFaqs.has(faq.id) 
                        ? <Minus className="h-5 w-5 flex-shrink-0" />
                        : <Plus className="h-5 w-5 flex-shrink-0" />
                      }
                    </button>
                    
                    {/* Answer */}
                    {expandedFaqs.has(faq.id) && (
                      <div className={`px-6 py-4 border-t ${
                        isDarkMode ? 'border-blue-700 bg-blue-700/50' : 'border-amber-200 bg-amber-200/50'
                      }`}>
                        <div className={`space-y-2 ${
                          isDarkMode ? 'text-gray-300' : 'text-amber-900'
                        }`}>
                          {faq.answer.split('\n').map((line, index) => (
                            <p 
                              key={index} 
                              className={`${line.startsWith('•') ? 'pl-4' : ''} ${
                                line.startsWith('•') ? 'border-l-2 border-yellow-400' : ''
                              }`}
                            >
                              {line}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>
          </div>

          {/* Article Feedback Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
              Was this article helpful?
            </h2>
            <div className="flex gap-4">
              <button
                onClick={() => setFeedback(prev => ({ ...prev, helpful: prev.helpful + 1 }))}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-blue-700 hover:bg-blue-600'
                    : 'bg-amber-200 hover:bg-amber-300'
                }`}
              >
                <ThumbsUp className="h-5 w-5" />
                <span>Yes ({feedback.helpful})</span>
              </button>
              <button
                onClick={() => setFeedback(prev => ({ ...prev, notHelpful: prev.notHelpful + 1 }))}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-blue-700 hover:bg-blue-600'
                    : 'bg-amber-200 hover:bg-amber-300'
                }`}
              >
                <ThumbsDown className="h-5 w-5" />
                <span>No ({feedback.notHelpful})</span>
              </button>
            </div>
          </section>

          {/* Related Articles Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
              Related Articles
            </h2>
            <div className="grid gap-6">
              {relatedArticles.map((article, index) => (
                <Link
                  key={index}
                  to={article.url}
                  className={`p-4 rounded-lg transition-all duration-300 ${
                    isDarkMode
                      ? 'bg-blue-700 hover:bg-blue-600'
                      : 'bg-amber-200 hover:bg-amber-300'
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <h3 className={`font-semibold ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                    }`}>
                      {article.title}
                    </h3>
                    <ArrowRight className={`h-5 w-5 ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                    }`} />
                  </div>
                  <p className="text-sm mt-2">{article.date}</p>
                </Link>
              ))}
            </div>
          </section>

          {/* Scroll to Top Button */}
          {showScrollTop && (
            <button
              onClick={scrollToTop}
              className={`fixed bottom-8 right-8 p-3 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 ${
                isDarkMode
                  ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                  : 'bg-amber-500 text-white hover:bg-amber-600'
              }`}
            >
              <ChevronUp className="h-6 w-6" />
            </button>
          )}
        </article>
      </main>
    </div>
  );
};

export default LoadSheddingSouthAfrica2025; 