import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useTheme } from '../../contexts/ThemeContext';
import { HelpCircle, Calculator, Download, DollarSign, Sun, TrendingUp, Calendar, Leaf } from 'lucide-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import debounce from 'lodash/debounce';
import SeoHead from '../common/SeoHead';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

// Currency formatter for South African Rand
const currencyFormatter = new Intl.NumberFormat('en-ZA', {
  style: 'currency',
  currency: 'ZAR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const validateInputs = (inputs) => {
  const errors = [];
  if (inputs.monthlyBill <= 0) errors.push('Monthly bill must be greater than 0');
  if (inputs.systemSize <= 0) errors.push('System size must be greater than 0');
  if (inputs.electricityRate <= 0) errors.push('Electricity rate must be greater than 0');
  if (inputs.annualIncrease < 0 || inputs.annualIncrease > 20) errors.push('Annual increase must be between 0 and 20%');
  if (inputs.systemCost <= 0) errors.push('System cost must be greater than 0');
  return errors;
};

const SavingsCalculator = () => {
  const { isDarkMode } = useTheme();
  const [inputs, setInputs] = useState({
    monthlyBill: 2000,
    systemSize: 5,
    electricityRate: 2.5,
    annualIncrease: 12,
    systemCost: 75000,
  });
  const [savings, setSavings] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [errors, setErrors] = useState([]);
  const chartRef = useRef(null);

  // SEO Configuration
  const seoData = {
    title: 'Solar Investment Savings Calculator | ROI Calculator',
    description: 'Calculate your potential savings and return on investment (ROI) from installing solar power. Get detailed financial projections, payback period analysis, and long-term savings estimates for your solar investment in South Africa.',
    keywords: 'solar savings calculator, solar ROI calculator, solar investment calculator, solar payback period, solar financial benefits, solar cost savings, solar investment return, South Africa solar savings',
    path: '/calculators/savings',
    image: '/images/savings-calculator.jpg',
    purpose: 'financial returns and savings from solar power investment',
    features: [
      'ROI Calculation',
      'Payback Period Analysis',
      'Monthly Savings Projection',
      'Long-term Financial Benefits',
      'Carbon Footprint Reduction',
      'Electricity Cost Comparison'
    ],
    calculations: [
      'Monthly Energy Savings',
      'Annual Financial Returns',
      'Carbon Emission Reduction',
      'System Payback Period',
      'Lifetime Savings',
      'Investment Value'
    ],
    lastUpdated: new Date().toISOString()
  };

  // Debounced calculation function
  const debouncedCalculate = useCallback(
    debounce((currentInputs) => {
      const validationErrors = validateInputs(currentInputs);
      setErrors(validationErrors);
      
      if (validationErrors.length === 0) {
        calculateSavings(currentInputs);
      }
    }, 500),
    []
  );

  const calculateSavings = useCallback((currentInputs) => {
    const { monthlyBill, systemSize, electricityRate, annualIncrease, systemCost } = currentInputs;

    // Calculate annual consumption and production
    const annualConsumption = (monthlyBill / electricityRate) * 12; // kWh per year
    const annualProduction = systemSize * 1600; // kWh per year (1600 kWh per kW in SA)
    const offsetPercentage = Math.min(annualProduction / annualConsumption, 1);

    // Calculate first year savings (based on actual bill reduction)
    const firstYearSavings = (monthlyBill * 12) * offsetPercentage;

    // Calculate savings over 25 years with compound annual increase
    const savingsData = [];
    let yearSavings = firstYearSavings;
    let cumulativeSavings = 0;
    const cumulativeData = [];

    for (let i = 0; i < 25; i++) {
      savingsData.push(yearSavings);
      cumulativeSavings += yearSavings;
      cumulativeData.push(cumulativeSavings);
      // Calculate next year's savings with electricity price increase
      yearSavings = yearSavings * (1 + (annualIncrease / 100));
    }

    // Calculate ROI and payback period
    const netReturn = cumulativeSavings - systemCost;
    const roi = (netReturn / systemCost) * 100;

    // Find payback period (when cumulative savings exceed system cost)
    const paybackPeriod = cumulativeData.findIndex(total => total >= systemCost) + 1 || '>25';

    setSavings({
      firstYear: firstYearSavings,
      cumulative25Years: cumulativeSavings,
      offsetPercentage: (offsetPercentage * 100).toFixed(1),
      roi: roi.toFixed(2),
      paybackPeriod,
      monthlyAverage: cumulativeSavings / (25 * 12)
    });

    setChartData({
      labels: Array.from({ length: 25 }, (_, i) => `Year ${i + 1}`),
      datasets: [
        {
          label: 'Annual Savings',
          data: savingsData,
          borderColor: isDarkMode ? 'rgba(255, 206, 86, 1)' : 'rgba(75, 192, 192, 1)',
          backgroundColor: isDarkMode ? 'rgba(255, 206, 86, 0.1)' : 'rgba(75, 192, 192, 0.1)',
          borderDash: [5, 5],
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: false,
        },
        {
          label: 'Cumulative Savings',
          data: cumulativeData,
          borderColor: isDarkMode ? 'rgba(255, 99, 132, 1)' : 'rgba(153, 102, 255, 1)',
          backgroundColor: isDarkMode ? 'rgba(255, 99, 132, 0.1)' : 'rgba(153, 102, 255, 0.1)',
          borderWidth: 3,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true,
        },
      ],
    });
  }, [isDarkMode]);

  useEffect(() => {
    debouncedCalculate(inputs);
    return () => debouncedCalculate.cancel();
  }, [inputs, debouncedCalculate]);

  useEffect(() => {
    const savedInputs = localStorage.getItem('solarCalculatorInputs');
    if (savedInputs) {
      setInputs(JSON.parse(savedInputs));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('solarCalculatorInputs', JSON.stringify(inputs));
  }, [inputs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numValue = parseFloat(value) || 0;
    
    // Don't update if the value is negative
    if (numValue < 0) return;
    
    // Update the inputs state
    setInputs(prev => {
      const newInputs = { ...prev, [name]: numValue };
      // Immediately trigger calculation with new inputs
      debouncedCalculate(newInputs);
      return newInputs;
    });
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          color: isDarkMode ? '#ffffff' : '#000000',
        },
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;
          } else {
            ci.show(index);
            legendItem.hidden = false;
          }
        },
      },
      title: {
        display: true,
        text: 'Projected Solar Investment Returns',
        color: isDarkMode ? '#ffffff' : '#000000',
        font: {
          size: 16,
          weight: 'bold',
        },
        padding: { bottom: 20 }
      },
      tooltip: {
        enabled: true,
        backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        titleColor: isDarkMode ? '#fff' : '#000',
        bodyColor: isDarkMode ? '#fff' : '#000',
        titleFont: { size: 14, weight: 'bold' },
        bodyFont: { size: 13 },
        padding: 12,
        cornerRadius: 8,
        callbacks: {
          title: (tooltipItems) => {
            return `Year ${tooltipItems[0].label}`;
          },
          label: (context) => {
            const label = context.dataset.label;
            const value = currencyFormatter.format(context.raw);
            return `${label}: ${value}`;
          },
          afterBody: (tooltipItems) => {
            const annualSavings = tooltipItems[0].raw;
            const cumulative = tooltipItems[1]?.raw;
            return [
              '',
              `Monthly Average: ${currencyFormatter.format(annualSavings / 12)}`,
              cumulative ? `Total Savings: ${currencyFormatter.format(cumulative)}` : '',
            ];
          },
        }
      },
      annotation: {
        annotations: {
          paybackLine: {
            type: 'line',
            xMin: savings?.paybackPeriod - 1,
            xMax: savings?.paybackPeriod - 1,
            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
            borderWidth: 2,
            borderDash: [6, 6],
            label: {
              display: true,
              content: `Payback Period: Year ${savings?.paybackPeriod}`,
              position: 'start',
              backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
              color: isDarkMode ? '#fff' : '#000',
              font: { weight: 'bold' },
              padding: 8,
            }
          },
          breakEvenPoint: {
            type: 'point',
            xValue: savings?.paybackPeriod - 1,
            yValue: inputs.systemCost,
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
            radius: 6,
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          drawBorder: false,
        },
        ticks: {
          color: isDarkMode ? '#ffffff' : '#000000',
          font: { size: 12 },
          padding: 8,
        },
        title: {
          display: true,
          text: 'Years',
          color: isDarkMode ? '#ffffff' : '#000000',
          font: { size: 14, weight: 'bold' },
          padding: { top: 20 }
        }
      },
      y: {
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          drawBorder: false,
        },
        beginAtZero: true,
        ticks: {
          color: isDarkMode ? '#ffffff' : '#000000',
          font: { size: 12 },
          padding: 8,
          callback: function(value) {
            return currencyFormatter.format(value);
          }
        },
        title: {
          display: true,
          text: 'Savings (ZAR)',
          color: isDarkMode ? '#ffffff' : '#000000',
          font: { size: 14, weight: 'bold' },
          padding: { bottom: 20 }
        }
      }
    },
  };

  const resetCalculator = () => {
    setInputs({
      monthlyBill: 2000,
      systemSize: 5,
      electricityRate: 2.5,
      annualIncrease: 12,
      systemCost: 75000,
    });
    setSavings(null);
    setChartData(null);
    setErrors([]);
  };

  const handleExport = useCallback(() => {
    if (chartData && chartRef.current) {
      const link = document.createElement('a');
      link.download = 'solar-savings-calculation.png';
      link.href = chartRef.current.toBase64Image();
      link.click();
    }
  }, [chartData]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="calculator" data={seoData} />
      
      <div className="flex items-center justify-center mb-4 sm:mb-6">
        <Calculator className={`w-8 h-8 sm:w-10 sm:h-10 mr-3 sm:mr-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
        <h1 className={`text-2xl sm:text-3xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'} text-center`}>
          Solar Investment Savings Calculator
        </h1>
      </div>
      
      <div className={`grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow rounded-lg p-4 sm:p-6`}>
        <div className="w-full">
          <InputField
            label="Monthly Electricity Bill (R)"
            name="monthlyBill"
            value={inputs.monthlyBill}
            onChange={handleInputChange}
            min={0}
            max={10000}
            step={50}
            tooltip="Your average monthly electricity cost from Eskom or your local provider."
          />
          <InputField
            label="Solar System Size (kW)"
            name="systemSize"
            value={inputs.systemSize}
            onChange={handleInputChange}
            min={1}
            max={20}
            step={0.5}
            tooltip="The size of your solar system in kilowatts (kW)."
          />
          <InputField
            label="Electricity Rate (R/kWh)"
            name="electricityRate"
            value={inputs.electricityRate}
            onChange={handleInputChange}
            min={0.1}
            max={5}
            step={0.01}
            tooltip="The current price you pay per kilowatt-hour (kWh) of electricity."
          />
          <InputField
            label="Annual Electricity Price Increase (%)"
            name="annualIncrease"
            value={inputs.annualIncrease}
            onChange={handleInputChange}
            min={0}
            max={15}
            step={0.5}
            tooltip="The expected annual increase in electricity prices."
          />
          <InputField
            label="System Cost (R)"
            name="systemCost"
            value={inputs.systemCost}
            onChange={handleInputChange}
            min={0}
            max={1000000}
            step={1000}
            tooltip="The total cost of your solar system including installation."
          />
        </div>
        
        {savings && (
          <div className="text-center flex flex-col justify-center space-y-2 sm:space-y-3">
            <h2 className="text-xl sm:text-2xl font-semibold mb-2 sm:mb-4">Estimated Savings</h2>
            <p className="text-lg sm:text-xl">First Year: <span className="font-bold text-green-500">{currencyFormatter.format(savings.firstYear)}</span></p>
            <p className="text-lg sm:text-xl">25 Year Cumulative: <span className="font-bold text-green-500">{currencyFormatter.format(savings.cumulative25Years)}</span></p>
            <p className="text-lg sm:text-xl">Monthly Average: <span className="font-bold text-green-500">{currencyFormatter.format(savings.monthlyAverage)}</span></p>
            <p className="text-lg sm:text-xl">Energy Offset: <span className="font-bold text-blue-500">{savings.offsetPercentage}%</span></p>
            <p className="text-lg sm:text-xl">Return on Investment: <span className="font-bold text-blue-500">{savings.roi}%</span></p>
            <p className="text-lg sm:text-xl">Payback Period: <span className="font-bold text-blue-500">{savings.paybackPeriod} years</span></p>
          </div>
        )}
      </div>
      
      {errors.length > 0 && (
        <div className="mt-3 sm:mt-4 p-3 sm:p-4 bg-red-200 text-red-800 rounded text-sm sm:text-base">
          <ul className="list-disc list-inside">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      {chartData && (
        <div className={`mt-4 sm:mt-8 p-3 sm:p-4 ${isDarkMode ? 'bg-blue-800' : 'bg-white'} rounded-lg shadow`}>
          <div className="flex justify-end mb-2">
            <button
              onClick={handleExport}
              className="flex items-center gap-2 px-3 py-1.5 sm:px-4 sm:py-2 bg-green-500 text-white text-sm sm:text-base rounded hover:bg-green-600 transition-colors"
              aria-label="Export chart as image"
            >
              <Download size={16} />
              Export Chart
            </button>
          </div>
          <div className="h-[300px] sm:h-[400px] lg:h-[500px]">
            <Line 
              ref={chartRef}
              options={{
                ...chartOptions,
                plugins: {
                  ...chartOptions.plugins,
                  legend: {
                    ...chartOptions.plugins.legend,
                    labels: {
                      ...chartOptions.plugins.legend.labels,
                      font: {
                        size: window.innerWidth < 640 ? 10 : 12
                      }
                    }
                  }
                }
              }} 
              data={chartData}
              aria-label="Solar savings projection chart"
            />
          </div>
        </div>
      )}

      <button
        onClick={resetCalculator}
        className="mt-3 sm:mt-4 px-3 py-1.5 sm:px-4 sm:py-2 bg-red-500 text-white text-sm sm:text-base rounded hover:bg-red-600 transition-colors"
      >
        Reset Calculator
      </button>
    </div>
  );
};

const InputField = ({ label, name, value, onChange, min, max, step, tooltip }) => {
  const { isDarkMode } = useTheme();
  const id = `input-${name}`;
  
  return (
    <div className="mb-4 sm:mb-6">
      <label 
        htmlFor={id}
        className="block text-sm sm:text-base font-medium mb-1.5 sm:mb-2 flex items-center"
        aria-label={`${label} - ${tooltip}`}
      >
        {label}
        <span 
          className="ml-1.5 sm:ml-2 cursor-help" 
          data-tooltip-id={`tooltip-${name}`}
          role="tooltip"
          aria-label={tooltip}
        >
          <HelpCircle size={14} className="sm:w-4 sm:h-4" />
        </span>
      </label>
      
      <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 items-stretch sm:items-center">
        <input
          type="range"
          id={`${id}-slider`}
          name={name}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
          className={`w-full sm:w-2/3 h-2 rounded-lg appearance-none cursor-pointer ${
            isDarkMode 
              ? 'bg-blue-700 accent-yellow-400' 
              : 'bg-amber-200 accent-amber-500'
          }`}
          aria-labelledby={id}
        />
        
        <input
          type="number"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
          className={`w-full sm:w-1/3 px-2 sm:px-3 py-1.5 sm:py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-opacity-50 text-sm sm:text-base ${
            isDarkMode 
              ? 'bg-blue-700 border-blue-600 focus:ring-yellow-400 text-white' 
              : 'bg-amber-50 border-amber-300 focus:ring-amber-400 text-amber-900'
          }`}
          aria-label={`${label} numeric input`}
        />
      </div>
      
      <ReactTooltip 
        id={`tooltip-${name}`} 
        place="right" 
        effect="solid"
        className="max-w-[200px] sm:max-w-xs text-xs sm:text-sm"
      >
        {tooltip}
      </ReactTooltip>
    </div>
  );
};

export default SavingsCalculator;