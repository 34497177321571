import React, { useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import SeoHead from './common/SeoHead';
import { Sun, FileText, TrendingUp, Leaf, DollarSign, Search, ChevronDown, ExternalLink, Bookmark, Share2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const SolarEnergyNews = () => {
  const { isDarkMode } = useTheme();
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('newest');
  const [newsArticles, setNewsArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [savedArticles, setSavedArticles] = useState([]);

  const headerClass = isDarkMode
    ? 'text-yellow-400 hover:glow-yellow-300'
    : 'text-amber-700';

  const categories = [
    { id: 'all', name: 'All', icon: Sun },
    { id: 'technology', name: 'Technology', icon: Sun },
    { id: 'policy', name: 'Policy & Regulations', icon: FileText },
    { id: 'market', name: 'Market Trends', icon: TrendingUp },
    { id: 'environment', name: 'Environmental Impact', icon: Leaf },
    { id: 'economics', name: 'Economic Aspects', icon: DollarSign },
  ];

  // Simulated news data - in a real application, this would come from an API
  const sampleNews = [
    {
      id: 4,
      title: "Stage 6 Loadshedding Crisis in South Africa: Impact Analysis, Solutions & Recovery Plan 2025",
      category: "policy",
      date: "2025-02-15",
      summary: "In-depth analysis of South Africa's Stage 6 loadshedding crisis, examining the unprecedented 6,000MW power cuts, R500M daily economic losses, and comprehensive solutions. Features expert insights on grid stability, emergency measures, renewable energy transition, and economic recovery strategies. Essential reading for businesses and households affected by severe power outages.",
      source: "Solar Insight",
      url: "/news/stage-6-loadshedding-south-africa",
      isDetailPage: true,
      image: "/images/stage-6-loadshedding.jpg",
      readTime: "10 min read",
      keywords: "Stage 6 loadshedding, Eskom power cuts, load shedding schedule, power outages SA, grid stability, energy crisis solutions, renewable energy transition, economic impact, emergency power measures, solar power solutions",
      subheaders: [
        "Understanding Stage 6: South Africa's Most Severe Power Crisis",
        "Economic Impact: R500M Daily Losses & Business Disruption",
        "Critical Infrastructure: Healthcare & Essential Services at Risk",
        "Emergency Response: Government & Private Sector Initiatives",
        "Sustainable Solutions: Renewable Energy & Grid Modernization",
        "Recovery Timeline: Path to Energy Stability 2025-2026"
      ]
    },
    {
      id: 3,
      title: "South Africa's New Solar Registration Laws: What Every Homeowner Needs to Know",
      category: "policy",
      date: "2025-02-10",
      summary: "A comprehensive guide to the new solar registration laws in South Africa, outlining the regulations, deadlines, costs, and benefits for homeowners.",
      source: "Solar Insight",
      url: "/news/solar-registration-laws-2025",
      isDetailPage: true,
      image: "/images/solar-registration-laws.jpg",
      readTime: "10 min read"
    },
    {
      id: 2,
      title: 'Load Shedding in South Africa 2025: Crisis and Solutions',
      category: 'policy',
      date: '2025-01-31',
      summary: 'A comprehensive analysis of South Africa\'s ongoing load shedding crisis, examining root causes, socio-economic impacts, and pathways toward a sustainable energy future.',
      source: 'Solar Insight',
      url: '/news/load-shedding-south-africa-2025',
      isDetailPage: true,
      image: '/images/load-shedding-2025.jpg',
      readTime: '8 min read'
    },
    {
      id: 1,
      title: 'New Perovskite Solar Cells Break Efficiency Record',
      category: 'technology',
      date: '2025-01-29',
      summary: 'Groundbreaking research reveals new perovskite solar cell technology achieving 29.15% efficiency, setting a new world record and promising a revolution in solar energy.',
      source: 'Solar Tech Today',
      url: '/news/perovskite-solar-breakthrough-2025',
      isDetailPage: true,
      image: '/images/perovskite-breakthrough.jpg',
      readTime: '5 min read'
    },
    // ... (other news items)
  ];

  useEffect(() => {
    // Simulate API call to fetch news
    setTimeout(() => {
      setNewsArticles(sampleNews);
      setIsLoading(false);
    }, 1000);
  }, []);

  const filteredAndSortedNews = newsArticles
    .filter(article => 
      (activeCategory === 'all' || article.category === activeCategory) &&
      (article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       article.summary.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      if (sortOption === 'newest') {
        return new Date(b.date) - new Date(a.date);
      } else {
        return new Date(a.date) - new Date(b.date);
      }
    });

  const toggleSaveArticle = (articleId) => {
    setSavedArticles(prev => 
      prev.includes(articleId) 
        ? prev.filter(id => id !== articleId)
        : [...prev, articleId]
    );
  };

  const shareArticle = (article) => {
    if (navigator.share) {
      navigator.share({
        title: article.title,
        text: article.summary,
        url: article.url,
      }).then(() => {
        console.log('Successful share');
      }).catch((error) => {
        console.log('Error sharing', error);
      });
    } else {
      alert('Web Share API is not supported in your browser. You can copy the article URL to share.');
    }
  };

  // SEO Configuration
  const seoData = {
    title: "Solar Energy News and Updates",
    description: "Stay informed with the latest solar energy news, industry updates, and policy changes in South Africa. Expert analysis, market trends, and technological breakthroughs in renewable energy.",
    keywords: "solar news, renewable energy updates, solar industry news, energy policy updates, solar technology news, South Africa solar news, green energy developments",
    path: "/news",
    image: "/images/news-header.jpg",
    lastUpdated: new Date().toISOString(),
    articleCount: sampleNews.length,
    categories: categories.map(cat => cat.name).join(', ')
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="general" data={seoData} />

      <main className="max-w-6xl mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${headerClass}`}>
          Solar Energy News
        </h1>

        {/* Category filters */}
        <div className="flex flex-wrap justify-center mb-6">
          {categories.map(category => (
            <button
              key={category.id}
              className={`m-1 px-4 py-2 rounded-full flex items-center transition-colors duration-200 ${
                activeCategory === category.id 
                  ? 'bg-yellow-400 text-blue-900' 
                  : isDarkMode 
                    ? 'bg-blue-800 text-white hover:bg-blue-700' 
                    : 'bg-white text-gray-800 hover:bg-gray-100'
              }`}
              onClick={() => setActiveCategory(category.id)}
            >
              <category.icon className="mr-2 h-4 w-4" />
              {category.name}
            </button>
          ))}
        </div>

        {/* Search and sort */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <div className="relative w-full md:w-64 mb-4 md:mb-0">
            <input
              type="text"
              placeholder="Search news..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`w-full pl-10 pr-4 py-2 rounded-lg ${isDarkMode ? 'bg-blue-800 text-white' : 'bg-white text-gray-900'} focus:outline-none focus:ring-2 focus:ring-yellow-400`}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <div className="relative w-full md:w-64">
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className={`w-full pl-4 pr-10 py-2 rounded-lg appearance-none ${isDarkMode ? 'bg-blue-800 text-white' : 'bg-white text-gray-900'} focus:outline-none focus:ring-2 focus:ring-yellow-400`}
            >
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
            </select>
            <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        {/* News articles */}
        {isLoading ? (
          <div className="text-center">Loading news...</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredAndSortedNews.map(article => (
              <div key={article.id} className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-blue-800' : 'bg-white'} transition-transform duration-200 hover:scale-105`}>
                <h2 className={`text-xl font-bold mb-2 ${headerClass}`}>{article.title}</h2>
                <p className={`text-sm mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{article.date} | {article.source}</p>
                <p className="mb-4">{article.summary}</p>
                <div className="flex justify-between items-center">
                  {article.isDetailPage ? (
                    <Link
                      to={article.url}
                      className={`inline-flex items-center ${isDarkMode ? 'text-yellow-400 hover:text-yellow-300' : 'text-amber-600 hover:text-amber-700'}`}
                    >
                      Read More <ExternalLink className="ml-1 h-4 w-4" />
                    </Link>
                  ) : (
                    <a
                      href={article.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`inline-flex items-center ${isDarkMode ? 'text-yellow-400 hover:text-yellow-300' : 'text-amber-600 hover:text-amber-700'}`}
                    >
                      Read More <ExternalLink className="ml-1 h-4 w-4" />
                    </a>
                  )}
                  <div className="flex space-x-2">
                    <button
                      onClick={() => toggleSaveArticle(article.id)}
                      className={`p-1 rounded-full ${
                        savedArticles.includes(article.id)
                          ? 'bg-yellow-400 text-blue-900'
                          : isDarkMode
                            ? 'bg-blue-700 text-white'
                            : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      <Bookmark className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => shareArticle(article)}
                      className={`p-1 rounded-full ${
                        isDarkMode
                          ? 'bg-blue-700 text-white'
                          : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      <Share2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {filteredAndSortedNews.length === 0 && !isLoading && (
          <div className="text-center mt-8">No news articles found matching your criteria.</div>
        )}
      </main>
    </div>
  );
};

export default SolarEnergyNews;