// Base SEO configuration
const baseSeoConfig = {
  siteName: 'Solar Insight',
  baseUrl: 'https://solarinsight.co.za',
  defaultImage: '/images/og-default.jpg',
  twitterHandle: '@solarinsightsa',
  organization: {
    name: 'Solar Insight',
    logo: 'https://solarinsight.co.za/logo.png'
  }
};

// Template generators for different page types
const seoTemplates = {
  calculator: (data) => ({
    title: `${data.title} | Free Solar Calculator | ${baseSeoConfig.siteName}`,
    description: `Calculate ${data.purpose}. Get instant results, personalized recommendations, and cost estimates for your solar installation in South Africa.`,
    keywords: `solar calculator, ${data.keywords}, solar power calculator, solar energy tools, South Africa solar`,
    ogType: 'website',
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "WebApplication",
      "name": data.title,
      "description": data.description,
      "applicationCategory": "Calculator",
      "operatingSystem": "Any",
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "ZAR"
      }
    }
  }),

  article: (data) => ({
    title: `${data.title} | ${baseSeoConfig.siteName}`,
    description: data.description,
    keywords: `${data.keywords}, solar news, South Africa solar, solar energy updates`,
    ogType: 'article',
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": data.title,
      "description": data.description,
      "datePublished": data.publishDate,
      "dateModified": data.modifiedDate || data.publishDate,
      "image": data.image || baseSeoConfig.defaultImage,
      "author": {
        "@type": "Organization",
        "name": baseSeoConfig.organization.name
      },
      "publisher": {
        "@type": "Organization",
        "name": baseSeoConfig.organization.name,
        "logo": {
          "@type": "ImageObject",
          "url": baseSeoConfig.organization.logo
        }
      }
    }
  }),

  product: (data) => ({
    title: `${data.title} | Solar Products | ${baseSeoConfig.siteName}`,
    description: `Explore ${data.description}. Find detailed specifications, pricing, and expert recommendations for your solar installation needs.`,
    keywords: `${data.keywords}, solar products, solar equipment, South Africa solar`,
    ogType: 'product',
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": data.title,
      "description": data.description,
      "brand": {
        "@type": "Brand",
        "name": baseSeoConfig.organization.name
      },
      "offers": {
        "@type": "AggregateOffer",
        "priceCurrency": "ZAR",
        "availability": "https://schema.org/InStock"
      }
    }
  }),

  service: (data) => ({
    title: `${data.title} | Professional Solar Services | ${baseSeoConfig.siteName}`,
    description: `Professional ${data.description}. Expert technicians, competitive pricing, and reliable service across South Africa.`,
    keywords: `${data.keywords}, solar services, professional solar, South Africa solar`,
    ogType: 'service',
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": data.title,
      "description": data.description,
      "provider": {
        "@type": "Organization",
        "name": baseSeoConfig.organization.name,
        "logo": baseSeoConfig.organization.logo
      },
      "areaServed": {
        "@type": "Country",
        "name": "South Africa"
      }
    }
  }),

  general: (data) => ({
    title: `${data.title} | ${baseSeoConfig.siteName}`,
    description: data.description,
    keywords: `${data.keywords}, solar power, solar energy, South Africa solar`,
    ogType: 'website',
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": data.title,
      "description": data.description,
      "publisher": {
        "@type": "Organization",
        "name": baseSeoConfig.organization.name,
        "logo": baseSeoConfig.organization.logo
      }
    }
  })
};

// Helper function to generate full SEO data
export const generateSeoData = (template, data) => {
  // Ensure we have valid data
  if (!data) {
    console.warn('No SEO data provided');
    return getDefaultSeoData();
  }

  // Generate SEO data based on template type
  const baseData = {
    canonical: `${baseSeoConfig.baseUrl}${data.path}`,
    ogImage: data.image || baseSeoConfig.defaultImage,
    twitterCard: 'summary_large_image',
    twitterSite: baseSeoConfig.twitterHandle
  };

  switch (template) {
    case 'product':
      return {
        ...baseData,
        title: `${data.title} | Solar Products | ${baseSeoConfig.siteName}`,
        description: data.description,
        keywords: data.keywords,
        ogType: 'product',
        jsonLd: {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": data.title,
          "description": data.description,
          "image": data.image,
          "brand": {
            "@type": "Brand",
            "name": baseSeoConfig.organization.name
          },
          "offers": {
            "@type": "AggregateOffer",
            "priceCurrency": data.price?.currency || "ZAR",
            "lowPrice": data.price?.minPrice,
            "highPrice": data.price?.maxPrice,
            "availability": "https://schema.org/InStock"
          },
          "category": data.category,
          "manufacturer": data.brands?.[0] || baseSeoConfig.organization.name
        }
      };

    case 'article':
      return {
        ...baseData,
        title: `${data.title} | Solar News | ${baseSeoConfig.siteName}`,
        description: data.description,
        keywords: data.keywords,
        ogType: 'article',
        jsonLd: {
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": data.title,
          "description": data.description,
          "image": data.image,
          "datePublished": data.publishDate,
          "dateModified": data.modifiedDate || data.publishDate,
          "author": {
            "@type": "Person",
            "name": data.author
          },
          "publisher": {
            "@type": "Organization",
            "name": baseSeoConfig.organization.name,
            "logo": {
              "@type": "ImageObject",
              "url": baseSeoConfig.organization.logo
            }
          }
        }
      };

    case 'calculator':
      return {
        ...baseData,
        title: `${data.title} | Solar Calculator | ${baseSeoConfig.siteName}`,
        description: data.description,
        keywords: data.keywords,
        ogType: 'website',
        jsonLd: {
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": data.title,
          "description": data.description,
          "applicationCategory": "Calculator",
          "operatingSystem": "Any",
          "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "ZAR"
          }
        }
      };

    case 'service':
      return {
        ...baseData,
        title: `${data.title} | Professional Solar Services | ${baseSeoConfig.siteName}`,
        description: data.description,
        keywords: data.keywords,
        ogType: 'service',
        jsonLd: {
          "@context": "https://schema.org",
          "@type": "Service",
          "name": data.title,
          "description": data.description,
          "provider": {
            "@type": "Organization",
            "name": baseSeoConfig.organization.name,
            "logo": baseSeoConfig.organization.logo
          },
          "areaServed": {
            "@type": "Country",
            "name": "South Africa"
          }
        }
      };

    case 'general':
      return {
        ...baseData,
        title: data.title ? `${data.title} | ${baseSeoConfig.siteName}` : baseSeoConfig.siteName,
        description: data.description,
        keywords: data.keywords,
        ogType: 'website',
        jsonLd: {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": data.title,
          "description": data.description,
          "publisher": {
            "@type": "Organization",
            "name": baseSeoConfig.organization.name,
            "logo": baseSeoConfig.organization.logo
          }
        }
      };

    default:
      return getDefaultSeoData();
  }
};

const getDefaultSeoData = () => ({
  title: 'Solar Power Solutions | Solar Insight',
  description: 'Leading provider of solar power solutions in South Africa. Expert installation, maintenance, and support for residential and commercial solar systems.',
  keywords: 'solar power, solar energy, solar installation, solar panels, South Africa solar, renewable energy, load shedding solutions',
  ogType: 'website',
  canonical: baseSeoConfig.baseUrl,
  ogImage: baseSeoConfig.defaultImage,
  twitterCard: 'summary_large_image',
  twitterSite: baseSeoConfig.twitterHandle,
  jsonLd: {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": baseSeoConfig.organization.name,
    "description": "Leading provider of solar power solutions in South Africa",
    "url": baseSeoConfig.baseUrl,
    "logo": baseSeoConfig.organization.logo
  }
});

export { baseSeoConfig }; 