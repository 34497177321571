import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../auth/AuthContext';
import { PlusCircle, Image, Share2, Save, AlertCircle } from 'lucide-react';
import SeoHead from '../common/SeoHead';

function AddTip() {
  const { isDarkMode } = useTheme();
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category: 'maintenance',
    imageFile: null,
    imagePreview: null,
    tags: [],
  });

  const categories = [
    { value: 'maintenance', label: 'Maintenance' },
    { value: 'efficiency', label: 'Efficiency' },
    { value: 'savings', label: 'Cost Savings' },
    { value: 'installation', label: 'Installation' },
    { value: 'loadshedding', label: 'Load Shedding' },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        imageFile: file,
        imagePreview: URL.createObjectURL(file),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add Firebase storage and Firestore logic here
    console.log('Submitting tip:', formData);
  };

  if (!isAdmin) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900' : 'bg-amber-50'} flex items-center justify-center`}>
        <div className="text-center">
          <AlertCircle className={`w-16 h-16 mx-auto mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`} />
          <h2 className={`text-2xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-amber-900'}`}>
            Admin Access Required
          </h2>
          <p className={`mb-4 ${isDarkMode ? 'text-blue-100' : 'text-amber-700'}`}>
            You need admin privileges to access this page.
          </p>
        </div>
      </div>
    );
  }

  // SEO Configuration
  const seoData = {
    title: 'Share Solar Power Tips | Community Knowledge Base',
    description: 'Share your solar power tips and experiences with our community. Help others optimize their solar systems and save on energy costs.',
    keywords: 'solar tips, community knowledge, solar power advice, energy saving tips, solar system optimization, South Africa solar community',
    path: '/social-tips/add',
    image: '/images/share-tips.jpg',
    contentType: 'form',
    categories: [
      'Installation',
      'Maintenance',
      'Efficiency',
      'Cost Saving',
      'Load Shedding'
    ],
    features: [
      'Community Contribution',
      'Expert Review',
      'Knowledge Sharing',
      'Tips Database'
    ]
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="social" data={seoData} />

      <div className="container mx-auto px-4 py-8">
        <div className={`mb-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h1 className={`text-3xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}`}>
            Create New Solar Tip
          </h1>
          <p className="text-gray-200">
            Create engaging, shareable solar power tips for our community
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Form Section */}
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-yellow-400 font-semibold mb-2">
                  Tip Title
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="Enter a catchy title"
                  required
                />
              </div>

              <div>
                <label className="block text-yellow-400 font-semibold mb-2">
                  Category
                </label>
                <select
                  value={formData.category}
                  onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                >
                  {categories.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-yellow-400 font-semibold mb-2">
                  Tip Content
                </label>
                <textarea
                  value={formData.content}
                  onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400 h-32"
                  placeholder="Write your solar tip here..."
                  required
                />
              </div>

              <div>
                <label className="block text-yellow-400 font-semibold mb-2">
                  Upload Image (1080x1080)
                </label>
                <div className="flex items-center space-x-4">
                  <label className={`
                    flex items-center px-4 py-2 rounded-lg cursor-pointer
                    ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-700 hover:bg-amber-600'}
                    text-white transition duration-200
                  `}>
                    <Image className="w-5 h-5 mr-2" />
                    Choose Image
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </label>
                  {formData.imagePreview && (
                    <span className="text-green-400">Image selected</span>
                  )}
                </div>
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => navigate('/social-tips')}
                  className={`
                    px-4 py-2 rounded-lg font-semibold
                    ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-700 hover:bg-amber-600'}
                    text-white transition duration-200
                  `}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`
                    flex items-center px-4 py-2 rounded-lg font-semibold
                    bg-yellow-400 text-blue-900 hover:bg-yellow-300
                    transition duration-200
                  `}
                >
                  <Save className="w-5 h-5 mr-2" />
                  Save Tip
                </button>
              </div>
            </form>
          </div>

          {/* Preview Section */}
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
            <h2 className="text-2xl font-bold text-yellow-400 mb-4">
              Preview
            </h2>
            <div className="bg-white rounded-lg p-4 aspect-square">
              {formData.imagePreview ? (
                <img
                  src={formData.imagePreview}
                  alt="Tip preview"
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg">
                  <div className="text-center text-gray-400">
                    <Image className="w-12 h-12 mx-auto mb-2" />
                    <p>Image preview will appear here</p>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4">
              <h3 className="text-white font-semibold">
                {formData.title || 'Your title will appear here'}
              </h3>
              <p className="text-gray-300 mt-2">
                {formData.content || 'Your content will appear here'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTip; 