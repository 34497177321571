import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import SeoHead from '../common/SeoHead';
import {
  Sun,
  TrendingUp,
  Tag,
  Zap,
  Battery,
  Users,
  Share2,
  Download,
  Check,
  ThumbsUp,
  MessageCircle,
  ChevronRight,
  Calendar,
  Eye,
  Plus,
  ChevronUp
} from 'lucide-react';

const SocialTipsList = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { category: urlCategory } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(urlCategory || 'all');
  const [email, setEmail] = useState('');
  
  // Temporary admin check - replace with proper auth later
  const isAdmin = false; // Set to false by default until auth is implemented

  useEffect(() => {
    if (urlCategory) {
      setSelectedCategory(urlCategory);
    }
  }, [urlCategory]);

  const categories = [
    { id: 'all', name: 'All Tips', icon: <Sun size={16} />, heading: 'All Solar Power Tips' },
    { id: 'efficiency', name: 'Efficiency Tips', icon: <TrendingUp size={16} />, heading: 'Solar Efficiency Tips' },
    { id: 'maintenance', name: 'Maintenance', icon: <Tag size={16} />, heading: 'Solar Maintenance Guides' },
    { id: 'loadshedding', name: 'Load Shedding', icon: <Zap size={16} />, heading: 'Load Shedding Solutions' },
    { id: 'battery', name: 'Battery Care', icon: <Battery size={16} />, heading: 'Battery Care Tips' },
    { id: 'community', name: 'Community', icon: <Users size={16} />, heading: 'Community Solar Projects' }
  ];

  const allTips = {
    efficiency: [
      {
        id: 'eff1',
        title: "Maximize Panel Efficiency",
        content: "Expert tips on optimizing your solar panel cleaning and maintenance for maximum energy generation.",
        fullContent: `
          <h1>Maximize Panel Efficiency</h1>
          <h2>Regular Cleaning</h2>
          <ul>
            <li>Use a soft brush or cloth to remove debris monthly</li>
            <li>Rinse panels carefully to wash away dust</li>
          </ul>
          <p>By cleaning your panels, you can boost overall system output by up to 15%.</p>
        `,
        imageUrl: "/images/efficiency-1.jpg",
        category: "efficiency",
        author: "Sarah Johnson",
        date: "2024-05-01",
        likes: 324,
        comments: 89
      },
      {
        id: 'eff2',
        title: "Optimize Your Panel Angle",
        content: "Learn how to adjust your panel angles seasonally for optimal performance.",
        fullContent: `
          <h1>Optimize Panel Angles</h1>
          <h2>Seasonal Adjustments</h2>
          <ul>
            <li>Increase tilt during winter to capture lower sun angles</li>
            <li>Decrease tilt in summer to avoid overheating</li>
          </ul>
          <p>Adjusting your panel angles can significantly improve annual solar yield.</p>
        `,
        imageUrl: "/images/efficiency-2.jpg",
        category: "efficiency",
        author: "Michael Brown",
        date: "2024-05-02",
        likes: 256,
        comments: 45
      }
    ],
    maintenance: [
      {
        id: 'main1',
        title: "Monthly Solar Maintenance",
        content: "Essential monthly maintenance tasks to keep your solar system running efficiently.",
        fullContent: `
          <h1>Monthly Solar Maintenance</h1>
          <h2>Check Key Components</h2>
          <ul>
            <li>Examine wiring for damage or loose connections</li>
            <li>Verify your inverter status lights daily</li>
          </ul>
          <p>Keeping an eye on essential parts can prevent bigger issues in the future.</p>
        `,
        imageUrl: "/images/maintenance-1.jpg",
        category: "maintenance",
        author: "David Smith",
        date: "2024-05-03",
        likes: 245,
        comments: 56
      },
      {
        id: 'main2',
        title: "Protect Against Storms",
        content: "Learn how to protect your solar installation during severe weather conditions.",
        fullContent: `
          <h1>Storm Protection Tips</h1>
          <h2>Preparing Your Solar Setup</h2>
          <ul>
            <li>Secure panel mounts with proper bolts</li>
            <li>Trim nearby branches to avoid damage</li>
          </ul>
          <p>Preventive measures help maintain system performance during heavy weather.</p>
        `,
        imageUrl: "/images/maintenance-2.jpg",
        category: "maintenance",
        author: "Emma Wilson",
        date: "2024-05-04",
        likes: 198,
        comments: 34
      }
    ],
    loadshedding: [
      {
        id: 'load1',
        title: "Load Shedding Survival Guide",
        content: "Essential tips for managing your solar system during load shedding periods.",
        fullContent: `
          <h1>Load Shedding Survival Guide</h1>
          <h2>Smart Usage</h2>
          <ul>
            <li>Run heavy appliances (washing machine) when power is stable</li>
            <li>Keep essential loads (lights, fridge) on a backup circuit</li>
          </ul>
          <p>Strategic planning helps keep your home functional during power cuts.</p>
        `,
        imageUrl: "/images/loadshedding-1.jpg",
        category: "loadshedding",
        author: "John Davis",
        date: "2024-05-05",
        likes: 567,
        comments: 123
      },
      {
        id: 'load2',
        title: "Inverter Backup Basics",
        content: "Master the essentials of inverter backup systems for load shedding resilience.",
        fullContent: `
          <h1>Inverter Backup Basics</h1>
          <h2>Keep Essential Devices Running</h2>
          <ul>
            <li>Choose battery capacity for at least 2 hours of backup</li>
            <li>Check battery health monthly using built-in indicators</li>
          </ul>
          <p>A well-sized inverter-battery system ensures minimal disruption during load shedding.</p>
        `,
        imageUrl: "/images/loadshedding-2.jpg",
        category: "loadshedding",
        author: "Lisa Anderson",
        date: "2024-05-06",
        likes: 432,
        comments: 87
      }
    ],
    battery: [
      {
        id: 'bat1',
        title: "Battery Care Tips",
        content: "Essential tips for maintaining and extending the life of your solar batteries.",
        fullContent: `
          <h1>Battery Care Tips</h1>
          <h2>Extend Your Battery Life</h2>
          <ul>
            <li>Avoid deep discharges below 20% state of charge</li>
            <li>Monitor cycles weekly to catch any performance drop</li>
          </ul>
          <p>Simple habits can prolong battery life by 50% or more.</p>
        `,
        imageUrl: "/images/battery-1.jpg",
        category: "battery",
        author: "Tom Wilson",
        date: "2024-05-07",
        likes: 345,
        comments: 67
      },
      {
        id: 'bat2',
        title: "Charging Best Practices",
        content: "Expert advice on optimal battery charging routines and maintenance.",
        fullContent: `
          <h1>Charging Best Practices</h1>
          <h2>Optimal Performance</h2>
          <ul>
            <li>Maintain a stable charging rate per manufacturer specs</li>
            <li>Keep batteries in a cool, ventilated area</li>
          </ul>
          <p>Proper charging routines safeguard battery capacity over the long term.</p>
        `,
        imageUrl: "/images/battery-2.jpg",
        category: "battery",
        author: "Rachel Green",
        date: "2024-05-08",
        likes: 289,
        comments: 45
      }
    ],
    community: [
      {
        id: 'com1',
        title: "Start a Community Solar Project",
        content: "Learn how to initiate and manage successful community solar projects.",
        fullContent: `
          <h1>Community Solar Projects</h1>
          <h2>Collaborative Savings</h2>
          <ul>
            <li>Pool resources to reduce individual costs</li>
            <li>Share maintenance tasks among members</li>
          </ul>
          <p>Community-driven solar can lower barriers and create shared benefits for all.</p>
        `,
        imageUrl: "/images/community-1.jpg",
        category: "community",
        author: "James Wilson",
        date: "2024-05-09",
        likes: 423,
        comments: 98
      },
      {
        id: 'com2',
        title: "Local Solar Success Stories",
        content: "Inspiring stories from South African communities embracing solar power.",
        fullContent: `
          <h1>Local Solar Success Stories</h1>
          <h2>Inspiring Real-Life Examples</h2>
          <ul>
            <li>Families cutting their electricity bills by 60%</li>
            <li>Entire neighborhoods going partially off-grid</li>
          </ul>
          <p>See how ordinary South Africans are transforming their energy usage with solar.</p>
        `,
        imageUrl: "/images/community-2.jpg",
        category: "community",
        author: "Maria Santos",
        date: "2024-05-10",
        likes: 367,
        comments: 76
      }
    ]
  };

  const getFilteredTips = () => {
    if (selectedCategory === 'all') {
      return Object.entries(allTips).flatMap(([category, tips]) => 
        tips.map(tip => ({
          ...tip,
          categoryName: categories.find(c => c.id === category)?.name || category
        }))
      );
    }
    return allTips[selectedCategory]?.map(tip => ({
      ...tip,
      categoryName: categories.find(c => c.id === selectedCategory)?.name || selectedCategory
    })) || [];
  };

  const currentCategory = categories.find(c => c.id === selectedCategory);
  const filteredTips = getFilteredTips();

  const handleSubscribe = (e) => {
    e.preventDefault();
    console.log('Subscribe:', email);
    setEmail('');
  };

  // SEO Configuration
  const seoData = {
    title: 'Solar Power Tips & Community Guides | Solar Insight',
    description: 'Discover expert solar power tips, maintenance guides, and community success stories. Learn about efficiency, battery care, load shedding solutions, and more from South Africa\'s solar community.',
    keywords: 'solar power tips, solar maintenance, solar efficiency, battery care, load shedding tips, community solar, South Africa solar guides',
    path: '/tips',
    image: '/images/solar-tips-banner.jpg',
    lastUpdated: new Date().toISOString(),
    categories: categories.map(cat => ({
      name: cat.name,
      description: cat.heading,
      tipCount: Object.values(allTips).flat().filter(tip => 
        cat.id === 'all' ? true : tip.category === cat.id
      ).length
    })),
    stats: {
      totalTips: Object.values(allTips).flat().length,
      totalLikes: Object.values(allTips).flat().reduce((acc, tip) => acc + tip.likes, 0),
      totalComments: Object.values(allTips).flat().reduce((acc, tip) => acc + tip.comments, 0)
    },
    features: [
      'Expert Tips',
      'Community Guides',
      'Interactive Comments',
      'Like & Share',
      'Category Filters',
      'Newsletter Updates'
    ],
    authors: [...new Set(Object.values(allTips).flat().map(tip => tip.author))],
    updateFrequency: 'Weekly',
    selectedCategory: selectedCategory,
    engagement: {
      commentsEnabled: true,
      sharingEnabled: true,
      newsletterSignup: true
    }
  };

  // Theme classes
  const containerBg = isDarkMode ? 'bg-blue-900' : 'bg-amber-50';
  const cardBg = isDarkMode ? 'bg-blue-800' : 'bg-white';
  const textColor = isDarkMode ? 'text-blue-100' : 'text-amber-900';
  const headerColor = isDarkMode ? 'text-yellow-400' : 'text-amber-800';
  const borderColor = isDarkMode ? 'border-blue-700' : 'border-amber-200';
  const hoverEffect = isDarkMode 
    ? 'hover:bg-blue-700 hover:border-blue-600' 
    : 'hover:bg-amber-100 hover:border-amber-300';

  return (
    <div className={`min-h-screen ${containerBg} ${textColor}`}>
      <SeoHead template="tips" data={seoData} />

      <main className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <div className={`text-center py-16 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'} rounded-lg shadow-xl mb-12`}>
          <h1 className={`
            text-4xl md:text-6xl font-bold mb-6
            ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}
            drop-shadow-[0_0_10px_rgba(250,204,21,0.7)]
          `}>
            {currentCategory?.heading || 'Solar Power Tips & Insights'}
          </h1>
          <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
            Expert advice and practical solutions for South African solar power users
          </p>
        </div>

        {/* Main Content Area */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Sidebar - Categories */}
          <aside className="lg:w-64 flex-shrink-0">
            <div className={`sticky top-4 p-4 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
              <h2 className={`text-xl font-bold mb-4 ${headerColor}`}>Categories</h2>
              <nav className="flex flex-col gap-2">
                {categories.map((cat) => (
                  <Link
                    key={cat.id}
                    to={`/social-tips/${cat.id}`}
                    className={`
                      flex items-center px-4 py-2 rounded-lg text-sm font-medium
                      ${selectedCategory === cat.id
                        ? isDarkMode 
                          ? 'bg-yellow-400 text-blue-900'
                          : 'bg-yellow-400 text-amber-900'
                        : isDarkMode
                          ? 'bg-blue-700 text-white hover:bg-blue-600'
                          : 'bg-amber-700 text-white hover:bg-amber-600'
                      }
                      transition duration-200
                    `}
                  >
                    {cat.icon}
                    <span className="ml-2">{cat.name}</span>
                  </Link>
                ))}
              </nav>
            </div>
          </aside>

          {/* Middle Section - Tips Grid */}
          <div className="flex-grow max-w-4xl mx-auto">
            <div className={`p-6 rounded-lg shadow-xl mb-8 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
              <h2 className={`text-3xl font-bold text-center ${headerColor}`}>
                {currentCategory?.heading || 'Latest Solar Tips'}
              </h2>
              <p className="text-center text-gray-200 mt-2">
                Click any tip to view full details, share, or download
              </p>
            </div>
            
            {/* Tips Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {filteredTips.map((tip) => (
                <article
                  key={tip.id}
                  className={`${cardBg} rounded-lg overflow-hidden shadow-lg
                    transition-all duration-300 ${hoverEffect} cursor-pointer
                    group relative flex flex-col h-full`}
                  onClick={() => navigate(`/social-tips/${tip.category}/${tip.id}`)}
                >
                  {/* Square Image Container */}
                  <div className="aspect-square relative overflow-hidden">
                    <img
                      src={tip.imageUrl || '/images/default-tip.jpg'}
                      alt={tip.title}
                      className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                      width={1080}
                      height={1080}
                      loading="lazy"
                    />
                    {/* Category Badge */}
                    <div className={`absolute top-4 right-4 px-3 py-1 rounded-full text-xs font-medium
                      ${isDarkMode ? 'bg-blue-900/90 text-blue-100' : 'bg-amber-100/90 text-amber-900'}`}>
                      {tip.categoryName}
                    </div>
                    {/* Date Badge */}
                    <div className={`absolute top-4 left-4 flex items-center space-x-2
                      ${isDarkMode ? 'text-blue-100' : 'text-amber-100'}`}>
                      <div className="flex items-center bg-black/50 rounded-full px-3 py-1">
                        <Calendar size={12} className="mr-1" />
                        <span className="text-xs">
                          {new Date(tip.date).toLocaleDateString('en-ZA', { 
                            day: 'numeric',
                            month: 'short'
                          })}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Content Section */}
                  <div className="p-6 flex flex-col flex-grow">
                    <h3 className={`text-xl font-bold mb-3 ${headerColor}`}>{tip.title}</h3>
                    <p className={`text-sm mb-4 flex-grow ${textColor}`}>
                      {tip.content}
                    </p>
                    <div className="flex items-center justify-between mt-auto pt-4 border-t border-opacity-10 border-current">
                      <div className="flex items-center space-x-4">
                        <span className="flex items-center">
                          <ThumbsUp size={14} className="mr-1" />
                          {tip.likes}
                        </span>
                        <span className="flex items-center">
                          <MessageCircle size={14} className="mr-1" />
                          {tip.comments}
                        </span>
                      </div>
                      <span className={`flex items-center font-medium
                        ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                        View Tip
                        <ChevronRight size={16} className="ml-1" />
                      </span>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>

          {/* Right Sidebar - Newsletter */}
          <aside className="lg:w-80 flex-shrink-0">
            <div className={`sticky top-4 p-6 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
              <h2 className="text-2xl font-bold text-white mb-4">
                Want More Solar Tips?
              </h2>
              <p className="text-gray-200 mb-6">
                Join our community of South African solar enthusiasts and get exclusive tips
              </p>
              <form onSubmit={handleSubscribe} className="space-y-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 rounded-lg
                    focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  required
                />
                <button
                  type="submit"
                  className={`w-full px-6 py-2 rounded-lg font-semibold
                    ${isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-yellow-400 text-amber-900'}
                    hover:bg-yellow-300 transition duration-200
                  `}
                >
                  Subscribe Now
                </button>
              </form>
            </div>
          </aside>
        </div>

        {/* Quick Actions Floating Button - Only show for admins */}
        {isAdmin && (
          <div className="fixed bottom-6 right-6 flex flex-col space-y-2">
            <Link
              to="/social-tips/add"
              className={`p-4 rounded-full shadow-lg
                ${isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-yellow-400 text-amber-900'}
                hover:bg-yellow-300 transition-colors duration-200
                transform hover:scale-105`}
              title="Add New Tip"
            >
              <Plus size={24} />
            </Link>
            <button
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className={`p-4 rounded-full shadow-lg
                ${isDarkMode ? 'bg-blue-700 text-white' : 'bg-amber-700 text-white'}
                hover:bg-opacity-90 transition-colors duration-200
                transform hover:scale-105`}
              title="Back to Top"
            >
              <ChevronUp size={24} />
            </button>
          </div>
        )}
      </main>
    </div>
  );
};

export default SocialTipsList; 