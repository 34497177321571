import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, Brush, Check } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import SeoHead from '../common/SeoHead';

const SolarCleaningPage = () => {
  const { isDarkMode } = useTheme();

  const faqData = [
    {
      question: "How often should solar panels be cleaned in South Africa?",
      answer: "Due to varying climate conditions across regions like Gauteng, Western Cape, and KwaZulu-Natal, we recommend cleaning every 6-12 months. However, this may need to be more frequent in dusty areas or during dry seasons."
    },
    {
      question: "What cleaning methods do you use?",
      answer: "We use eco-friendly, specialized cleaning solutions and soft-bristled equipment designed specifically for solar panels. Our methods ensure thorough cleaning without risking damage to the panels or their coating."
    },
    {
      question: "Do you offer emergency cleaning services?",
      answer: "Yes, we provide emergency cleaning services across major cities including Johannesburg, Cape Town, and Durban. Contact us for immediate assistance if you notice significant power output reduction."
    },
    {
      question: "How long does the cleaning process take?",
      answer: "The duration varies based on system size and condition. A typical residential installation (10-20 panels) usually takes 2-3 hours to clean thoroughly."
    }
  ];

  const [showFaq, setShowFaq] = useState(Array(faqData.length).fill(false));
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    selectedPackage: '',
    message: '',
    numPanels: '',
  });
  const [formStatus, setFormStatus] = useState({
    isSubmitting: false,
    isSubmitted: false,
    error: null
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus({ isSubmitting: true, isSubmitted: false, error: null });

    try {
      // Here you would typically make an API call to your backend
      // For now, we'll simulate an API call with a timeout
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Reset form and show success
      setFormData({
        name: '',
        email: '',
        phone: '',
        selectedPackage: '',
        message: '',
        numPanels: '',
      });
      setFormStatus({
        isSubmitting: false,
        isSubmitted: true,
        error: null
      });
    } catch (error) {
      setFormStatus({
        isSubmitting: false,
        isSubmitted: false,
        error: 'There was an error submitting your request. Please try again.'
      });
    }
  };

  const toggleFaq = (index) => {
    setShowFaq(prev => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // Structured data for SEO
  const cleaningServiceData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Solar Panel Cleaning Service",
    "provider": {
      "@type": "LocalBusiness",
      "name": "Solar Insight"
    },
    "areaServed": [
      "Cape Town",
      "Johannesburg",
      "Durban",
      "Pretoria"
    ],
    "description": "Professional solar panel cleaning service using eco-friendly methods to maintain optimal system efficiency.",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Solar Panel Cleaning Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Basic Panel Cleaning",
            "price": "R650",
            "priceCurrency": "ZAR"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Standard Panel Cleaning",
            "price": "R1200",
            "priceCurrency": "ZAR"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Premium Panel Cleaning",
            "price": "R1800",
            "priceCurrency": "ZAR"
          }
        }
      ]
    }
  };

  // Cleaning packages data
  const cleaningPackages = [
    {
      id: 'basic',
      name: 'Basic Package',
      price: 'R650 - R850',
      description: [
        'Up to 10 panels',
        'Eco-friendly cleaning solution',
        'Basic visual inspection',
        'Standard cleaning process',
        'Same-day service report'
      ],
      highlights: 'Perfect for small residential installations or routine maintenance.',
      recommended: false
    },
    {
      id: 'standard',
      name: 'Standard Package',
      price: 'R1200 - R1500',
      description: [
        'Up to 20 panels',
        'Premium cleaning solution',
        'Detailed inspection',
        'Performance measurement',
        'Bird protection assessment',
        'Comprehensive report'
      ],
      highlights: 'Most popular choice for typical residential systems.',
      recommended: true
    },
    {
      id: 'premium',
      name: 'Premium Package',
      price: 'Starting at R1800',
      description: [
        '20+ panels',
        'Advanced cleaning technology',
        'Full system inspection',
        'Performance optimization',
        'Preventive maintenance check',
        'Priority scheduling',
        'Detailed analysis report'
      ],
      highlights: 'Ideal for large residential or commercial installations.',
      recommended: false
    }
  ];

  // SEO Configuration
  const seoData = {
    title: 'Professional Solar Panel Cleaning Services',
    description: 'Expert solar panel cleaning services in South Africa. Improve system efficiency, extend panel life, and maximize energy production with our professional cleaning solutions.',
    keywords: 'solar panel cleaning, professional cleaning, panel maintenance, efficiency optimization, solar cleaning service, South Africa solar cleaning',
    path: '/services/cleaning',
    image: '/images/solar-cleaning.jpg',
    features: [
      'Professional Equipment',
      'Eco-friendly Cleaning',
      'Safety Certified',
      'Performance Reports',
      'Scheduled Maintenance'
    ],
    benefits: [
      'Increased Efficiency',
      'Extended Panel Life',
      'Better ROI',
      'Regular Monitoring'
    ],
    pricing: {
      currency: 'ZAR',
      startingFrom: 500,
      pricePerPanel: 100
    },
    serviceAreas: [
      'Cape Town',
      'Johannesburg',
      'Durban',
      'Pretoria'
    ]
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="service" data={seoData} />

      <main className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <section className={`text-center py-16 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'} rounded-lg shadow-xl mb-12`}>
          <div className="flex justify-center mb-6">
            <Brush className="w-16 h-16 text-yellow-400" />
          </div>
          <h1 className={`
            text-4xl md:text-6xl font-bold mb-6
            ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}
            drop-shadow-[0_0_10px_rgba(250,204,21,0.7)]
          `}>
            Solar Panel Cleaning
          </h1>
          <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
            Professional cleaning services to maintain optimal solar panel efficiency
          </p>
        </section>

        {/* Benefits Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Why Choose Our Cleaning Service?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Improved Efficiency",
                description: "Clean panels can improve energy production by up to 30%"
              },
              {
                title: "Professional Equipment",
                description: "Specialized tools and eco-friendly cleaning solutions"
              },
              {
                title: "Experienced Team",
                description: "Certified technicians with extensive cleaning experience"
              },
              {
                title: "Safety First",
                description: "Adherence to strict safety protocols and standards"
              },
              {
                title: "Comprehensive Service",
                description: "Detailed inspection and cleaning report provided"
              },
              {
                title: "Warranty Compliant",
                description: "Methods approved by major panel manufacturers"
              }
            ].map((benefit, index) => (
              <div key={index} className="p-4 bg-opacity-20 bg-white rounded-lg">
                <h3 className="text-lg font-semibold mb-2 text-yellow-400">{benefit.title}</h3>
                <p className="text-gray-300">{benefit.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Process Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Our Cleaning Process</h2>
          <div className="space-y-4">
            {[
              "Initial inspection of panel condition and surrounding area",
              "Application of specialized cleaning solution",
              "Gentle cleaning using soft-bristled equipment",
              "Thorough rinsing with purified water",
              "Final inspection and performance check",
              "Detailed cleaning report and recommendations"
            ].map((step, index) => (
              <div key={index} className="flex items-center space-x-3">
                <Check className="w-6 h-6 text-yellow-400 flex-shrink-0" />
                <p className="text-gray-300">{step}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Packages Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-center text-white">Choose Your Cleaning Package</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {cleaningPackages.map(pkg => (
              <div 
                key={pkg.id} 
                className={`
                  relative bg-opacity-20 bg-white p-6 rounded-lg flex flex-col
                  ${pkg.recommended ? 'border-2 border-yellow-400' : ''}
                  transform hover:scale-105 transition-transform duration-300
                `}
              >
                {pkg.recommended && (
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-yellow-400 text-blue-900 px-4 py-1 rounded-full text-sm font-bold">
                    Most Popular
                  </div>
                )}
                <h3 className="text-xl font-semibold mb-2 text-yellow-400">{pkg.name}</h3>
                <p className="text-2xl font-bold text-white mb-4">{pkg.price}</p>
                <ul className="text-gray-300 mb-6 flex-1 space-y-2">
                  {pkg.description.map((desc, idx) => (
                    <li key={idx} className="flex items-start space-x-2">
                      <Check className="w-5 h-5 text-yellow-400 flex-shrink-0 mt-0.5" />
                      <span>{desc}</span>
                    </li>
                  ))}
                </ul>
                <p className="text-sm text-gray-400 italic mb-6">{pkg.highlights}</p>
                <Link
                  to={`/contact?package=${pkg.id}`}
                  className={`
                    w-full text-center px-6 py-3 rounded-lg font-semibold text-lg
                    ${pkg.recommended 
                      ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
                      : 'border-2 border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-blue-900'
                    }
                    transition-colors duration-300
                  `}
                >
                  Select Package
                </Link>
              </div>
            ))}
          </div>
          <p className="text-center text-gray-300 mt-6">
            All packages include our eco-friendly cleaning solutions and professional service.
            <br />
            Not sure which package to choose? Contact us for a personalized recommendation.
          </p>
        </section>

        {/* Lead Generation Form Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-center text-white">Get Your Free Quote Today</h2>
          <div className="max-w-2xl mx-auto">
            {formStatus.isSubmitted ? (
              <div className="bg-green-500 text-white p-6 rounded-lg text-center">
                <h3 className="text-xl font-bold mb-2">Thank You!</h3>
                <p>We've received your request and will contact you shortly to discuss your solar panel cleaning needs.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-gray-300 mb-2" htmlFor="name">
                      Full Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 rounded-lg bg-white bg-opacity-20 border border-yellow-400 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="Enter your full name"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2" htmlFor="email">
                      Email Address *
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 rounded-lg bg-white bg-opacity-20 border border-yellow-400 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2" htmlFor="phone">
                      Phone Number *
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 rounded-lg bg-white bg-opacity-20 border border-yellow-400 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="Enter your phone number"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2" htmlFor="numPanels">
                      Number of Panels
                    </label>
                    <input
                      type="number"
                      id="numPanels"
                      name="numPanels"
                      value={formData.numPanels}
                      onChange={handleInputChange}
                      className="w-full p-3 rounded-lg bg-white bg-opacity-20 border border-yellow-400 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="How many panels do you have?"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-gray-300 mb-2" htmlFor="selectedPackage">
                    Preferred Package
                  </label>
                  <select
                    id="selectedPackage"
                    name="selectedPackage"
                    value={formData.selectedPackage}
                    onChange={handleInputChange}
                    className={`w-full p-3 rounded-lg text-white border focus:outline-none focus:ring-2 
                      ${isDarkMode 
                        ? 'bg-blue-700 border-blue-600 focus:ring-blue-500' 
                        : 'bg-amber-800 border-amber-600 focus:ring-amber-500'
                      }`}
                  >
                    <option value="" className={isDarkMode ? 'bg-blue-700' : 'bg-amber-800'}>Select a package</option>
                    <option value="basic" className={isDarkMode ? 'bg-blue-700' : 'bg-amber-800'}>Basic Package</option>
                    <option value="standard" className={isDarkMode ? 'bg-blue-700' : 'bg-amber-800'}>Standard Package</option>
                    <option value="premium" className={isDarkMode ? 'bg-blue-700' : 'bg-amber-800'}>Premium Package</option>
                  </select>
                </div>
                <div>
                  <label className="block text-gray-300 mb-2" htmlFor="message">
                    Additional Information
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows="4"
                    className="w-full p-3 rounded-lg bg-white bg-opacity-20 border border-yellow-400 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    placeholder="Tell us about your specific needs or any questions you have..."
                  ></textarea>
                </div>
                {formStatus.error && (
                  <div className="bg-red-500 text-white p-3 rounded-lg">
                    {formStatus.error}
                  </div>
                )}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    disabled={formStatus.isSubmitting}
                    className={`
                      px-8 py-3 rounded-lg font-semibold text-lg
                      ${formStatus.isSubmitting 
                        ? 'bg-gray-500 cursor-not-allowed' 
                        : 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'}
                      transition-colors duration-300
                      min-w-[200px]
                    `}
                  >
                    {formStatus.isSubmitting ? 'Submitting...' : 'Get Free Quote'}
                  </button>
                </div>
              </form>
            )}
            <p className="text-sm text-gray-400 text-center mt-4">
              By submitting this form, you agree to our privacy policy and terms of service.
              We'll never share your information with third parties.
            </p>
          </div>
        </section>

        {/* FAQ Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <div key={index} className="border-b border-gray-700">
                <button
                  className="w-full text-left py-4 flex justify-between items-center"
                  onClick={() => toggleFaq(index)}
                >
                  <span className="text-lg font-medium text-white">{faq.question}</span>
                  {showFaq[index] ? (
                    <ChevronUp className="w-6 h-6 text-yellow-400" />
                  ) : (
                    <ChevronDown className="w-6 h-6 text-yellow-400" />
                  )}
                </button>
                {showFaq[index] && (
                  <p className="pb-4 text-gray-300">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </section>

        {/* CTA Section */}
        <section className={`text-center p-8 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-4 text-white">Ready to Schedule a Cleaning?</h2>
          <p className="text-gray-300 mb-6">
            Contact us today to book a professional cleaning service or learn more about our maintenance packages
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/contact"
              className={`
                inline-block px-8 py-3 rounded-lg font-semibold text-lg
                ${isDarkMode ? 'bg-yellow-400 text-gray-900' : 'bg-yellow-400 text-gray-900'}
                hover:bg-yellow-300 transition duration-300
                transform hover:scale-105
              `}
            >
              Book Now
            </Link>
            <Link
              to="/services"
              className={`
                inline-block px-8 py-3 rounded-lg font-semibold text-lg
                border-2 border-yellow-400 text-yellow-400
                hover:bg-yellow-400 hover:text-gray-900
                transition duration-300
                transform hover:scale-105
              `}
            >
              View All Services
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SolarCleaningPage; 