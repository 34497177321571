import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { ThumbsUp, MessageCircle, User, Clock, ChevronLeft, Share2 } from 'lucide-react';
import SeoHead from '../common/SeoHead';

const SocialTipDetail = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { category, tipId } = useParams();

  // Theme classes
  const containerBg = isDarkMode ? 'bg-blue-900' : 'bg-amber-50';
  const cardBg = isDarkMode ? 'bg-blue-800' : 'bg-white';
  const textColor = isDarkMode ? 'text-blue-100' : 'text-amber-900';
  const headerColor = isDarkMode ? 'text-yellow-400' : 'text-amber-800';
  const borderColor = isDarkMode ? 'border-blue-700' : 'border-amber-200';
  const hoverEffect = isDarkMode 
    ? 'hover:bg-blue-700 hover:border-blue-600' 
    : 'hover:bg-amber-100 hover:border-amber-300';

  // This would typically come from an API or database
  // For now, we'll use the same data structure as in SocialTipsList
  const allTips = {
    installation: [
      {
        id: 'inst1',
        title: "Professional Solar Panel Installation Guide",
        content: "Step-by-step guide for proper solar panel installation including roof preparation, mounting, and wiring.",
        imageUrl: "/images/installation-1.jpg",
        category: "installation",
        author: "David Smith",
        date: "2024-03-15",
        likes: 245,
        comments: 56,
        fullContent: `
          <h2>Essential Steps for Professional Solar Panel Installation</h2>
          
          <h3>1. Site Assessment</h3>
          <p>Before beginning installation, a thorough site assessment is crucial. This includes:</p>
          <ul>
            <li>Roof structural analysis</li>
            <li>Solar exposure evaluation</li>
            <li>Electrical system inspection</li>
          </ul>

          <h3>2. Planning and Design</h3>
          <p>Create a detailed installation plan considering:</p>
          <ul>
            <li>Panel layout and orientation</li>
            <li>Mounting system selection</li>
            <li>Wiring routes and connections</li>
          </ul>

          <h3>3. Safety Considerations</h3>
          <p>Ensure all safety measures are in place:</p>
          <ul>
            <li>Use appropriate PPE</li>
            <li>Follow electrical safety guidelines</li>
            <li>Implement fall protection measures</li>
          </ul>
        `
      },
      // ... other installation tips
    ],
    // ... other categories
  };

  const tip = Object.values(allTips)
    .flat()
    .find(t => t.id === tipId);

  if (!tip) {
    return (
      <div className={`min-h-screen p-4 ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
        <div className="container mx-auto text-center py-16">
          <Share2 className="mx-auto h-16 w-16 mb-4 opacity-50" />
          <h1 className="text-2xl font-bold mb-4">Tip Not Found</h1>
          <p className="mb-8">The solar tip you're looking for doesn't exist or has been moved.</p>
          <Link
            to="/social-tips"
            className={`inline-flex items-center px-4 py-2 rounded-lg
              ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-600 hover:bg-amber-500'}
              text-white transition duration-200`}
          >
            <ChevronLeft className="mr-2" size={16} />
            Back to Tips
          </Link>
        </div>
      </div>
    );
  }

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": tip.title,
    "description": tip.content,
    "author": {
      "@type": "Person",
      "name": tip.author
    },
    "datePublished": tip.date,
    "image": tip.imageUrl,
    "publisher": {
      "@type": "Organization",
      "name": "Solar Insight SA",
      "logo": {
        "@type": "ImageObject",
        "url": "https://solarinsight.co.za/logo.png"
      }
    }
  };

  const seoData = {
    title: tip ? `${tip.title} | Solar Power Tips` : 'Solar Power Tip | Community Knowledge',
    description: tip ? tip.content : 'Detailed solar power tip from our community knowledge base. Learn from experienced solar users.',
    keywords: `solar tips, ${tip?.category || 'solar power'}, ${tip?.tags?.join(', ') || 'energy saving'}, South Africa solar community`,
    path: `/social-tips/${tip?.id || ''}`,
    image: tip?.image || '/images/default-tip.jpg',
    contentType: 'article',
    author: tip?.author || 'Solar Community',
    publishDate: tip?.createdAt || new Date().toISOString(),
    category: tip?.category || 'Solar Tips',
    engagement: {
      likes: tip?.likes || 0,
      comments: tip?.comments?.length || 0,
      shares: tip?.shares || 0
    },
    expertReviewed: tip?.expertReviewed || false
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="social" data={seoData} />

      <main className="container mx-auto px-4 py-8 space-y-8">
        {/* Header Section */}
        <header className={`
          rounded-lg shadow-xl overflow-hidden
          ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}
        `}>
          {/* Navigation */}
          <div className="px-6 py-4 border-b border-opacity-20
            ${isDarkMode ? 'border-blue-700' : 'border-amber-700'}">
            <Link
              to="/social-tips"
              className={`inline-flex items-center px-4 py-2 rounded-lg
                ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-700 hover:bg-amber-600'}
                text-white transition duration-200`}
            >
              <ChevronLeft className="mr-2" size={16} />
              Back to Tips
            </Link>
          </div>

          {/* Hero Content */}
          <div className="relative">
            <div className="absolute inset-0">
              <img
                src={tip.imageUrl || '/images/default-tip.jpg'}
                alt={tip.title}
                className="w-full h-full object-cover opacity-30"
              />
            </div>
            <div className="relative px-6 py-16 text-center">
              <h1 className={`
                text-4xl md:text-6xl font-bold mb-6
                ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}
                drop-shadow-[0_0_10px_rgba(250,204,21,0.7)]
              `}>
                {tip.title}
              </h1>
              <div className="flex flex-wrap items-center justify-center gap-6 text-white">
                <span className="flex items-center">
                  <User size={16} className="mr-2" />
                  {tip.author}
                </span>
                <span className="flex items-center">
                  <Clock size={16} className="mr-2" />
                  {new Date(tip.date).toLocaleDateString()}
                </span>
                <span className={`px-3 py-1 rounded-full text-sm
                  ${isDarkMode ? 'bg-blue-700' : 'bg-amber-700'}`}>
                  {category}
                </span>
              </div>
            </div>
          </div>
        </header>

        {/* Content Section */}
        <section className={`
          rounded-lg shadow-xl overflow-hidden
          ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}
        `}>
          <div className="p-8">
            <div className="prose prose-lg max-w-none
              prose-headings:text-yellow-400
              prose-p:text-gray-200
              prose-strong:text-white
              prose-ul:text-gray-200
              prose-li:text-gray-200"
              dangerouslySetInnerHTML={{ __html: tip.fullContent }}
            />
          </div>
        </section>

        {/* Engagement Section */}
        <section className={`
          rounded-lg shadow-xl overflow-hidden
          ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}
        `}>
          <div className="p-6">
            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="flex items-center space-x-6 text-gray-200">
                <span className="flex items-center">
                  <ThumbsUp size={20} className="mr-2" />
                  {tip.likes} likes
                </span>
                <span className="flex items-center">
                  <MessageCircle size={20} className="mr-2" />
                  {tip.comments} comments
                </span>
              </div>
              <div className="flex items-center gap-4">
                <button
                  className={`
                    px-6 py-2 rounded-lg font-semibold
                    ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-700 hover:bg-amber-600'}
                    text-white transition duration-200
                  `}
                >
                  Add Comment
                </button>
                <button
                  className={`
                    px-6 py-2 rounded-lg font-semibold
                    ${isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-yellow-400 text-amber-900'}
                    hover:bg-yellow-300 transition duration-200
                  `}
                >
                  Share Tip
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Related Tips */}
        <section className={`
          rounded-lg shadow-xl overflow-hidden
          ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}
        `}>
          <div className="p-6">
            <h2 className={`text-2xl font-bold mb-6 ${headerColor}`}>
              Related Tips
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {Object.values(allTips[category] || {})
                .filter(relatedTip => relatedTip.id !== tipId)
                .map(relatedTip => (
                  <article
                    key={relatedTip.id}
                    className={`${cardBg} rounded-lg overflow-hidden shadow-lg
                      transition-all duration-300 ${hoverEffect} cursor-pointer`}
                    onClick={() => navigate(`/social-tips/${category}/${relatedTip.id}`)}
                  >
                    <div className="flex items-center p-4">
                      <div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0">
                        <img
                          src={relatedTip.imageUrl || '/images/default-tip.jpg'}
                          alt={relatedTip.title}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="ml-4 flex-grow">
                        <h3 className={`font-bold mb-1 ${headerColor}`}>
                          {relatedTip.title}
                        </h3>
                        <p className={`text-sm ${textColor} line-clamp-2`}>
                          {relatedTip.content}
                        </p>
                      </div>
                    </div>
                  </article>
                ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SocialTipDetail; 