import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, Wrench, CheckCircle } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import SeoHead from '../common/SeoHead';

const SolarRepairPage = () => {
  const { isDarkMode } = useTheme();

  const faqData = [
    {
      question: "What types of solar repairs do you offer?",
      answer: "We handle all aspects of solar system repairs including panel replacement, inverter repairs, wiring issues, mounting system fixes, and monitoring system troubleshooting."
    },
    {
      question: "How quickly can you respond to repair requests?",
      answer: "We offer emergency repair services with same-day response in major cities like Johannesburg, Cape Town, and Durban. Standard repair requests are typically scheduled within 24-48 hours."
    },
    {
      question: "Do you provide warranty repairs?",
      answer: "Yes, we are authorized to perform warranty repairs for major solar equipment manufacturers. We handle all warranty claim documentation and processing on your behalf."
    },
    {
      question: "What diagnostic tools do you use?",
      answer: "We use advanced thermal imaging, multimeters, and specialized solar diagnostic equipment to accurately identify issues and ensure proper repairs."
    }
  ];

  const [showFaq, setShowFaq] = useState(Array(faqData.length).fill(false));

  const repairSteps = [
    {
      title: "Initial Assessment",
      description: "Thorough system inspection and problem diagnosis using advanced diagnostic tools"
    },
    {
      title: "Detailed Report",
      description: "Complete breakdown of issues found and recommended repairs with cost estimates"
    },
    {
      title: "Professional Repair",
      description: "Expert repairs using quality replacement parts and industry-best practices"
    },
    {
      title: "Testing & Verification",
      description: "Comprehensive testing to ensure optimal system performance after repairs"
    },
    {
      title: "Documentation",
      description: "Detailed repair documentation and updated system maintenance records"
    }
  ];

  const toggleFaq = (index) => {
    setShowFaq(prev => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // SEO Configuration
  const seoData = {
    title: 'Solar System Repair Services',
    description: 'Expert solar repair services in South Africa. 24/7 emergency repairs, system diagnostics, and professional maintenance for all solar components. Fast response times and qualified technicians.',
    keywords: 'solar repair, system maintenance, emergency solar service, panel repair, inverter repair, battery repair, South Africa solar repair',
    path: '/services/repair',
    image: '/images/solar-repair.jpg',
    services: [
      'Emergency Repairs',
      'System Diagnostics',
      'Component Replacement',
      'Performance Optimization',
      'Warranty Repairs'
    ],
    expertise: [
      'Solar Panels',
      'Inverters',
      'Batteries',
      'Mounting Systems',
      'Electrical Systems'
    ],
    response: {
      emergency: '2 hours',
      standard: '24 hours',
      availability: '24/7'
    },
    coverage: [
      'Cape Town',
      'Johannesburg',
      'Durban',
      'Pretoria'
    ]
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="service" data={seoData} />

      <main className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <section className={`text-center py-16 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'} rounded-lg shadow-xl mb-12`}>
          <div className="flex justify-center mb-6">
            <Wrench className="w-16 h-16 text-yellow-400" />
          </div>
          <h1 className={`
            text-4xl md:text-6xl font-bold mb-6
            ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}
            drop-shadow-[0_0_10px_rgba(250,204,21,0.7)]
          `}>
            Solar System Repair
          </h1>
          <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
            Expert repair services for all solar system components
          </p>
        </section>

        {/* Services Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Our Repair Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Panel Repairs",
                description: "Fixing or replacing damaged solar panels"
              },
              {
                title: "Inverter Service",
                description: "Troubleshooting and repairing inverter issues"
              },
              {
                title: "Wiring Repairs",
                description: "Fixing electrical connections and wiring problems"
              },
              {
                title: "Mounting Fixes",
                description: "Repairing or replacing mounting hardware"
              },
              {
                title: "System Diagnostics",
                description: "Advanced testing and problem identification"
              },
              {
                title: "Emergency Repairs",
                description: "Quick response for urgent system issues"
              }
            ].map((service, index) => (
              <div key={index} className="p-4 bg-opacity-20 bg-white rounded-lg">
                <h3 className="text-lg font-semibold mb-2 text-yellow-400">{service.title}</h3>
                <p className="text-gray-300">{service.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Repair Process */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Our Repair Process</h2>
          <div className="space-y-6">
            {repairSteps.map((step, index) => (
              <div key={index} className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <CheckCircle className="w-8 h-8 text-yellow-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white">{step.title}</h3>
                  <p className="text-gray-300">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* FAQ Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <div key={index} className="border-b border-gray-700">
                <button
                  className="w-full text-left py-4 flex justify-between items-center"
                  onClick={() => toggleFaq(index)}
                >
                  <span className="text-lg font-medium text-white">{faq.question}</span>
                  {showFaq[index] ? (
                    <ChevronUp className="w-6 h-6 text-yellow-400" />
                  ) : (
                    <ChevronDown className="w-6 h-6 text-yellow-400" />
                  )}
                </button>
                {showFaq[index] && (
                  <p className="pb-4 text-gray-300">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </section>

        {/* CTA Section */}
        <section className={`text-center p-8 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-4 text-white">Need Solar System Repairs?</h2>
          <p className="text-gray-300 mb-6">
            Contact us now for quick, professional repair services
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/contact"
              className={`
                inline-block px-8 py-3 rounded-lg font-semibold text-lg
                ${isDarkMode ? 'bg-yellow-400 text-gray-900' : 'bg-yellow-400 text-gray-900'}
                hover:bg-yellow-300 transition duration-300
                transform hover:scale-105
              `}
            >
              Request Repair
            </Link>
            <Link
              to="/services"
              className={`
                inline-block px-8 py-3 rounded-lg font-semibold text-lg
                border-2 border-yellow-400 text-yellow-400
                hover:bg-yellow-400 hover:text-gray-900
                transition duration-300
                transform hover:scale-105
              `}
            >
              View All Services
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SolarRepairPage; 