import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import SeoHead from '../common/SeoHead';
import {
  Calculator,
  Sun,
  HelpCircle,
  Info,
  ArrowRight,
  Brush,
  DollarSign,
  TrendingUp,
  Leaf,
} from 'lucide-react';

const CleaningCalculator = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    numberOfPanels: 10,
    panelWattage: 400,
    dirtLevel: 'moderate',
    cleaningFrequency: 6,
    electricityRate: 2.5,
  });

  const [results, setResults] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: name === 'dirtLevel' ? value : parseFloat(value)
    }));
  };

  useEffect(() => {
    calculateResults();
  }, [inputs]);

  const calculateResults = () => {
    const { numberOfPanels, panelWattage, dirtLevel, cleaningFrequency, electricityRate } = inputs;

    // Efficiency loss percentages based on dirt level
    const efficiencyLoss = {
      light: 0.05, // 5% loss
      moderate: 0.10, // 10% loss
      heavy: 0.15, // 15% loss
    };

    // Calculate baseline annual production (kWh) assuming 5.5 peak sun hours per day
    const dailyProduction = (numberOfPanels * panelWattage * 5.5) / 1000; // in kWh
    const annualProduction = dailyProduction * 365;

    // Calculate production loss due to dirt
    const lossPercentage = efficiencyLoss[dirtLevel];
    const annualLoss = annualProduction * lossPercentage;

    // Calculate financial impact
    const annualCostLoss = annualLoss * electricityRate;
    const monthlyLoss = annualLoss / 12;
    const monthlyCostLoss = annualCostLoss / 12;

    // Calculate cleaning impact
    const cleaningsPerYear = 12 / cleaningFrequency;
    const averageEfficiencyGain = lossPercentage / 2; // Assumes linear dirt accumulation
    const annualSavings = annualProduction * averageEfficiencyGain * electricityRate;
    
    // Environmental impact (0.95 kg CO2 per kWh in South Africa)
    const carbonReduction = annualLoss * 0.95;

    setResults({
      efficiencyLoss: (lossPercentage * 100).toFixed(1),
      annualLoss: annualLoss.toFixed(2),
      annualCostLoss: annualCostLoss.toFixed(2),
      monthlyLoss: monthlyLoss.toFixed(2),
      monthlyCostLoss: monthlyCostLoss.toFixed(2),
      cleaningsPerYear,
      annualSavings: annualSavings.toFixed(2),
      carbonReduction: carbonReduction.toFixed(2),
    });
  };

  // Styling
  const bgColor = isDarkMode ? 'bg-blue-900' : 'bg-amber-50';
  const textColor = isDarkMode ? 'text-amber-100' : 'text-amber-900';
  const inputBgColor = isDarkMode ? 'bg-blue-800' : 'bg-white';
  const inputBorderColor = isDarkMode ? 'border-blue-700' : 'border-amber-300';
  const iconHoverColor = isDarkMode ? 'hover:text-amber-300' : 'hover:text-amber-600';
  const resultsBgColor = isDarkMode ? 'bg-blue-800' : 'bg-amber-100';
  const headerColor = isDarkMode ? 'text-yellow-400' : 'text-amber-800';

  const commonQuestions = [
    {
      q: "How does dirt affect solar panel efficiency?",
      a: "Dirt, dust, and debris can reduce solar panel efficiency by blocking sunlight from reaching the photovoltaic cells. Even a thin layer of dirt can reduce efficiency by 5-10%, while heavy soiling can cause losses of 15% or more."
    },
    {
      q: "How often should I clean my solar panels?",
      a: "The optimal cleaning frequency depends on your location and environment. In dusty areas or during dry seasons, cleaning every 3-4 months is recommended. In areas with regular rainfall, cleaning 2-3 times per year might be sufficient."
    },
    {
      q: "Can rain clean my solar panels?",
      a: "While rain can help remove some loose dirt, it often isn't enough to clean panels thoroughly. Rain can also leave mineral deposits behind when it dries. Professional cleaning ensures optimal performance."
    }
  ];

  // SEO Configuration
  const seoData = {
    title: 'Solar Panel Cleaning Calculator',
    purpose: 'the impact of solar panel cleaning on system efficiency and potential savings',
    description: 'Calculate how much efficiency and money you can gain by maintaining clean solar panels. Get personalized recommendations for cleaning frequency and ROI analysis.',
    keywords: 'solar panel cleaning calculator, panel efficiency calculator, cleaning ROI calculator, maintenance savings calculator, solar performance optimization',
    path: '/calculators/cleaning',
    image: '/images/cleaning-calculator.jpg'
  };

  return (
    <div className={`p-4 sm:p-6 rounded-lg shadow-lg ${bgColor} ${textColor}`}>
      <SeoHead template="calculator" data={seoData} />

      <h1 className={`text-3xl font-bold mb-4 flex items-center ${headerColor}`}>
        <Brush className={`mr-2 ${iconHoverColor}`} /> Solar Panel Cleaning Calculator
      </h1>

      <p className="mb-6">
        Calculate how much efficiency and money you can gain by maintaining clean solar panels.
        Our calculator considers your system size, local conditions, and cleaning frequency to estimate potential savings.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <InputField
          label="Number of Panels"
          name="numberOfPanels"
          value={inputs.numberOfPanels}
          onChange={handleInputChange}
          tooltip="The total number of solar panels in your system"
          type="number"
          min={1}
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
        />

        <InputField
          label="Panel Wattage (W)"
          name="panelWattage"
          value={inputs.panelWattage}
          onChange={handleInputChange}
          tooltip="The power rating of each panel in watts"
          type="number"
          min={100}
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
        />

        <div className="relative group">
          <label className="block mb-2 font-semibold flex items-center">
            Current Dirt Level
            <HelpCircle size={16} className={`ml-2 cursor-help ${iconHoverColor}`} />
          </label>
          <select
            name="dirtLevel"
            value={inputs.dirtLevel}
            onChange={handleInputChange}
            className={`w-full p-2 rounded ${inputBgColor} ${inputBorderColor} border ${textColor}`}
          >
            <option value="light">Light Dust (5% loss)</option>
            <option value="moderate">Moderate Dirt (10% loss)</option>
            <option value="heavy">Heavy Soiling (15% loss)</option>
          </select>
          <div className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
            Select the current condition of your solar panels
          </div>
        </div>

        <InputField
          label="Cleaning Frequency (months)"
          name="cleaningFrequency"
          value={inputs.cleaningFrequency}
          onChange={handleInputChange}
          tooltip="How often you plan to clean your panels (in months)"
          type="number"
          min={1}
          max={12}
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
        />

        <InputField
          label="Electricity Rate (R/kWh)"
          name="electricityRate"
          value={inputs.electricityRate}
          onChange={handleInputChange}
          tooltip="Your current electricity rate per kilowatt-hour"
          type="number"
          step="0.01"
          min={0.1}
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
        />
      </div>

      {results && (
        <div className={`p-4 rounded-lg ${resultsBgColor} mb-6`}>
          <h2 className={`text-2xl font-bold mb-2 flex items-center ${headerColor}`}>
            <Calculator className={`mr-2 ${iconHoverColor}`} /> Cleaning Impact Analysis
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ResultItem
              icon={<Sun className={iconHoverColor} />}
              label="Current Efficiency Loss"
              value={`${results.efficiencyLoss}%`}
              description="Reduction in system efficiency due to dirt"
            />
            <ResultItem
              icon={<Brush className={iconHoverColor} />}
              label="Energy Loss"
              value={`${results.monthlyLoss} kWh/month`}
              description="Energy production lost due to soiling"
            />
            <ResultItem
              icon={<DollarSign className={iconHoverColor} />}
              label="Monthly Cost Impact"
              value={`R${results.monthlyCostLoss}`}
              description="Financial impact of reduced efficiency"
            />
            <ResultItem
              icon={<TrendingUp className={iconHoverColor} />}
              label="Annual Savings Potential"
              value={`R${results.annualSavings}`}
              description="Potential savings with regular cleaning"
            />
            <ResultItem
              icon={<Leaf className={iconHoverColor} />}
              label="Environmental Impact"
              value={`${results.carbonReduction} kg CO₂`}
              description="Annual carbon emission reduction potential"
            />
          </div>
        </div>
      )}

      <div className="mb-8">
        <h3 className={`text-xl font-bold mb-4 ${headerColor}`}>
          Frequently Asked Questions
        </h3>
        {commonQuestions.map((q, index) => (
          <div key={index} className="mb-4">
            <button
              className={`flex justify-between items-center w-full text-left font-semibold p-2 rounded ${
                isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-100 hover:bg-amber-200'
              } transition-colors duration-300`}
              onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}
            >
              {q.q}
              <ArrowRight
                className={`transform transition-transform duration-300 ${
                  activeQuestion === index ? 'rotate-90' : ''
                } ${iconHoverColor}`}
              />
            </button>
            {activeQuestion === index && (
              <p className={`mt-2 p-2 rounded ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                {q.a}
              </p>
            )}
          </div>
        ))}
      </div>

      <div className={`p-4 rounded-lg ${resultsBgColor}`}>
        <h3 className={`text-xl font-bold mb-2 flex items-center ${headerColor}`}>
          <Info className={`mr-2 ${iconHoverColor}`} /> Next Steps
        </h3>
        <p>
          Regular cleaning is crucial for maintaining optimal solar panel performance. Based on your results:
        </p>
        <ul className="list-disc list-inside mt-2">
          <li>Schedule professional cleaning services</li>
          <li>Consider a maintenance plan for regular upkeep</li>
          <li>Monitor your system's performance after cleaning</li>
        </ul>
        <button 
          onClick={() => navigate('/services/cleaning')}
          className="mt-4 px-6 py-2 bg-green-500 hover:bg-green-600 text-white font-bold rounded transition-colors duration-300"
        >
          Book Cleaning Service
        </button>
      </div>
    </div>
  );
};

const InputField = ({
  label,
  name,
  value,
  onChange,
  tooltip,
  type,
  min,
  max,
  step,
  bgColor,
  borderColor,
  textColor,
  iconHoverColor
}) => (
  <div className="relative group">
    <label className="block mb-2 font-semibold flex items-center">
      {label}
      <HelpCircle size={16} className={`ml-2 cursor-help ${iconHoverColor}`} />
    </label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      className={`w-full p-2 rounded ${bgColor} ${borderColor} border ${textColor}`}
    />
    <div className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
      {tooltip}
    </div>
  </div>
);

const ResultItem = ({ icon, label, value, description }) => (
  <div className="flex items-start">
    <div className="mr-3 mt-1">{icon}</div>
    <div>
      <p className="font-semibold">{label}</p>
      <p className="text-lg font-bold">{value}</p>
      <p className="text-sm">{description}</p>
    </div>
  </div>
);

export default CleaningCalculator; 