import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import SeoHead from '../common/SeoHead';
import {
  Share2,
  Bookmark,
  BookmarkPlus,
  ChevronUp,
  Calendar,
  Clock,
  Tag,
  ThumbsUp,
  ThumbsDown,
  List,
  ChevronRight,
  ArrowRight,
  Plus,
  Minus,
  HelpCircle,
  AlertTriangle,
  ClipboardCheck,
  ScanLine,
  FileText,
  Award,
  AlertOctagon,
  Banknote,
  Power,
  Scale,
  CheckCircle2,
  Shield,
  ShieldCheck,
  Network,
  Coins,
  Lightbulb
} from 'lucide-react';

const SolarRegistrationLaws2025 = () => {
  const { isDarkMode } = useTheme();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const [activeSection, setActiveSection] = useState('');
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [feedback, setFeedback] = useState({ helpful: 0, notHelpful: 0 });
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const articleRef = useRef(null);
  const sectionsRef = useRef({});

  const publishDate = "2025-02-10T09:00:00Z";
  const siteUrl = 'https://solarinsight.co.za';
  const articlePath = '/news/solar-registration-laws-2025';
  const fullUrl = `${siteUrl}${articlePath}`;

  const DEADLINE_DATE = new Date('2026-03-31T23:59:59').getTime();

  // SEO Configuration
  const seoData = {
    title: "South Africa's Solar Registration Laws 2025",
    description: "South African solar registration requirements 2025. Learn about March 2026 deadline, compliance costs, step-by-step registration process, and avoid R6,052 non-compliance fine. Free registration for systems under 50 kVA.",
    keywords: "solar registration South Africa, solar compliance 2025, SSEG registration, solar grid connection, solar installation laws, solar CoC, municipal solar regulations",
    publishDate,
    modifiedDate: publishDate,
    path: articlePath,
    image: '/images/solar-registration-laws.jpg',
    category: 'Policy',
    author: 'Solar Insight Team',
    readTime: '10 min read'
  };

  // Reading progress and scroll detection
  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setReadingProgress(progress);
      setShowScrollTop(window.scrollY > 300);
      
      // Update active section
      Object.entries(sectionsRef.current).forEach(([id, ref]) => {
        if (ref && ref.getBoundingClientRect().top < window.innerHeight / 3) {
          setActiveSection(id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date().getTime();
      const distance = DEADLINE_DATE - now;

      if (distance < 0) {
        setTimeRemaining({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        });
        return;
      }

      setTimeRemaining({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      });
    };

    const timer = setInterval(calculateTimeRemaining, 1000);
    calculateTimeRemaining();

    return () => clearInterval(timer);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (sectionId) => {
    sectionsRef.current[sectionId]?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `South Africa's New Solar Registration Laws: What Every Homeowner Needs to Know`,
          text: 'An in-depth guide to the new solar registration laws in South Africa and their impact on homeowners.',
          url: window.location.href
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    } else {
      navigator.clipboard.writeText(window.location.href);
      alert('Link copied to clipboard!');
    }
  };

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "2025 Solar Registration Laws South Africa: Complete Homeowner's Guide",
    "datePublished": publishDate,
    "dateModified": publishDate,
    "author": {
      "@type": "Organization",
      "name": "Solar Insight"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Solar Insight",
      "logo": {
        "@type": "ImageObject",
        "url": `${siteUrl}/logo.png`
      }
    },
    "description": "Essential guide to South Africa's 2025 solar registration laws. Covers March 2026 deadline, compliance requirements, costs, and step-by-step registration process. Free registration for systems under 50 kVA.",
    "keywords": "solar registration South Africa, Eskom compliance 2025, solar CoC, SSEG registration, solar grid connection, municipal solar regulations, solar installation laws",
    "articleSection": "Policy",
    "image": `${siteUrl}/images/solar-registration-laws.jpg`,
    "url": fullUrl,
    "mainEntityOfPage": fullUrl,
    "wordCount": "2500",
    "timeRequired": "PT10M",
    "articleBody": "Comprehensive guide to South Africa's 2025 solar registration laws and requirements..."
  };

  // Table of Contents for the article
  const tableOfContents = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'regulations', title: 'Understanding the New Solar Regulations' },
    { id: 'due-date-cost', title: 'Due Date & Costs' },
    { id: 'municipal-regulations', title: 'Municipal Regulations' },
    { id: 'registration-plan', title: 'Registration Process' },
    { id: 'consequences', title: 'Consequences of Non-Compliance' },
    { id: 'benefits', title: 'Benefits of Compliance' },
    { id: 'professional-guidance', title: 'Professional Guidance' },
    { id: 'final-thoughts', title: 'Final Thoughts' },
    { id: 'faqs', title: 'FAQs' }
  ];

  // Add this before the return statement
  const faqs = [
    {
      id: 1,
      question: "Do I need to register my solar system if it's not connected to the grid?",
      answer: "While off-grid systems technically don't require registration under current laws, it's recommended to register any solar installation. This ensures compliance with future regulations and maintains property value. Some municipalities may still require registration for safety and planning purposes.",
      category: "Registration Requirements"
    },
    {
      id: 2,
      question: "What is the deadline for registering my solar installation?",
      answer: "The deadline is March 2026. However, it's strongly recommended to register well before this date to avoid last-minute rushes and potential delays. Early registration also ensures you have time to address any compliance issues that may arise during the process.",
      category: "Deadlines"
    },
    {
      id: 3,
      question: "Are there any costs associated with the registration process?",
      answer: "During the grace period, registration for systems under 50 kVA is free. However, you may incur costs for:\n• Professional system inspection (R1,500 - R3,000)\n• Certificate of Compliance (CoC) (R800 - R1,500)\n• Smart meter installation if required (R2,000 - R5,000)\n• Any necessary system upgrades to meet standards",
      category: "Costs"
    },
    {
      id: 4,
      question: "What happens if I ignore these regulations?",
      answer: "Non-compliance can result in:\n• Initial fine of R6,052\n• Mandatory system disconnection\n• Potential legal action\n• Property sale complications\n• Invalidation of home insurance\nIt's crucial to comply to avoid these serious consequences.",
      category: "Compliance"
    },
    {
      id: 5,
      question: "What documentation do I need for registration?",
      answer: "Required documents include:\n• System specifications\n• Installation certificates\n• Compliance certificates (CoC)\n• Proof of ownership\n• Professional installation documentation\n• Municipal approval (if applicable)\n• Inverter and panel specifications",
      category: "Documentation"
    },
    {
      id: 6,
      question: "Can I register my system myself or do I need a professional?",
      answer: "While self-registration is possible, it's recommended to work with a certified solar installer or electrician. They can ensure:\n• Proper documentation\n• Technical compliance\n• Accurate system assessment\n• Smooth registration process\nThis typically results in faster approval and fewer complications.",
      category: "Process"
    },
    {
      id: 7,
      question: "Will registration affect my insurance coverage?",
      answer: "Proper registration can positively impact your insurance:\n• Ensures coverage validity\n• May reduce premiums\n• Protects against claim rejections\n• Demonstrates regulatory compliance\nUnregistered systems might void your home insurance coverage.",
      category: "Insurance"
    },
    {
      id: 8,
      question: "What are the benefits of registering my solar system?",
      answer: "Registration offers several advantages:\n• Legal compliance\n• Potential tax incentives\n• Increased property value\n• Easier future system upgrades\n• Access to feed-in tariffs\n• Better insurance coverage\n• Peace of mind",
      category: "Benefits"
    }
  ];

  const [expandedFaqs, setExpandedFaqs] = useState(new Set());
  const [selectedCategory, setSelectedCategory] = useState('all');

  const toggleFaq = (id) => {
    setExpandedFaqs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const faqCategories = ['all', ...new Set(faqs.map(faq => faq.category))];

  const filteredFaqs = selectedCategory === 'all' 
    ? faqs 
    : faqs.filter(faq => faq.category === selectedCategory);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-gray-100' : 'bg-amber-50 text-amber-900'}`}>
      <SeoHead template="article" data={seoData} />

      {/* Reading Progress Bar */}
      <div 
        className="fixed top-0 left-0 w-full h-1 bg-gray-200 z-50"
        style={{ background: isDarkMode ? 'rgba(30, 58, 138, 0.3)' : 'rgba(251, 191, 36, 0.3)' }}
      >
        <div
          className={`h-full transition-all duration-300 ${isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'}`}
          style={{ width: `${readingProgress}%` }}
        />
      </div>

      <main className="container mx-auto px-4 py-8">
        <article className="max-w-4xl mx-auto" ref={articleRef}>
          {/* Hero Section */}
          <section className={`rounded-xl shadow-2xl mb-12 p-8 ${isDarkMode ? 'bg-gradient-to-br from-blue-800 to-blue-900' : 'bg-gradient-to-br from-amber-100 to-amber-200'}`}>
            <h1 className={`text-4xl md:text-6xl font-bold mb-6 text-center ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
              South Africa's Solar Registration Laws
            </h1>
            <p className={`text-xl mb-8 text-center ${isDarkMode ? 'text-yellow-100' : 'text-amber-800'}`}>
              A must-read guide for every homeowner with a solar power system
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button 
                onClick={handleShare}
                className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${isDarkMode ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' : 'bg-amber-500 text-white hover:bg-amber-600'}`}
              >
                <Share2 className="h-5 w-5" />
                <span>Share Article</span>
              </button>
              <button 
                onClick={handleBookmark}
                className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${isBookmarked ? (isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white') : (isDarkMode ? 'border-2 border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-blue-900' : 'border-2 border-amber-500 text-amber-500 hover:bg-amber-500 hover:text-white')}`}
              >
                {isBookmarked ? <Bookmark className="h-5 w-5" /> : <BookmarkPlus className="h-5 w-5" />}
                <span>{isBookmarked ? 'Saved' : 'Save for Later'}</span>
              </button>
            </div>
          </section>

          {/* Article Meta */}
          <section className={`flex items-center justify-between mb-8 p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Calendar className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <time dateTime={publishDate} className="text-sm">February 10, 2025</time>
              </div>
              <div className="flex items-center gap-2">
                <Clock className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">10 min read</span>
              </div>
              <div className="flex items-center gap-2">
                <Tag className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">Policy</span>
              </div>
            </div>
          </section>

          {/* Countdown Timer */}
          <section className={`mb-12 p-8 rounded-xl shadow-2xl ${isDarkMode ? 'bg-blue-800/80' : 'bg-amber-100/80'}`}>
            <div className="flex items-center justify-between mb-4">
              <h2 className={`text-2xl md:text-3xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Solar Registration Deadline Countdown
              </h2>
              <AlertTriangle className={`h-6 w-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
            </div>
            <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-amber-800'}`}>
              Time remaining until the <span className="font-bold">mandatory solar system registration deadline (March 31, 2026)</span>:
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 md:gap-4">
              {[
                { label: 'Days', value: timeRemaining.days },
                { label: 'Hours', value: timeRemaining.hours },
                { label: 'Minutes', value: timeRemaining.minutes },
                { label: 'Seconds', value: timeRemaining.seconds }
              ].map(({ label, value }) => (
                <div
                  key={label}
                  className={`text-center p-3 md:p-4 rounded-lg ${
                    isDarkMode ? 'bg-blue-700' : 'bg-amber-200'
                  }`}
                >
                  <div className={`text-xl md:text-3xl font-bold mb-1 md:mb-2 ${
                    isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                  }`}>
                    {value.toString().padStart(2, '0')}
                  </div>
                  <div className={`text-xs md:text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-amber-800'
                  }`}>
                    {label}
                  </div>
                </div>
              ))}
            </div>
            <p className={`mt-6 text-sm ${isDarkMode ? 'text-gray-400' : 'text-amber-600'}`}>
              ⚠️ Failure to register by the deadline may result in a R6,052 fine and system disconnection
            </p>
          </section>

          {/* Table of Contents Toggle */}
          <button
            onClick={() => setShowTableOfContents(!showTableOfContents)}
            className={`fixed left-4 top-1/2 transform -translate-y-1/2 p-3 rounded-full shadow-lg ${isDarkMode ? 'bg-blue-800 text-yellow-400' : 'bg-amber-100 text-amber-700'}`}
          >
            <List className="h-6 w-6" />
          </button>

          {/* Floating Table of Contents */}
          {showTableOfContents && (
            <div className={`fixed left-16 top-1/2 transform -translate-y-1/2 p-4 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
              <h3 className={`font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>Table of Contents</h3>
              <ul className="space-y-2">
                {tableOfContents.map(({ id, title }) => (
                  <li key={id}>
                    <button
                      onClick={() => scrollToSection(id)}
                      className={`flex items-center text-left ${activeSection === id ? (isDarkMode ? 'text-yellow-400' : 'text-amber-700') : 'text-gray-400'} hover:text-yellow-400 transition-colors`}
                    >
                      <ChevronRight className={`h-4 w-4 mr-2 ${activeSection === id ? 'opacity-100' : 'opacity-0'}`} />
                      {title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Main Content Sections */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'}`}>
            <div className="prose prose-lg max-w-none">
              {/* Introduction */}
              <div ref={(el) => sectionsRef.current['introduction'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>South Africa's Solar Registration Laws</h2>
                <p className={`text-xl leading-relaxed mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Many people have either switched to <span className="font-bold">solar power</span> or are considering doing so. South Africans are turning to solar power to circumvent <span className="font-bold">Eskom's costly and inconvenient power interruptions</span>.
                </p>
                <p className={`text-xl leading-relaxed mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  However, if you have installed a solar power system at your home, you <span className="font-bold">must understand the new solar registration laws</span>. These rules and regulations aren't just unnecessary bureaucracy; they're <span className="font-bold">essential for the security of the energy system</span> and everyone's well-being.
                </p>
                <p className={`text-xl leading-relaxed mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  And believe me when I say that if you disregard them, you'll end up with a <span className="font-bold">hefty fine of R6,052</span> or, even worse, <span className="font-bold">no electricity</span>. Let's break it down to understand the requirements.
                </p>
              </div>

              {/* New Solar Regulations */}
              <div ref={(el) => sectionsRef.current['regulations'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  <FileText className="inline-block mr-2 h-8 w-8" />
                  Understanding the New Solar Regulations
                </h2>
                
                {/* Introduction Card */}
                <div className={`p-6 rounded-lg mb-8 ${isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'}`}>
                  <h3 className={`text-xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                    Key Legislative Changes
                  </h3>
                  <div className={`space-y-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                    <p>
                      The South African government has introduced <span className="font-bold">comprehensive new regulations for small-scale embedded generation (SSEG) systems</span>. These changes are part of the <span className="font-bold">Electricity Regulation Amendment Act</span>, which took effect on <span className="font-bold">January 1, 2025</span>.
                    </p>
                    <p>
                      <span className="font-bold">Important:</span> Registration is mandatory for <span className="font-bold">all solar installations</span>, regardless of whether you intend to feed power back into the grid or not.
                    </p>
                  </div>
                </div>

                {/* Requirements Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                  {[
                    {
                      title: 'System Registration',
                      icon: <ClipboardCheck className="h-8 w-8" />,
                      description: 'Register with your local authority',
                      details: [
                        'Choose between Eskom or municipality',
                        'Submit within deadline',
                        'Provide system documentation',
                        'Complete online registration'
                      ],
                      color: isDarkMode ? 'bg-green-900/30' : 'bg-green-100'
                    },
                    {
                      title: 'Safety Certification',
                      icon: <ShieldCheck className="h-8 w-8" />,
                      description: 'Obtain Certificate of Compliance',
                      details: [
                        'Professional safety inspection',
                        'Electrical compliance check',
                        'Installation verification',
                        'Documentation of standards met'
                      ],
                      color: isDarkMode ? 'bg-blue-900/30' : 'bg-blue-100'
                    },
                    {
                      title: 'System Testing',
                      icon: <ScanLine className="h-8 w-8" />,
                      description: 'Pass mandatory compliance tests',
                      details: [
                        'Performance verification',
                        'Safety feature testing',
                        'Grid connection check',
                        'System functionality audit'
                      ],
                      color: isDarkMode ? 'bg-yellow-900/30' : 'bg-yellow-100'
                    }
                  ].map(({ title, icon, description, details, color }) => (
                    <div 
                      key={title}
                      className={`p-6 rounded-lg transition-all duration-300 ${color}`}
                    >
                      <div className="flex items-center gap-3 mb-4">
                        <div className={`${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                          {icon}
                        </div>
                        <h3 className={`text-xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                          {title}
                        </h3>
                      </div>
                      <p className={`mb-4 font-medium ${isDarkMode ? 'text-gray-200' : 'text-amber-900'}`}>
                        {description}
                      </p>
                      <ul className={`space-y-2 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                        {details.map((detail, index) => (
                          <li key={index} className="flex items-center gap-2">
                            <CheckCircle2 className={`h-4 w-4 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                            <span className="text-sm">{detail}</span>
                          </li>
                        ))}
                </ul>
                    </div>
                  ))}
                </div>

                {/* Warning Box */}
                <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-red-900/30' : 'bg-red-100'}`}>
                  <div className="flex items-center gap-3">
                    <AlertTriangle className={`h-6 w-6 ${isDarkMode ? 'text-yellow-400' : 'text-red-500'}`} />
                    <div>
                      <h4 className={`font-bold mb-1 ${isDarkMode ? 'text-yellow-400' : 'text-red-700'}`}>
                        Non-Compliance Consequences
                      </h4>
                      <p className={`${isDarkMode ? 'text-gray-200' : 'text-red-700'}`}>
                        Failure to comply with these regulations by the deadline will result in <span className="font-bold">severe penalties</span>, including fines, mandatory system disconnection, and potential legal proceedings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Due Date & Costs */}
              <div ref={(el) => sectionsRef.current['due-date-cost'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>Due Date & Costs</h2>
                <p className={`mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  The <span className="font-bold">deadline for system registration is March 2026</span>. Systems with a power output <span className="font-bold">below 50 kVA are eligible for free registration</span>. However, costs may still be incurred for inspections or installing smart meters. Ignoring these rules can lead to a <span className="font-bold">fine of R6,052</span>.
                </p>
              </div>

              {/* Municipal Regulations */}
              <div ref={(el) => sectionsRef.current['municipal-regulations'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>Municipal Regulations</h2>
                <p className={`mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Beyond Eskom, <span className="font-bold">municipalities implement their own regulations</span>. Depending on your location, make sure you understand the guidelines mandated by your local authority. For example, in <span className="font-bold">Cape Town, pre-approval is required</span> before installing a solar PV system.
                </p>
              </div>

              {/* Registration Process */}
              <div ref={(el) => sectionsRef.current['registration-plan'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  <ClipboardCheck className="inline-block mr-2 h-8 w-8" />
                  Registration Process
                </h2>
                <p className={`mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Follow this comprehensive step-by-step plan for registering your solar power installation:
                </p>
                <div className="space-y-6">
                  {[
                    {
                      step: 1,
                      title: 'Assess Your Solar System',
                      icon: <ScanLine className="h-6 w-6" />,
                      description: 'Determine if your system is grid-connected or off-grid and ensure compliance with safety standards.',
                      tips: [
                        'Check system specifications',
                        'Review safety documentation',
                        'Consult professional installer if needed'
                      ]
                    },
                    {
                      step: 2,
                      title: 'Submit Your Application',
                      icon: <FileText className="h-6 w-6" />,
                      description: 'Apply to your municipality or Eskom with all required documentation.',
                      tips: [
                        'Technical specifications',
                        'Installation plans',
                        'Compliance certificates (CoC)',
                        'Test reports'
                      ]
                    },
                    {
                      step: 3,
                      title: 'Get Certified',
                      icon: <Award className="h-6 w-6" />,
                      description: 'Await review and inspection for final approval.',
                      tips: [
                        'Schedule inspection',
                        'Prepare for site visit',
                        'Receive registration certificate'
                      ]
                    }
                  ].map(({ step, title, icon, description, tips }) => (
                    <div 
                      key={step}
                      className={`relative flex items-start p-6 rounded-lg transition-all duration-300 ${
                        isDarkMode 
                          ? 'bg-blue-700/50 hover:bg-blue-700/70' 
                          : 'bg-amber-200/50 hover:bg-amber-200/70'
                      }`}
                    >
                      <div className={`absolute -left-3 top-6 w-8 h-8 rounded-full flex items-center justify-center ${
                        isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'
                      }`}>
                        {step}
                      </div>
                      <div className="ml-8 w-full">
                        <div className="flex items-center gap-2 mb-3">
                          {icon}
                          <h3 className={`text-xl font-semibold ${
                            isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                          }`}>{title}</h3>
                        </div>
                        <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                          {description}
                        </p>
                        <div className={`grid grid-cols-1 sm:grid-cols-2 gap-2 ${
                          isDarkMode ? 'text-gray-300' : 'text-amber-900'
                        }`}>
                          {tips.map((tip, index) => (
                            <div key={index} className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 flex-shrink-0" />
                              <span className="text-sm">{tip}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Consequences of Non-Compliance */}
              <div ref={(el) => sectionsRef.current['consequences'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  <AlertOctagon className="inline-block mr-2 h-8 w-8" />
                  Consequences of Non-Compliance
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {[
                    {
                      title: 'Financial Penalty',
                      icon: <Banknote className="h-12 w-12" />,
                      description: <span className="font-bold">'R6,052 fine for non-registration'</span>,
                      details: <span className="font-bold">'Immediate penalty upon deadline expiry'</span>,
                      color: isDarkMode ? 'bg-red-900/30' : 'bg-red-100'
                    },
                    {
                      title: 'System Disconnection',
                      icon: <Power className="h-12 w-12" />,
                      description: <span className="font-bold">'Forced disconnection from grid'</span>,
                      details: <span className="font-bold">'Immediate power system shutdown'</span>,
                      color: isDarkMode ? 'bg-orange-900/30' : 'bg-orange-100'
                    },
                    {
                      title: 'Legal Consequences',
                      icon: <Scale className="h-12 w-12" />,
                      description: <span className="font-bold">'Potential legal proceedings'</span>,
                      details: <span className="font-bold">'Court action and additional penalties'</span>,
                      color: isDarkMode ? 'bg-yellow-900/30' : 'bg-yellow-100'
                    }
                  ].map(({ title, icon, description, details, color }) => (
                    <div 
                      key={title}
                      className={`p-6 rounded-lg transition-all duration-300 transform hover:scale-105 ${color}`}
                    >
                      <div className={`flex flex-col items-center text-center ${
                        isDarkMode ? 'text-gray-100' : 'text-amber-900'
                      }`}>
                        <div className="mb-4">
                          {icon}
                        </div>
                        <h3 className={`text-xl font-bold mb-2 ${
                          isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                        }`}>{title}</h3>
                        <p className="font-semibold mb-2">{description}</p>
                        <p className="text-sm opacity-80">{details}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={`mt-6 p-4 rounded-lg ${
                  isDarkMode ? 'bg-red-900/30' : 'bg-red-100'
                }`}>
                  <div className="flex items-center gap-2">
                    <AlertTriangle className={`h-5 w-5 ${
                      isDarkMode ? 'text-yellow-400' : 'text-red-500'
                    }`} />
                    <p className={`font-semibold ${
                      isDarkMode ? 'text-gray-100' : 'text-red-700'
                    }`}>
                      Don't risk these consequences - register your solar system before the deadline!
                    </p>
                  </div>
                </div>
              </div>

              {/* Benefits of Compliance */}
              <div ref={(el) => sectionsRef.current['benefits'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  <Shield className="inline-block mr-2 h-8 w-8" />
                  Benefits of Compliance
                </h2>
                <p className={`mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Registering your solar system offers several key advantages that protect both you and your investment:
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {[
                    {
                      title: 'Enhanced Safety Standards',
                      icon: <ShieldCheck className="h-12 w-12" />,
                      description: 'Ensures your installation meets rigorous safety requirements',
                      benefits: [
                        'Professional safety certification',
                        'Reduced risk of electrical fires',
                        'Insurance compliance',
                        'Regular safety inspections'
                      ],
                      color: isDarkMode ? 'bg-green-900/30' : 'bg-green-100'
                    },
                    {
                      title: 'Grid Integration Benefits',
                      icon: <Network className="h-12 w-12" />,
                      description: 'Contributes to a stable and efficient power network',
                      benefits: [
                        'Smart grid compatibility',
                        'System load balancing',
                        'Emergency power support',
                        'Grid modernization'
                      ],
                      color: isDarkMode ? 'bg-blue-900/30' : 'bg-blue-100'
                    },
                    {
                      title: 'Financial Advantages',
                      icon: <Coins className="h-12 w-12" />,
                      description: 'Access to monetary benefits and incentives',
                      benefits: [
                        'Feed-in tariff eligibility',
                        'Tax incentive access',
                        'Increased property value',
                        'Potential rebate programs'
                      ],
                      color: isDarkMode ? 'bg-yellow-900/30' : 'bg-yellow-100'
                    }
                  ].map(({ title, icon, description, benefits, color }) => (
                    <div 
                      key={title}
                      className={`p-6 rounded-lg transition-all duration-300 transform hover:scale-105 ${color}`}
                    >
                      <div className={`flex flex-col h-full ${
                        isDarkMode ? 'text-gray-100' : 'text-amber-900'
                      }`}>
                        <div className="flex items-center gap-3 mb-4">
                          <div className={`${
                            isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                          }`}>
                            {icon}
                          </div>
                          <h3 className={`text-xl font-bold ${
                            isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                          }`}>{title}</h3>
                        </div>
                        <p className="mb-4 font-medium">{description}</p>
                        <div className="space-y-2 mt-auto">
                          {benefits.map((benefit, index) => (
                            <div key={index} className="flex items-center gap-2">
                              <CheckCircle2 className={`h-4 w-4 flex-shrink-0 ${
                                isDarkMode ? 'text-yellow-400' : 'text-amber-500'
                              }`} />
                              <span className="text-sm">{benefit}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={`mt-6 p-4 rounded-lg ${
                  isDarkMode ? 'bg-blue-700/50' : 'bg-amber-200/50'
                }`}>
                  <div className="flex items-center gap-2">
                    <Lightbulb className={`h-5 w-5 ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-500'
                    }`} />
                    <p className={`font-semibold ${
                      isDarkMode ? 'text-gray-100' : 'text-amber-900'
                    }`}>
                      <span className="font-bold">Pro Tip:</span> Early registration not only ensures compliance but also gives you priority access to these benefits!
                    </p>
                  </div>
                </div>
              </div>

              {/* Professional Guidance */}
              <div ref={(el) => sectionsRef.current['professional-guidance'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>Professional Guidance</h2>
                <ul className={`list-disc list-inside space-y-2 mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  <li><span className="font-bold">Hire Professionals:</span> Work with certified electricians and installers.</li>
                  <li><span className="font-bold">Organize Documentation:</span> Keep all certificates, approvals, and invoices in order.</li>
                  <li><span className="font-bold">Stay Updated:</span> Regularly check Eskom's website and local municipal announcements.</li>
                </ul>
              </div>

              {/* Final Thoughts */}
              <div ref={(el) => sectionsRef.current['final-thoughts'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>Final Thoughts: Don't Get Left in the Dark!</h2>
                <p className={`mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Solar power is booming, but compliance with registration laws is essential to avoid heavy fines, power disconnections, or legal issues. Stay informed, follow the rules, and protect your investment while contributing to a sustainable energy future.
                </p>
              </div>

              {/* FAQs Section */}
              <div ref={(el) => sectionsRef.current['faqs'] = el}>
                <section className={`p-8 rounded-lg shadow-xl mb-12 ${
                  isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
                }`}>
                  <div className="flex items-center justify-between mb-8">
                    <h2 className={`text-3xl font-bold ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                    }`}>
                      Frequently Asked Questions
                    </h2>
                    <HelpCircle className={`h-8 w-8 ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                    }`} />
                  </div>

                  {/* Category Filter */}
                  <div className="flex flex-wrap gap-2 mb-6">
                    {faqCategories.map(category => (
                      <button
                        key={category}
                        onClick={() => setSelectedCategory(category)}
                        className={`px-4 py-2 rounded-full transition-all duration-200 ${
                          selectedCategory === category
                            ? isDarkMode
                              ? 'bg-yellow-400 text-blue-900'
                              : 'bg-amber-500 text-white'
                            : isDarkMode
                              ? 'bg-blue-700 text-gray-300 hover:bg-blue-600'
                              : 'bg-amber-200 text-amber-900 hover:bg-amber-300'
                        }`}
                      >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </button>
                    ))}
                  </div>

                  {/* FAQ Items */}
                  <div className="space-y-4">
                    {filteredFaqs.map((faq) => (
                      <div
                        key={faq.id}
                        className={`border rounded-lg transition-all duration-300 ${
                          isDarkMode
                            ? 'border-blue-700 hover:border-yellow-400'
                            : 'border-amber-200 hover:border-amber-500'
                        }`}
                      >
                        <button
                          onClick={() => toggleFaq(faq.id)}
                          className={`w-full px-6 py-4 flex items-center justify-between ${
                            expandedFaqs.has(faq.id)
                              ? isDarkMode
                                ? 'bg-blue-700'
                                : 'bg-amber-200'
                              : ''
                          }`}
                        >
                          <span className={`font-semibold text-left ${
                            isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                          }`}>
                            {faq.question}
                          </span>
                          {expandedFaqs.has(faq.id) 
                            ? <Minus className="h-5 w-5 flex-shrink-0" />
                            : <Plus className="h-5 w-5 flex-shrink-0" />
                          }
                        </button>
                        
                        {/* Answer */}
                        {expandedFaqs.has(faq.id) && (
                          <div className={`px-6 py-4 border-t ${
                            isDarkMode ? 'border-blue-700 bg-blue-700/50' : 'border-amber-200 bg-amber-200/50'
                          }`}>
                            <div className={`space-y-2 ${
                              isDarkMode ? 'text-gray-300' : 'text-amber-900'
                            }`}>
                              {faq.answer.split('\n').map((line, index) => (
                                <p 
                                  key={index} 
                                  className={`${line.startsWith('•') ? 'pl-4' : ''} ${
                                    line.startsWith('•') ? 'border-l-2 border-yellow-400' : ''
                                  }`}
                                >
                                  {line}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            </div>
          </section>

          {/* Article Feedback Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'}`}>
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>Was this article helpful?</h2>
            <div className="flex gap-4">
              <button
                onClick={() => setFeedback(prev => ({ ...prev, helpful: prev.helpful + 1 }))}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-200 hover:bg-amber-300'}`}
              >
                <ThumbsUp className="h-5 w-5" />
                <span>Yes ({feedback.helpful})</span>
              </button>
              <button
                onClick={() => setFeedback(prev => ({ ...prev, notHelpful: prev.notHelpful + 1 }))}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-200 hover:bg-amber-300'}`}
              >
                <ThumbsDown className="h-5 w-5" />
                <span>No ({feedback.notHelpful})</span>
              </button>
            </div>
          </section>

          {/* Related Articles Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'}`}>
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>Related Articles</h2>
            <div className="grid gap-6">
              <Link
                to="/news/perovskite-solar-breakthrough-2025"
                className={`p-4 rounded-lg transition-all duration-300 ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-200 hover:bg-amber-300'}`}
              >
                <div className="flex items-center justify-between">
                  <h3 className={`font-semibold ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>New Perovskite Solar Cells Break Efficiency Record</h3>
                  <ArrowRight className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`} />
                </div>
                <p className="text-sm mt-2">January 29, 2025</p>
              </Link>
              <Link
                to="/news/load-shedding-south-africa-2025"
                className={`p-4 rounded-lg transition-all duration-300 ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-200 hover:bg-amber-300'}`}
              >
                <div className="flex items-center justify-between">
                  <h3 className={`font-semibold ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>Load Shedding in South Africa 2025: Crisis and Solutions</h3>
                  <ArrowRight className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`} />
                </div>
                <p className="text-sm mt-2">January 31, 2025</p>
              </Link>
            </div>
          </section>

          {/* Scroll to Top Button */}
          {showScrollTop && (
            <button
              onClick={scrollToTop}
              className={`fixed bottom-8 right-8 p-3 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 ${isDarkMode ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' : 'bg-amber-500 text-white hover:bg-amber-600'}`}
            >
              <ChevronUp className="h-6 w-6" />
            </button>
          )}
        </article>
      </main>
    </div>
  );
};

export default SolarRegistrationLaws2025; 