import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Battery } from 'lucide-react';
import SeoHead from '../common/SeoHead';

const BatteryPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'Lithium-Ion Batteries', description: 'Popular for home installations, long lifespan, ideal for frequent use during load shedding' },
    { name: 'Lead-Acid Batteries', description: 'Lower upfront cost, require more maintenance' },
  ];

  const features = [
    'Capacity (kWh)',
    'Depth of Discharge (DoD)',
    'Cycle life',
    'Compatibility with SA\'s electrical standards',
  ];

  const brands = ['Freedom Won', 'Pylontech', 'Hubble', 'Dyness'];

  // SEO Configuration
  const seoData = {
    title: 'Solar Batteries & Energy Storage',
    description: 'Comprehensive range of solar batteries and energy storage solutions. Compare lithium-ion and lead-acid options, explore features, and find the perfect battery system for load shedding protection.',
    keywords: 'solar batteries, energy storage, lithium-ion batteries, lead-acid batteries, battery backup, load shedding solutions, solar power storage',
    path: '/product/batteries',
    image: '/images/solar-batteries.jpg',
    price: {
      currency: 'ZAR',
      minPrice: 15000,
      maxPrice: 100000
    },
    brands: brands,
    specifications: {
      types: types.map(t => t.name),
      features: features
    },
    rating: {
      value: 4.8,
      count: 150
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <SeoHead template="product" data={seoData} />
      
      <main className="container mx-auto px-4 py-8">
        <div className="flex items-center mb-8">
          <Battery className={`w-10 h-10 mr-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
          <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Solar Battery Storage Solutions
          </h1>
        </div>

        <p className="mb-8 text-lg">
          Solar batteries store excess energy for use during nighttime or power outages, providing reliable backup power for your home.
        </p>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Types of Batteries
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {types.map((type, index) => (
              <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
                <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
                <p>{type.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Key Features
          </h2>
          <ul className="list-disc list-inside">
            {features.map((feature, index) => (
              <li key={index} className="mb-2">{feature}</li>
            ))}
          </ul>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Top Brands in SA
          </h2>
          <div className="flex flex-wrap">
            {brands.map((brand, index) => (
              <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
                {brand}
              </span>
            ))}
          </div>
        </section>

        <section>
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Price Range
          </h2>
          <p className="text-lg font-semibold">
            R50,000 - R150,000 for a home battery system
          </p>
        </section>
      </main>
    </div>
  );
};

export default BatteryPage;