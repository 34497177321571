import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Cpu } from 'lucide-react';
import SeoHead from '../common/SeoHead';

const ChargeControllerPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'PWM (Pulse Width Modulation)', description: 'Cost-effective option for smaller systems' },
    { name: 'MPPT (Maximum Power Point Tracking)', description: 'More efficient, ideal for larger systems or varying conditions' },
  ];

  const features = [
    'Charging efficiency',
    'Voltage and current ratings',
    'Temperature compensation',
    'Display and monitoring capabilities',
    'Battery type compatibility',
  ];

  const brands = ['Victron Energy', 'Morningstar', 'Epever', 'Outback Power'];

  // SEO Configuration
  const seoData = {
    title: 'Solar Charge Controllers | MPPT & PWM Controllers | Solar Insight',
    description: 'High-quality solar charge controllers for optimal battery charging and system protection. MPPT and PWM controllers from leading brands.',
    keywords: 'solar charge controller, MPPT controller, PWM controller, battery charging, solar system protection, South Africa solar controllers',
    path: '/products/charge-controllers',
    image: '/images/charge-controller.jpg',
    productType: 'Charge Controller',
    features: [
      'MPPT Technology',
      'PWM Options',
      'Battery Protection',
      'System Monitoring',
      'Overcharge Prevention'
    ],
    specifications: {
      voltageRange: '12V-48V',
      maxCurrent: '60A-100A',
      efficiency: '98%',
      warrantyYears: 5
    },
    brands: [
      'Victron Energy',
      'Schneider Electric',
      'Outback Power',
      'Studer Innotec'
    ]
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="product" data={seoData} />
      
      <main className="container mx-auto px-4 py-8">
        <div className="flex items-center mb-8">
          <Cpu className={`w-10 h-10 mr-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
          <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Solar Charge Controllers
          </h1>
        </div>

        <p className="mb-8 text-lg">
          Charge controllers regulate the voltage and current from solar panels to batteries, ensuring optimal charging and system longevity.
        </p>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Types of Charge Controllers
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {types.map((type, index) => (
              <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
                <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
                <p>{type.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Key Features
          </h2>
          <ul className="list-disc list-inside">
            {features.map((feature, index) => (
              <li key={index} className="mb-2">{feature}</li>
            ))}
          </ul>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Top Brands in SA
          </h2>
          <div className="flex flex-wrap">
            {brands.map((brand, index) => (
              <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
                {brand}
              </span>
            ))}
          </div>
        </section>

        <section>
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Price Range
          </h2>
          <p className="text-lg font-semibold">
            R1,000 - R15,000 (depending on type and capacity)
          </p>
        </section>
      </main>
    </div>
  );
};

export default ChargeControllerPage;