import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import SeoHead from './common/SeoHead';
import { Helmet } from 'react-helmet';
import { Sun, Battery, Zap, DollarSign, BarChart, Droplet, X, Search, ChevronDown } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import ErrorBoundary from './ErrorBoundary';

const InsightPage = () => {
  const { isDarkMode } = useTheme();
  const [selectedInsight, setSelectedInsight] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('default');
  const [isLoading, setIsLoading] = useState(true);

  const insights = [
    { 
      title: 'Solar Panel Efficiency', 
      icon: Sun, 
      color: 'text-yellow-500',
      category: 'Technology',
      content: 'Modern solar panels can convert up to 22% of sunlight into electricity, with some high-end models reaching 23-24% efficiency. Key factors affecting efficiency include:\n\n1. Panel type: Monocrystalline panels are generally more efficient than polycrystalline.\n2. Orientation and tilt: Panels facing south (in the Northern Hemisphere) at a tilt equal to the latitude typically perform best.\n3. Temperature: Cooler temperatures improve efficiency, with most panels losing 0.3-0.5% efficiency per degree Celsius increase.\n4. Shading: Even partial shade can significantly reduce panel output.\n5. Maintenance: Regular cleaning and inspection can maintain optimal performance.\n\nResearchers are continuously working on improving efficiency, with some laboratory cells achieving over 40% efficiency using multi-junction designs.',
      data: [
        { year: 2010, efficiency: 15 },
        { year: 2012, efficiency: 16 },
        { year: 2014, efficiency: 17 },
        { year: 2016, efficiency: 18 },
        { year: 2018, efficiency: 19 },
        { year: 2020, efficiency: 20 },
        { year: 2022, efficiency: 22 },
      ]
    },
    { 
      title: 'Battery Storage Solutions', 
      icon: Battery, 
      color: 'text-green-500',
      category: 'Technology',
      content: 'Battery storage systems are crucial for maximizing the benefits of solar energy. They allow you to store excess power generated during sunny periods for use during nighttime or cloudy days, increasing energy independence. Key considerations for battery storage include:\n\n1. Capacity: Typically ranging from 4kWh to 20kWh for residential systems.\n2. Chemistry: Lithium-ion batteries are most common due to their high energy density and long lifespan.\n3. Depth of Discharge (DoD): Higher DoD means more usable capacity but may affect battery lifespan.\n4. Cycle life: Premium batteries can last 10,000 cycles or more.\n5. Smart features: Many modern systems offer energy management and monitoring capabilities.\n6. Scalability: Some systems allow for easy expansion of capacity.\n\nEmerging technologies like flow batteries and solid-state batteries promise even better performance and longevity in the future.',
      data: [
        { year: 2010, capacity: 2 },
        { year: 2012, capacity: 3 },
        { year: 2014, capacity: 5 },
        { year: 2016, capacity: 8 },
        { year: 2018, capacity: 13 },
        { year: 2020, capacity: 20 },
        { year: 2022, capacity: 30 },
      ]
    },
    { 
      title: 'Energy Consumption Patterns', 
      icon: Zap, 
      color: 'text-purple-500',
      category: 'Usage',
      content: 'Understanding your energy consumption patterns is crucial for optimizing your solar system size and maximizing savings. Key aspects to consider include:\n\n1. Daily usage profile: Identify peak consumption times and align them with solar production when possible.\n2. Seasonal variations: Account for higher usage in summer (due to AC) or winter (due to heating).\n3. Base load: Determine the minimum continuous power needed for always-on appliances.\n4. Energy-intensive appliances: Note when high-draw devices like ovens or dryers are typically used.\n5. Behavioral changes: Consider how lifestyle adjustments can shift energy use to high-production hours.\n6. Future needs: Factor in potential additions like electric vehicles or home expansions.\n\nUtilizing smart home energy monitors can provide detailed insights into consumption patterns, helping you make informed decisions about system sizing and usage habits.',
      data: [
        { month: 'Jan', consumption: 900 },
        { month: 'Feb', consumption: 850 },
        { month: 'Mar', consumption: 800 },
        { month: 'Apr', consumption: 750 },
        { month: 'May', consumption: 700 },
        { month: 'Jun', consumption: 800 },
      ]
    },
    { 
      title: 'Financial Benefits', 
      icon: DollarSign, 
      color: 'text-blue-500',
      category: 'Economics',
      content: 'Solar installations offer significant financial benefits, potentially eliminating electricity bills and providing a solid return on investment (ROI). Key financial aspects include:\n\n1. Initial cost: Varies widely but has decreased significantly, now averaging $2.5-$3.5 per watt installed.\n2. Payback period: Typically ranges from 5-10 years, depending on location, energy costs, and incentives.\n3. Electricity savings: Can save $10,000-$30,000 over the system\'s lifetime in many areas.\n4. Incentives: Federal tax credits, state rebates, and local incentives can significantly reduce upfront costs.\n5. Net metering: Allows you to sell excess energy back to the grid, further increasing savings.\n6. Property value increase: Homes with solar systems often sell for 3-4% more than comparable non-solar homes.\n7. Protection against rising energy costs: Solar provides a hedge against future electricity price increases.\n\nConsider using financial modeling tools to project long-term savings based on your specific situation and local factors.',
      data: [
        { year: 1, savings: 1000 },
        { year: 2, savings: 2100 },
        { year: 3, savings: 3300 },
        { year: 4, savings: 4600 },
        { year: 5, savings: 6000 },
      ]
    },
    { 
      title: 'Performance Metrics', 
      icon: BarChart, 
      color: 'text-red-500',
      category: 'Technology',
      content: 'Monitoring key performance indicators (KPIs) is essential for ensuring your solar system is operating optimally. Important metrics include:\n\n1. Energy production: Measured in kilowatt-hours (kWh), this should be tracked daily and compared against expectations.\n2. Performance ratio: The percentage of actual vs. theoretical maximum output, typically 70-90% for well-performing systems.\n3. Specific yield: Energy generated per kilowatt of installed capacity, useful for comparing different systems.\n4. Capacity factor: The ratio of actual energy produced to the maximum possible if the system operated at full capacity 24/7.\n5. Inverter efficiency: Most modern inverters operate at 95-98% efficiency.\n6. Degradation rate: Annual performance loss, typically 0.5-1% for quality panels.\n7. Financial metrics: Include cost per kWh produced, ROI, and payback period.\n\nMany modern solar systems come with monitoring apps that track these metrics in real-time, allowing for quick identification and resolution of any performance issues.',
      data: [
        { month: 'Jan', production: 500 },
        { month: 'Feb', production: 550 },
        { month: 'Mar', production: 700 },
        { month: 'Apr', production: 800 },
        { month: 'May', production: 900 },
        { month: 'Jun', production: 950 },
      ]
    },
    { 
      title: 'Environmental Impact', 
      icon: Droplet, 
      color: 'text-teal-500',
      category: 'Environment',
      content: 'Solar energy significantly reduces carbon emissions and has a positive environmental impact. Key environmental benefits include:\n\n1. Carbon offset: A typical residential solar system can offset 3-4 tons of carbon emissions annually, equivalent to planting about 100 trees each year.\n2. Reduced water usage: Solar PV systems use minimal water compared to traditional power generation methods.\n3. Air quality improvement: Solar reduces the need for fossil fuel combustion, decreasing air pollutants like sulfur dioxide and particulate matter.\n4. Land use: Rooftop solar doesn\'t require additional land, preserving natural habitats.\n5. Noise reduction: Solar systems operate silently, reducing noise pollution.\n6. Manufacturing impact: While production of solar panels does have an environmental footprint, the energy payback time is typically 1-4 years, after which the system produces net positive environmental benefits.\n7. End-of-life recycling: Increasing focus on recyclability and circular economy principles in the solar industry.\n\nBy choosing solar, you\'re contributing to a more sustainable energy future and helping combat climate change.',
      data: [
        { year: 2018, reduction: 2.5 },
        { year: 2019, reduction: 2.7 },
        { year: 2020, reduction: 3.0 },
        { year: 2021, reduction: 3.3 },
        { year: 2022, reduction: 3.6 },
      ]
    },
  ];

  useEffect(() => {
    // Simulate API call
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const filteredAndSortedInsights = useMemo(() => {
    let filtered = insights.filter(insight =>
      insight.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      insight.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

    switch(sortOption) {
      case 'alphabetical':
        return filtered.sort((a, b) => a.title.localeCompare(b.title));
      case 'category':
        return filtered.sort((a, b) => a.category.localeCompare(b.category));
      default:
        return filtered;
    }
  }, [insights, searchTerm, sortOption]);

  const renderChart = (data, dataKey) => {
    const chartType = dataKey === 'efficiency' || dataKey === 'capacity' || dataKey === 'reduction' ? 'line' : 'pie';

    if (chartType === 'line') {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={Object.keys(data[0])[0]} />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey={dataKey} stroke="#8884d8" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      );
    } else {
      const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82ca9d'];
      return (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey={dataKey}
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      );
    }
  };

  const handleKeyDown = (e, insight) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setSelectedInsight(insight);
    }
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "Solar Energy Insights | Solar Insight",
    "description": "Expert insights and knowledge about solar energy solutions and technologies",
    "publisher": {
      "@type": "Organization",
      "name": "Solar Insight",
      "logo": {
        "@type": "ImageObject",
        "url": "https://solarinsight.co.za/logo.png"
      }
    },
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": insights?.map((insight, index) => ({
        "@type": "Article",
        "position": index + 1,
        "url": `https://solarinsight.co.za/insights/${insight.slug}`,
        "name": insight.title,
        "description": insight.summary
      }))
    }
  };

  // SEO Configuration
  const seoData = {
    title: 'Solar Energy Insights & Knowledge Hub',
    description: 'Discover expert insights, comprehensive guides, and in-depth knowledge about solar energy solutions, technology trends, and best practices in South Africa. Stay informed with our latest research and analysis.',
    keywords: 'solar insights, solar energy knowledge, solar guides, solar technology, solar research, solar analysis, South Africa solar education',
    path: '/insights',
    image: '/images/insights-hub.jpg',
    lastUpdated: new Date().toISOString(),
    categories: [
      'Solar Technology',
      'Installation Guides',
      'Maintenance Tips',
      'Industry News',
      'Cost Analysis',
      'Energy Efficiency'
    ],
    topics: [
      'Latest Solar Technologies',
      'Installation Best Practices',
      'Maintenance Guidelines',
      'Cost-Benefit Analysis',
      'Energy Storage Solutions',
      'Grid Integration'
    ],
    articleCount: 50,
    expertContributors: 12,
    updateFrequency: 'Weekly'
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="general" data={seoData} />
      
      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
          Solar Insights
        </h1>

        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="relative w-full md:w-64 mb-4 md:mb-0">
            <input
              type="text"
              placeholder="Search insights..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`w-full pl-10 pr-4 py-2 rounded-lg ${isDarkMode ? 'bg-blue-800 text-white' : 'bg-white text-gray-900'} focus:outline-none focus:ring-2 focus:ring-yellow-400`}
              aria-label="Search insights"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>

          <div className="relative w-full md:w-64">
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className={`w-full pl-4 pr-10 py-2 rounded-lg appearance-none ${isDarkMode ? 'bg-blue-800 text-white' : 'bg-white text-gray-900'} focus:outline-none focus:ring-2 focus:ring-yellow-400`}
              aria-label="Sort insights"
            >
              <option value="default">Sort by...</option>
              <option value="alphabetical">Alphabetical</option>
              <option value="category">Category</option>
            </select>
            <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
        
        {isLoading ? (
          <div className="text-center">Loading insights...</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredAndSortedInsights.map((insight, index) => (
              <div 
                key={index} 
                className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-white hover:bg-gray-50'} cursor-pointer transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105`}
                onClick={() => setSelectedInsight(insight)}
                onKeyDown={(e) => handleKeyDown(e, insight)}
                tabIndex={0}
                role="button"
                aria-label={`View details about ${insight.title}`}
              >
                <div className="flex items-center mb-4">
                  <insight.icon className={`h-8 w-8 ${insight.color} mr-3`} aria-hidden="true" />
                  <h2 className="text-xl font-semibold">{insight.title}</h2>
                </div>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>
                  {insight.content.substring(0, 100)}...
                </p>
                <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  Category: {insight.category}
                </span>
              </div>
            ))}
          </div>
        )}

        {selectedInsight && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className={`relative max-w-4xl w-full ${isDarkMode ? 'bg-blue-800' : 'bg-white'} p-6 rounded-lg shadow-xl overflow-y-auto max-h-[90vh] m-4`}>
              <button 
                className={`absolute top-2 right-2 ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}
                onClick={() => setSelectedInsight(null)}
                aria-label="Close insight details"
              >
                <X className="h-6 w-6" />
              </button>
              <h2 className={`text-2xl font-bold mb-4 ${selectedInsight.color}`}>
                {selectedInsight.title}
              </h2>
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-6`}>
                {selectedInsight.content}
              </p>
              <ErrorBoundary fallback={<div>Error loading chart</div>}>
                {renderChart(selectedInsight.data, Object.keys(selectedInsight.data[0])[1])}
              </ErrorBoundary>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default InsightPage;