import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, Settings, Check } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import SeoHead from '../common/SeoHead';

const SolarMaintenancePage = () => {
  const { isDarkMode } = useTheme();

  const faqData = [
    {
      question: "How often should solar systems be maintained?",
      answer: "We recommend professional maintenance every 6-12 months, depending on your location in South Africa. Systems in dustier areas like Johannesburg may need more frequent maintenance than coastal regions like Cape Town."
    },
    {
      question: "What does maintenance include?",
      answer: "Our maintenance service includes panel inspection, cleaning, electrical testing, inverter checks, mounting system inspection, and performance optimization. We also provide detailed reports and recommendations."
    },
    {
      question: "Do you offer maintenance packages?",
      answer: "Yes, we offer customized maintenance packages for both residential and commercial solar systems. These can include scheduled inspections, cleaning, and priority support."
    },
    {
      question: "How long does maintenance take?",
      answer: "A typical maintenance service takes 2-4 hours, depending on system size and complexity. We work efficiently to minimize any disruption to your power supply."
    }
  ];

  const [showFaq, setShowFaq] = useState(Array(faqData.length).fill(false));

  const maintenanceChecklist = [
    {
      title: "Visual Inspection",
      items: [
        "Panel condition assessment",
        "Mounting system check",
        "Wiring and connection inspection",
        "Weather seal verification"
      ]
    },
    {
      title: "Performance Testing",
      items: [
        "Power output measurement",
        "Inverter efficiency check",
        "Battery system testing",
        "Monitoring system verification"
      ]
    },
    {
      title: "Safety Checks",
      items: [
        "Electrical safety testing",
        "Earth connection verification",
        "Surge protection check",
        "Emergency shutdown test"
      ]
    },
    {
      title: "System Optimization",
      items: [
        "Panel angle adjustment",
        "Inverter settings optimization",
        "Performance monitoring setup",
        "Energy efficiency recommendations"
      ]
    }
  ];

  const toggleFaq = (index) => {
    setShowFaq(prev => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // Structured data for SEO
  const maintenanceServiceData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Solar System Maintenance Service",
    "provider": {
      "@type": "LocalBusiness",
      "name": "Solar Insight"
    },
    "areaServed": [
      "Cape Town",
      "Johannesburg",
      "Durban",
      "Pretoria"
    ],
    "description": "Comprehensive solar system maintenance services including inspection, testing, and optimization across South Africa.",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Solar Maintenance Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Regular Maintenance Package"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Performance Optimization"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Safety Inspection"
          }
        }
      ]
    }
  };

  // SEO Configuration
  const seoData = {
    title: 'Solar System Maintenance Services',
    description: 'Professional solar maintenance services in South Africa. Regular inspections, performance monitoring, and preventive maintenance to ensure optimal system efficiency and longevity.',
    keywords: 'solar maintenance, system inspection, preventive maintenance, performance monitoring, solar servicing, South Africa solar maintenance',
    path: '/services/maintenance',
    image: '/images/solar-maintenance.jpg',
    services: [
      'Regular Inspections',
      'Performance Monitoring',
      'Preventive Maintenance',
      'System Optimization',
      'Component Testing'
    ],
    plans: [
      {
        name: 'Basic',
        frequency: 'Quarterly',
        features: ['Visual Inspection', 'Performance Check', 'Basic Cleaning']
      },
      {
        name: 'Premium',
        frequency: 'Monthly',
        features: ['Detailed Inspection', 'Advanced Diagnostics', 'Full Service']
      }
    ],
    benefits: [
      'Extended System Life',
      'Optimal Performance',
      'Reduced Downtime',
      'Cost Savings',
      'Warranty Compliance'
    ],
    coverage: [
      'Cape Town',
      'Johannesburg',
      'Durban',
      'Pretoria'
    ]
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-gray-900'}`}>
      <SeoHead template="service" data={seoData} />

      <main className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <section className={`text-center py-16 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'} rounded-lg shadow-xl mb-12`}>
          <div className="flex justify-center mb-6">
            <Settings className="w-16 h-16 text-yellow-400" />
          </div>
          <h1 className={`
            text-4xl md:text-6xl font-bold mb-6
            ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}
            drop-shadow-[0_0_10px_rgba(250,204,21,0.7)]
          `}>
            Solar System Maintenance
          </h1>
          <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
            Keep your solar system running at peak efficiency with our professional maintenance services
          </p>
        </section>

        {/* Maintenance Checklist */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Comprehensive Maintenance Checklist</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {maintenanceChecklist.map((category, index) => (
              <div key={index} className="bg-opacity-20 bg-white rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-4 text-yellow-400">{category.title}</h3>
                <ul className="space-y-3">
                  {category.items.map((item, idx) => (
                    <li key={idx} className="flex items-center space-x-3">
                      <Check className="w-5 h-5 text-yellow-400 flex-shrink-0" />
                      <span className="text-gray-300">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Benefits Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Benefits of Regular Maintenance</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                title: "Extended Lifespan",
                description: "Regular maintenance can significantly extend your system's operational life"
              },
              {
                title: "Optimal Performance",
                description: "Ensure maximum energy production and system efficiency"
              },
              {
                title: "Cost Savings",
                description: "Prevent expensive repairs and maximize return on investment"
              },
              {
                title: "Warranty Compliance",
                description: "Meet manufacturer warranty requirements with documented maintenance"
              },
              {
                title: "Safety Assurance",
                description: "Regular safety checks protect your property and family"
              },
              {
                title: "Peace of Mind",
                description: "Know your system is operating safely and efficiently"
              }
            ].map((benefit, index) => (
              <div key={index} className="p-4 bg-opacity-20 bg-white rounded-lg">
                <h3 className="text-lg font-semibold mb-2 text-yellow-400">{benefit.title}</h3>
                <p className="text-gray-300">{benefit.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* FAQ Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-6 text-white">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <div key={index} className="border-b border-gray-700">
                <button
                  className="w-full text-left py-4 flex justify-between items-center"
                  onClick={() => toggleFaq(index)}
                >
                  <span className="text-lg font-medium text-white">{faq.question}</span>
                  {showFaq[index] ? (
                    <ChevronUp className="w-6 h-6 text-yellow-400" />
                  ) : (
                    <ChevronDown className="w-6 h-6 text-yellow-400" />
                  )}
                </button>
                {showFaq[index] && (
                  <p className="pb-4 text-gray-300">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </section>

        {/* CTA Section */}
        <section className={`text-center p-8 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-4 text-white">Ready to Maintain Your Solar Investment?</h2>
          <p className="text-gray-300 mb-6">
            Schedule a maintenance service or learn more about our maintenance packages
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/contact"
              className={`
                inline-block px-8 py-3 rounded-lg font-semibold text-lg
                ${isDarkMode ? 'bg-yellow-400 text-gray-900' : 'bg-yellow-400 text-gray-900'}
                hover:bg-yellow-300 transition duration-300
                transform hover:scale-105
              `}
            >
              Schedule Service
            </Link>
            <Link
              to="/services"
              className={`
                inline-block px-8 py-3 rounded-lg font-semibold text-lg
                border-2 border-yellow-400 text-yellow-400
                hover:bg-yellow-400 hover:text-gray-900
                transition duration-300
                transform hover:scale-105
              `}
            >
              View All Services
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SolarMaintenancePage; 