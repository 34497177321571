import React from 'react';
import { Helmet } from 'react-helmet';
import { generateSeoData } from '../../config/seoConfig';

const SeoHead = ({ template, data }) => {
  const seoData = generateSeoData(template, data);

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.description} />
      <meta name="keywords" content={seoData.keywords} />
      
      {/* Open Graph Tags */}
      <meta property="og:title" content={seoData.title} />
      <meta property="og:description" content={seoData.description} />
      <meta property="og:type" content={seoData.ogType} />
      <meta property="og:url" content={seoData.canonical} />
      <meta property="og:image" content={seoData.ogImage} />
      <meta property="og:site_name" content="Solar Insight" />
      
      {/* Twitter Card Tags */}
      <meta name="twitter:card" content={seoData.twitterCard} />
      <meta name="twitter:site" content={seoData.twitterSite} />
      <meta name="twitter:title" content={seoData.title} />
      <meta name="twitter:description" content={seoData.description} />
      <meta name="twitter:image" content={seoData.ogImage} />
      
      {/* Canonical URL */}
      <link rel="canonical" href={seoData.canonical} />
      
      {/* JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(seoData.jsonLd)}
      </script>

      {/* SEO Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Solar Insight" />
      <meta name="theme-color" content="#1e40af" />
      <meta name="format-detection" content="telephone=no" />

      {/* Additional Meta Tags */}
      {data.noIndex && <meta name="robots" content="noindex, nofollow" />}
      {data.publishDate && <meta name="article:published_time" content={data.publishDate} />}
      {data.modifiedDate && <meta name="article:modified_time" content={data.modifiedDate} />}
      {data.author && <meta name="article:author" content={data.author} />}
      {data.section && <meta name="article:section" content={data.section} />}
      {data.tags && <meta name="article:tag" content={data.tags.join(', ')} />}
    </Helmet>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default React.memo(SeoHead); 