import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import SeoHead from '../common/SeoHead';
import {
  Share2,
  Bookmark,
  BookmarkPlus,
  ChevronUp,
  Calendar,
  Clock,
  Tag,
  AlertTriangle,
  Battery,
  Sun,
  Zap,
  MessageCircle,
  ThumbsUp,
  ThumbsDown,
  List,
  ChevronRight,
  ArrowRight,
  Plus,
  Minus,
  HelpCircle,
  TrendingDown,
  Factory,
  Hospital,
  GraduationCap,
  DollarSign,
  Timer,
  Briefcase,
  Building2,
  Hammer,
  Truck,
  Leaf
} from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Cell } from 'recharts';

const Stage6LoadsheddingSA = () => {
  const { isDarkMode } = useTheme();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const [activeSection, setActiveSection] = useState('');
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [feedback, setFeedback] = useState({ helpful: 0, notHelpful: 0 });
  const [expandedFaqs, setExpandedFaqs] = useState(new Set());

  const articleRef = useRef(null);
  const sectionsRef = useRef({});

  const publishDate = "2025-02-15T09:00:00Z";
  const siteUrl = 'https://solarinsight.co.za';
  const articlePath = '/news/stage-6-loadshedding-south-africa';
  const fullUrl = `${siteUrl}${articlePath}`;

  // Reading progress calculation
  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setReadingProgress(progress);
      setShowScrollTop(window.scrollY > 300);

      // Update active section
      Object.entries(sectionsRef.current).forEach(([id, ref]) => {
        if (ref && ref.getBoundingClientRect().top < window.innerHeight / 3) {
          setActiveSection(id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const powerGenerationData = [
    { category: 'Aging Plants (>30 years)', percentage: 60 },
    { category: 'Coal Dependency', percentage: 65 },
    { category: 'Operational Capacity', percentage: 75 },
  ];

  const outagesTrendData = [
    { month: 'Jan', unplannedOutages: 12000 },
    { month: 'Feb', unplannedOutages: 13000 },
    { month: 'Mar', unplannedOutages: 13500 },
    { month: 'Apr', unplannedOutages: 14000 },
  ];

  const urbanRuralComparisonData = [
    {
      area: 'Urban Areas',
      loadSheddingHours: 4,
      reliability: 85,
    },
    {
      area: 'Rural Areas',
      loadSheddingHours: 12,
      reliability: 60,
    },
  ];

  const stage6ImpactData = [
    { sector: 'Manufacturing', capacityLoss: 65 },
    { sector: 'Mining', capacityLoss: 55 },
    { sector: 'Agriculture', capacityLoss: 70 },
    { sector: 'Retail', capacityLoss: 45 },
  ];

  const tableOfContents = [
    { id: 'introduction', title: 'Understanding Stage 6: South Africa\'s Most Severe Power Crisis' },
    { id: 'economic-impact', title: 'Economic Impact: R500M Daily Losses & Business Disruption' },
    { id: 'critical-infrastructure', title: 'Critical Infrastructure: Healthcare & Essential Services at Risk' },
    { id: 'emergency-response', title: 'Emergency Response: Government & Private Sector Initiatives' },
    { id: 'sustainable-solutions', title: 'Sustainable Solutions: Renewable Energy & Grid Modernization' },
    { id: 'recovery-timeline', title: 'Recovery Timeline: Path to Energy Stability 2025-2026' },
    { id: 'conclusion', title: 'Conclusion: Building a Resilient Energy Future' }
  ];

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Stage 6 Loadshedding in South Africa',
          text: 'Understanding the crisis, causes, and solutions of Stage 6 loadshedding.',
          url: window.location.href
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    } else {
      navigator.clipboard.writeText(window.location.href);
      alert('Link copied to clipboard!');
    }
  };

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (sectionId) => {
    sectionsRef.current[sectionId]?.scrollIntoView({ behavior: 'smooth' });
  };

  // Add toggleFaq function
  const toggleFaq = (index) => {
    setExpandedFaqs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  // SEO Configuration
  const seoData = {
    title: "Stage 6 Loadshedding South Africa 2025: Impact, Solutions & Recovery Guide",
    description: "Learn how Stage 6 loadshedding affects South Africa with 6,000MW power cuts and R500M daily losses. Discover solutions, infrastructure impacts, and renewable energy alternatives for the power crisis.",
    keywords: "Stage 6 loadshedding South Africa, Eskom power cuts 2025, load shedding stages, Stage 6 impact analysis, power outages SA, grid stability solutions, renewable energy transition, economic impact Stage 6, emergency power measures, solar solutions South Africa",
    publishDate,
    modifiedDate: publishDate,
    path: articlePath,
    image: '/images/stage-6-loadshedding.jpg',
    category: 'Energy Crisis',
    author: 'Solar Insight Team',
    readTime: '10 min read',
    section: 'Energy News',
    tags: [
      'Stage 6 loadshedding South Africa',
      'Eskom Stage 6 power cuts',
      'load shedding schedule 2025',
      'power outages South Africa',
      'grid stability solutions',
      'energy crisis management',
      'renewable energy solutions',
      'economic impact analysis',
      'emergency power measures',
      'solar power alternatives'
    ],
    noIndex: false,
    ogType: 'article',
    twitterCard: 'summary_large_image',
    canonical: `${siteUrl}${articlePath}`
  };

  // Add FAQ Schema
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What is Stage 6 loadshedding in South Africa?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Stage 6 loadshedding is the most severe level of controlled power outages, requiring the shutdown of 6,000 MW of electricity—nearly 20–30% of the national power supply. This extreme measure is implemented when the grid is on the brink of collapse."
        }
      },
      {
        "@type": "Question",
        "name": "What causes Stage 6 loadshedding in South Africa?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The primary causes include aging infrastructure, deferred maintenance, heavy coal dependency, governance challenges, and unplanned shocks like extreme weather or worker strikes. These factors combine to push the grid to its limits."
        }
      },
      // ... add all other FAQ items
    ]
  };

  // Update the article schema
  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "Stage 6 Loadshedding South Africa 2025: Complete Impact Analysis & Solutions Guide",
    "datePublished": publishDate,
    "dateModified": publishDate,
    "author": {
      "@type": "Organization",
      "name": "Solar Insight Team",
      "url": siteUrl
    },
    "publisher": {
      "@type": "Organization",
      "name": "Solar Insight",
      "logo": {
        "@type": "ImageObject",
        "url": `${siteUrl}/logo.png`
      }
    },
    "description": seoData.description,
    "keywords": seoData.keywords,
    "articleSection": "Energy Crisis",
    "image": `${siteUrl}/images/stage-6-loadshedding.jpg`,
    "url": fullUrl,
    "mainEntityOfPage": fullUrl,
    "about": {
      "@type": "Thing",
      "name": "Stage 6 Loadshedding",
      "description": "Analysis of South Africa's most severe power crisis level"
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-gray-100' : 'bg-amber-50 text-amber-900'}`}>
      <SeoHead template="article" data={seoData} />
      
      {/* Add Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(articleSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(faqSchema)}
      </script>

      {/* Reading Progress Bar */}
      <div 
        className="fixed top-0 left-0 w-full h-1 bg-gray-200 z-50"
        style={{ 
          background: isDarkMode ? 'rgba(30, 58, 138, 0.3)' : 'rgba(251, 191, 36, 0.3)'
        }}
      >
        <div
          className={`h-full transition-all duration-300 ${
            isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'
          }`}
          style={{ width: `${readingProgress}%` }}
        />
      </div>

      <main className="container mx-auto px-4 py-8">
        <article className="max-w-4xl mx-auto" ref={articleRef}>
          {/* Hero Section */}
          <section className={`rounded-xl shadow-2xl mb-12 p-8 ${
            isDarkMode 
              ? 'bg-gradient-to-br from-blue-800 to-blue-900' 
              : 'bg-gradient-to-br from-amber-100 to-amber-200'
          }`}>
            <h1 className={`text-4xl md:text-6xl font-bold mb-6 text-center ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
              Stage 6 Loadshedding
            </h1>
            <h2 className={`text-2xl md:text-3xl font-semibold mb-6 text-center ${
              isDarkMode ? 'text-yellow-300' : 'text-amber-600'
            }`}>
              South Africa's Most Severe Power Crisis: Impact Analysis & Solutions
            </h2>
            <div className={`max-w-3xl mx-auto mb-8 text-center ${
              isDarkMode ? 'text-gray-300' : 'text-amber-800'
            }`}>
              <p className="text-lg mb-4">
                Stage 6 loadshedding brings unprecedented challenges to South Africa, with 6,000MW power cuts and R500M daily economic losses. Explore the impacts, emergency measures, and long-term solutions for the nation's energy crisis.
              </p>
              <div className="flex flex-wrap justify-center gap-2 mt-4">
                {['Stage 6 loadshedding', 'Eskom power cuts', 'load shedding schedule', 'power outages SA', 'grid stability'].map((keyword) => (
                  <span key={keyword} className={`px-3 py-1 rounded-full text-sm ${
                    isDarkMode ? 'bg-blue-800 text-yellow-400' : 'bg-amber-200 text-amber-700'
                  }`}>
                    {keyword}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button onClick={handleShare} className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${
                isDarkMode
                  ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                  : 'bg-amber-500 text-white hover:bg-amber-600'
              }`}>
                <Share2 className="h-5 w-5" />
                <span>Share Article</span>
              </button>
              <button onClick={handleBookmark} className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${
                isBookmarked
                  ? isDarkMode
                    ? 'bg-yellow-400 text-blue-900'
                    : 'bg-amber-500 text-white'
                  : isDarkMode
                    ? 'border-2 border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-blue-900'
                    : 'border-2 border-amber-500 text-amber-500 hover:bg-amber-500 hover:text-white'
              }`}>
                {isBookmarked ? <Bookmark className="h-5 w-5" /> : <BookmarkPlus className="h-5 w-5" />}
                <span>{isBookmarked ? 'Saved' : 'Save for Later'}</span>
              </button>
            </div>
          </section>

          {/* Article Meta */}
          <section className={`flex items-center justify-between mb-8 p-4 rounded-lg ${
            isDarkMode ? 'bg-blue-800' : 'bg-amber-100'
          }`}>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Calendar className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <time dateTime={publishDate} className="text-sm">February 15, 2025</time>
              </div>
              <div className="flex items-center gap-2">
                <Clock className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">10 min read</span>
              </div>
              <div className="flex items-center gap-2">
                <Tag className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">Energy Crisis</span>
              </div>
            </div>
          </section>

          {/* Main Content */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <div className="prose prose-lg max-w-none">
              {/* Introduction */}
              <div ref={(el) => sectionsRef.current['introduction'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Understanding Stage 6: South Africa's Most Severe Power Crisis
                </h2>
                <p className={`text-xl leading-relaxed mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  South Africa faces its most challenging power crisis with Stage 6 loadshedding, requiring the shutdown of 6,000 megawatts (MW) of electricity—approximately 20-30% of the national power supply. These severe power cuts signal a grid under extreme pressure, affecting the nation's economy, infrastructure, and daily life.
                </p>
                <p className={`text-xl leading-relaxed mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  For businesses, households, and policymakers, understanding Stage 6 loadshedding is crucial for both immediate survival and planning long-term solutions to this unprecedented energy crisis.
                </p>
              </div>

              {/* What is Stage 6 Loadshedding */}
              <div ref={(el) => sectionsRef.current['what-is-stage6'] = el} className="mb-16">
                <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  What is Stage 6 Loadshedding?
                </h2>
                <p className={`text-xl leading-relaxed mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Stage 6 loadshedding is the most severe level of controlled power outages, requiring the shutdown of 6,000 MW of electricity—nearly 20–30% of the national power supply. This extreme measure is implemented when the grid is on the brink of collapse.
                </p>
              </div>

              {/* What Causes Stage 6 Loadshedding */}
              <div ref={(el) => sectionsRef.current['causes'] = el} className="mb-16">
                <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  What Causes Stage 6 Loadshedding?
                </h2>
                <p className={`text-xl leading-relaxed mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  The primary causes include aging infrastructure, deferred maintenance, heavy coal dependency, governance challenges, and unplanned shocks like extreme weather or worker strikes. These factors combine to push the grid to its limits.
                </p>
              </div>

              {/* Economic Impact */}
              <div ref={(el) => sectionsRef.current['economic-impact'] = el} className="mb-16">
                <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Economic Impact: R500M Daily Losses & Business Disruption
                </h2>
                
                {/* Economic Impact Stats */}
                <div className={`p-8 rounded-lg mb-12 ${isDarkMode ? 'bg-blue-700/50' : 'bg-amber-200/50'}`}>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                      <div className="flex items-center gap-2 mb-2">
                        <DollarSign className="h-5 w-5" />
                        <span className="font-bold">Daily Loss</span>
                      </div>
                      <p className="text-2xl font-bold">R500M</p>
                      <p className="text-sm opacity-80">Economic Impact</p>
                    </div>
                    
                    <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                      <div className="flex items-center gap-2 mb-2">
                        <Factory className="h-5 w-5" />
                        <span className="font-bold">Production</span>
                      </div>
                      <p className="text-2xl font-bold">-35%</p>
                      <p className="text-sm opacity-80">Industrial Output</p>
                    </div>
                    
                    <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'}`}>
                      <div className="flex items-center gap-2 mb-2">
                        <Briefcase className="h-5 w-5" />
                        <span className="font-bold">Jobs</span>
                      </div>
                      <p className="text-2xl font-bold">-2.1%</p>
                      <p className="text-sm opacity-80">Employment Impact</p>
                    </div>
                  </div>
                </div>

                {/* Sector Impact Chart */}
                <div className="mb-16">
                  <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    Sector Impact & Solar Opportunity
                  </h3>
                  <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                    This chart shows capacity loss across sectors, highlighting why businesses and homes need reliable backup power. Solar systems with adequate battery storage can help maintain operations during Stage 6 cuts.
                  </p>
                  <div className="h-80 mb-8">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={stage6ImpactData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="sector" />
                        <YAxis label={{ value: 'Capacity Loss (%)', angle: -90, position: 'insideLeft' }} />
                        <Tooltip />
                        <Bar dataKey="capacityLoss" fill={isDarkMode ? '#fbbf24' : '#f59e0b'} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div className={`mt-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-700/30' : 'bg-amber-100'}`}>
                    <h4 className="font-semibold mb-2">Solar Solution Impact:</h4>
                    <ul className="list-disc list-inside space-y-1">
                      <li>Manufacturing & Retail: 5-15kW systems with battery backup can maintain critical operations</li>
                      <li>Agriculture: Solar irrigation systems remain functional during outages</li>
                      <li>Mining: Supplementary solar power reduces reliance on grid electricity</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Critical Infrastructure */}
              <div ref={(el) => sectionsRef.current['critical-infrastructure'] = el} className="mb-16">
                <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Critical Infrastructure: Healthcare & Essential Services at Risk
                </h2>
                
                {/* Urban vs Rural Impact Chart */}
                <div className="mb-16">
                  <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    Urban vs Rural Power Reliability & Solar Benefits
                  </h3>
                  <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                    Rural areas face longer outages and lower reliability, making solar power particularly valuable. This comparison helps you understand why solar investment priorities may differ based on location.
                  </p>
                  <div className="h-80 mb-8">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={urbanRuralComparisonData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="area" />
                        <YAxis label={{ value: 'Hours / Reliability %', angle: -90, position: 'insideLeft' }} />
                        <Tooltip />
                        <Bar dataKey="loadSheddingHours" name="Daily Hours Without Power" fill={isDarkMode ? '#fbbf24' : '#f59e0b'} />
                        <Bar dataKey="reliability" name="Grid Reliability %" fill={isDarkMode ? '#60a5fa' : '#3b82f6'} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div className={`mt-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-700/30' : 'bg-amber-100'}`}>
                    <h4 className="font-semibold mb-2">Solar System Recommendations:</h4>
                    <ul className="list-disc list-inside space-y-1">
                      <li>Urban Areas: 5-8kW system with 5-10kWh battery storage for essential loads</li>
                      <li>Rural Areas: 8-12kW system with 10-15kWh battery storage for extended outages</li>
                      <li>Consider hybrid inverters for future system expansion</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Emergency Response */}
              <div ref={(el) => sectionsRef.current['emergency-response'] = el} className="mb-16">
                <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Emergency Response: Government & Private Sector Initiatives
                </h2>
                
                {/* Power Generation Infrastructure Chart */}
                <div className="mb-16">
                  <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    Grid Infrastructure Status & Solar Independence
                  </h3>
                  <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                    The aging power infrastructure and high coal dependency show why transitioning to solar is crucial for energy security. This data helps explain the urgency of installing solar power.
                  </p>
                  <div className="h-80 mb-8">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={powerGenerationData} margin={{ top: 20, right: 30, left: 20, bottom: 70 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="category" 
                          angle={-45} 
                          textAnchor="end" 
                          height={100}
                          interval={0}
                          tick={{ fontSize: 12 }}
                        />
                        <YAxis label={{ value: 'Percentage (%)', angle: -90, position: 'insideLeft' }} />
                        <Tooltip />
                        <Bar dataKey="percentage" fill={isDarkMode ? '#fbbf24' : '#f59e0b'} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div className={`mt-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-700/30' : 'bg-amber-100'}`}>
                    <h4 className="font-semibold mb-2">Why Switch to Solar Now:</h4>
                    <ul className="list-disc list-inside space-y-1">
                      <li>Aging infrastructure means more frequent outages in coming years</li>
                      <li>Solar + battery systems provide independence from grid issues</li>
                      <li>Current solar incentives and tax benefits make transition more affordable</li>
                    </ul>
                  </div>
                </div>

                {/* Outages Trend Chart */}
                <div className="mb-16">
                  <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    2025 Outages Trend & Solar ROI
                  </h3>
                  <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                    The increasing trend in unplanned outages demonstrates why solar investment becomes more valuable over time. Each spike represents potential savings for solar-equipped properties.
                  </p>
                  <div className="h-80 mb-8">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={outagesTrendData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis label={{ value: 'Unplanned Outages (MW)', angle: -90, position: 'insideLeft' }} />
                        <Tooltip />
                        <Line type="monotone" dataKey="unplannedOutages" stroke={isDarkMode ? '#fbbf24' : '#f59e0b'} strokeWidth={2} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                  <div className={`mt-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-700/30' : 'bg-amber-100'}`}>
                    <h4 className="font-semibold mb-2">Solar Investment Benefits:</h4>
                    <ul className="list-disc list-inside space-y-1">
                      <li>Average solar system pays for itself faster as outages increase</li>
                      <li>Battery storage becomes more valuable with frequent unplanned cuts</li>
                      <li>Property value increases with reliable power backup</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* New Sections for SEO */}
              <div ref={(el) => sectionsRef.current['household-impact'] = el} className="mb-16">
                <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  How Does Stage 6 Loadshedding Affect Households?
                </h2>
                <p className={`text-xl leading-relaxed mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  During Stage 6 loadshedding, households can experience up to 12 hours without power daily. This disrupts essential activities such as cooking, refrigeration, and safety systems, and forces many to rely on costly backup generators.
                </p>
                <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-700/30' : 'bg-amber-100'}`}>
                  <h3 className="font-semibold mb-4">Key Household Impacts:</h3>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Extended power outages up to 12 hours daily</li>
                    <li>Disruption to essential household activities</li>
                    <li>Increased reliance on backup power solutions</li>
                    <li>Higher energy costs from alternative power sources</li>
                  </ul>
                </div>
              </div>

              {/* FAQs Section */}
              <div ref={(el) => sectionsRef.current['faqs'] = el}>
                <section 
                  className={`p-8 rounded-lg shadow-xl mb-12 ${
                    isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
                  }`}
                  itemScope 
                  itemType="https://schema.org/FAQPage"
                >
                  <div className="flex items-center justify-between mb-8">
                    <h2 className={`text-3xl font-bold ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                    }`}>
                      Frequently Asked Questions About Stage 6 Loadshedding
                    </h2>
                    <HelpCircle className={`h-8 w-8 ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                    }`} />
                  </div>

                  {/* FAQ Items */}
                  <div className="space-y-4">
                    {[
                      {
                        question: "What is Stage 6 loadshedding in South Africa?",
                        answer: "Stage 6 loadshedding is the most severe level of controlled power outages, requiring the shutdown of 6,000 MW of electricity—nearly 20–30% of the national power supply. This extreme measure is implemented when the grid is on the brink of collapse.",
                        keywords: ["grid stability", "power supply", "electricity shutdown"]
                      },
                      {
                        question: "What causes Stage 6 loadshedding in South Africa?",
                        answer: "The primary causes include aging infrastructure, deferred maintenance, heavy coal dependency, governance challenges, and unplanned shocks like extreme weather or worker strikes. These factors combine to push the grid to its limits.",
                        keywords: ["infrastructure", "maintenance", "coal dependency"]
                      },
                      {
                        question: "How does Stage 6 loadshedding affect households?",
                        answer: "During Stage 6 loadshedding, households can experience up to 12 hours without power daily. This disrupts essential activities such as cooking, refrigeration, and safety systems, and forces many to rely on costly backup generators.",
                        keywords: ["household impact", "daily disruption", "backup power"]
                      },
                      {
                        question: "What is the economic impact of Stage 6 loadshedding?",
                        answer: "Extended power outages can cost the economy up to R500 million per day. The disruptions affect industrial productivity, reduce GDP growth, and force businesses to incur extra costs for backup systems, further straining the economy.",
                        keywords: ["economic losses", "business impact", "productivity"]
                      },
                      {
                        question: "How are essential services impacted by Stage 6 loadshedding?",
                        answer: "Essential services such as hospitals, water plants, and schools face significant challenges. Extended outages risk compromising critical care, disrupt supply chains, and lead to educational setbacks—especially in rural areas with limited backup power.",
                        keywords: ["essential services", "healthcare", "education"]
                      },
                      {
                        question: "What role does Eskom play in Stage 6 loadshedding?",
                        answer: "Eskom, South Africa's state-owned utility, is at the heart of the crisis. Its aging generation fleet, high levels of coal dependency, and mounting financial and governance challenges are major contributors to the severe power cuts seen in Stage 6 loadshedding.",
                        keywords: ["utility provider", "power generation", "governance"]
                      },
                      {
                        question: "What immediate measures are taken during Stage 6 loadshedding?",
                        answer: "Short-term fixes include importing emergency power from neighboring grids, deploying mobile generators, and fast-tracking solar incentives. These measures aim to stabilize the grid while long-term solutions are developed.",
                        keywords: ["emergency measures", "grid stability", "short-term solutions"]
                      },
                      {
                        question: "What long-term solutions can address Stage 6 loadshedding?",
                        answer: "Long-term strategies include modernizing power infrastructure by replacing aging coal plants with renewable energy systems (solar, wind, battery storage), enhancing governance through anti-corruption measures, and promoting public-private partnerships for decentralized energy generation.",
                        keywords: ["renewable energy", "infrastructure", "modernization"]
                      },
                      {
                        question: "How can renewable energy help alleviate Stage 6 loadshedding?",
                        answer: "Accelerating the transition to renewable energy—such as solar and wind—can reduce reliance on coal and stabilize the grid. Expanding renewable capacity is key to achieving targets like a 30% renewable energy mix by 2030 and mitigating extreme power cuts.",
                        keywords: ["renewable transition", "energy mix", "grid stability"]
                      },
                      {
                        question: "What governance challenges contribute to Stage 6 loadshedding?",
                        answer: "Governance challenges include corruption, mismanagement, and bureaucratic delays that hinder necessary investments in grid modernization and renewable energy projects. These issues exacerbate the systemic failures leading to Stage 6 loadshedding.",
                        keywords: ["corruption", "bureaucracy", "investment barriers"]
                      }
                    ].map((faq, index) => (
                      <div
                        key={index}
                        className={`border rounded-lg transition-all duration-300 ${
                          isDarkMode
                            ? 'border-blue-700 hover:border-yellow-400'
                            : 'border-amber-200 hover:border-amber-500'
                        }`}
                        itemScope 
                        itemProp="mainEntity" 
                        itemType="https://schema.org/Question"
                      >
                        <button
                          onClick={() => toggleFaq(index)}
                          className={`w-full px-6 py-4 flex items-center justify-between ${
                            expandedFaqs.has(index)
                              ? isDarkMode
                                ? 'bg-blue-700'
                                : 'bg-amber-200'
                              : ''
                          }`}
                        >
                          <span 
                            className={`font-semibold text-left ${
                              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                            }`}
                            itemProp="name"
                          >
                            {faq.question}
                          </span>
                          {expandedFaqs.has(index) 
                            ? <Minus className="h-5 w-5 flex-shrink-0" />
                            : <Plus className="h-5 w-5 flex-shrink-0" />
                          }
                        </button>
                        
                        {/* Answer */}
                        {expandedFaqs.has(index) && (
                          <div 
                            className={`px-6 py-4 border-t ${
                              isDarkMode ? 'border-blue-700 bg-blue-700/50' : 'border-amber-200 bg-amber-200/50'
                            }`}
                            itemScope 
                            itemProp="acceptedAnswer" 
                            itemType="https://schema.org/Answer"
                          >
                            <p 
                              className={isDarkMode ? 'text-gray-300' : 'text-amber-900'}
                              itemProp="text"
                            >
                              {faq.answer}
                            </p>
                            <div className="mt-2 flex flex-wrap gap-2">
                              {faq.keywords.map((keyword, kidx) => (
                                <span 
                                  key={kidx}
                                  className={`text-xs px-2 py-1 rounded-full ${
                                    isDarkMode ? 'bg-blue-900 text-yellow-400' : 'bg-amber-200 text-amber-700'
                                  }`}
                                >
                                  {keyword}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </section>
              </div>

              {/* Sustainable Solutions */}
              <div ref={(el) => sectionsRef.current['sustainable-solutions'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Sustainable Solutions: Renewable Energy & Grid Modernization
                </h2>
                <p className={`text-xl leading-relaxed mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Sustainable solutions focus on infrastructure modernization, renewable energy transition, and improved governance structures.
                </p>
              </div>

              {/* Recovery Timeline */}
              <div ref={(el) => sectionsRef.current['recovery-timeline'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Recovery Timeline: Path to Energy Stability 2025-2026
                </h2>
                <p className={`text-xl leading-relaxed mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  The recovery timeline outlines the steps needed to rebuild a more resilient and sustainable energy infrastructure.
                </p>
              </div>

              {/* Conclusion */}
              <div ref={(el) => sectionsRef.current['conclusion'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Conclusion: Building a Resilient Energy Future
                </h2>
                <p className={`mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Stage 6 loadshedding represents a critical juncture in South Africa's energy crisis. While the challenges are severe, they also present an opportunity to rebuild a more resilient and sustainable energy infrastructure. Success will require coordinated effort from government, private sector, and communities, coupled with significant investment in renewable energy solutions.
                </p>
              </div>

              {/* Article Feedback Section */}
              <div className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'}`}>
                <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Was this article helpful?
                </h2>
                <div className="flex gap-4">
                  <button
                    onClick={() => setFeedback(prev => ({ ...prev, helpful: prev.helpful + 1 }))}
                    className={`flex items-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 ${
                      isDarkMode 
                        ? 'bg-blue-700 hover:bg-blue-600 text-white' 
                        : 'bg-amber-200 hover:bg-amber-300 text-amber-900'
                    }`}
                  >
                    <ThumbsUp className="h-5 w-5" />
                    <span>Yes ({feedback.helpful})</span>
                  </button>
                  <button
                    onClick={() => setFeedback(prev => ({ ...prev, notHelpful: prev.notHelpful + 1 }))}
                    className={`flex items-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 ${
                      isDarkMode 
                        ? 'bg-blue-700 hover:bg-blue-600 text-white' 
                        : 'bg-amber-200 hover:bg-amber-300 text-amber-900'
                    }`}
                  >
                    <ThumbsDown className="h-5 w-5" />
                    <span>No ({feedback.notHelpful})</span>
                  </button>
                </div>
              </div>

              {/* Related Articles Section */}
              <div className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'}`}>
                <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Related Articles
                </h2>
                <div className="space-y-6">
                  <Link
                    to="/news/perovskite-solar-breakthrough-2025"
                    className={`block p-6 rounded-lg transition-all duration-300 ${
                      isDarkMode 
                        ? 'bg-blue-700 hover:bg-blue-600' 
                        : 'bg-amber-200 hover:bg-amber-300'
                    }`}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h3 className={`text-xl font-semibold ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                        New Perovskite Solar Cells Break Efficiency Record
                      </h3>
                      <ArrowRight className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`} />
                    </div>
                    <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                      January 29, 2025
                    </p>
                  </Link>

                  <Link
                    to="/news/load-shedding-south-africa-2025"
                    className={`block p-6 rounded-lg transition-all duration-300 ${
                      isDarkMode 
                        ? 'bg-blue-700 hover:bg-blue-600' 
                        : 'bg-amber-200 hover:bg-amber-300'
                    }`}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h3 className={`text-xl font-semibold ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                        Load Shedding in South Africa 2025: Crisis and Solutions
                      </h3>
                      <ArrowRight className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`} />
                    </div>
                    <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-amber-900'}`}>
                      January 31, 2025
                    </p>
                  </Link>

                  <Link
                    to="/news"
                    className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 ${
                      isDarkMode 
                        ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
                        : 'bg-amber-500 text-white hover:bg-amber-600'
                    }`}
                  >
                    <span className="font-semibold">View All Solar News</span>
                    <ArrowRight className="h-5 w-5" />
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </article>
      </main>
    </div>
  );
};

export default Stage6LoadsheddingSA; 